var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _vm.numberOfQualityScores > 1 &&
        _vm.dbDatafile.numberOfCleanedRecords > 0
          ? _c(
              "b-tabs",
              { attrs: { "content-class": "pt-3" } },
              [
                _c(
                  "b-tab",
                  {
                    attrs: {
                      lazy: "",
                      active: this.activatedTab === "OVERVIEW",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeActivatedTab("OVERVIEW")
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline-block" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "datafileResultCleanedTab.tabs.overview"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      32504195
                    ),
                  },
                  [
                    _c("OverviewResults", {
                      on: { changeTab: _vm.changeActivatedTab },
                    }),
                  ],
                  1
                ),
                this.algorithms.OES
                  ? _c(
                      "b-tab",
                      {
                        attrs: {
                          lazy: "",
                          active: this.activatedTab === "OES",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-sm-inline-block" },
                                    [_vm._v(" Open-Ended-Score")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2181774694
                        ),
                      },
                      [_c("OESResults")],
                      1
                    )
                  : _vm._e(),
                this.algorithms.TS
                  ? _c(
                      "b-tab",
                      {
                        attrs: { lazy: "", active: this.activatedTab === "TS" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-sm-inline-block" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "datafileResultCleanedTab.tabs.timeScore"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3276557437
                        ),
                      },
                      [_c("TSResults")],
                      1
                    )
                  : _vm._e(),
                this.algorithms.IBS
                  ? _c(
                      "b-tab",
                      {
                        attrs: {
                          lazy: "",
                          active: this.activatedTab === "IBS",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-sm-inline-block" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "datafileResultCleanedTab.tabs.itemBatteryScore"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          696215632
                        ),
                      },
                      [_c("IBSResults")],
                      1
                    )
                  : _vm._e(),
                this.algorithms.PS
                  ? _c(
                      "b-tab",
                      {
                        attrs: { lazy: "", active: this.activatedTab === "PS" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-sm-inline-block" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "datafileResultCleanedTab.tabs.predictionScore"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          330360829
                        ),
                      },
                      [_c("PSResults")],
                      1
                    )
                  : _vm._e(),
                this.algorithms.CS
                  ? _c(
                      "b-tab",
                      {
                        attrs: { lazy: "", active: this.activatedTab === "CS" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-sm-inline-block" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "datafileResultCleanedTab.tabs.certaintyScore"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1051796611
                        ),
                      },
                      [_c("CSResults")],
                      1
                    )
                  : _vm._e(),
                this.algorithms.SDS
                  ? _c(
                      "b-tab",
                      {
                        attrs: {
                          lazy: "",
                          active: this.activatedTab === "SDS",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-sm-inline-block" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "datafileResultCleanedTab.tabs.socialDesirabilityScores"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1671465611
                        ),
                      },
                      [_c("SDSResults")],
                      1
                    )
                  : _vm._e(),
                this.algorithms.IS
                  ? _c(
                      "b-tab",
                      {
                        attrs: { lazy: "", active: this.activatedTab === "IS" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-sm-inline-block" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "datafileResultCleanedTab.tabs.informationScore"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3424000260
                        ),
                      },
                      [_c("ISResults")],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.numberOfQualityScores === 1 &&
        _vm.dbDatafile.numberOfCleanedRecords > 0
          ? _c("div", [_c("OverviewResultsOneQualityScore")], 1)
          : _vm._e(),
        _vm.dbDatafile.numberOfCleanedRecords === 0
          ? _c(
              "div",
              {
                staticClass: "col-12 text-center mt-3",
                staticStyle: { "min-height": "200px" },
              },
              [
                _c("img", {
                  staticClass: "img-fluid mb-4 mt-3",
                  staticStyle: { width: "60px" },
                  attrs: {
                    src: require("@/assets/images/information.png"),
                    alt: "",
                  },
                }),
                _c("h5", [_vm._v(_vm._s(_vm.$t("general.noDatarecords")))]),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }