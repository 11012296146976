var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card animate__animated animate__fadeInUp animate__slow",
      style: [
        this.cleaned ? { background: "#FFFFFF" } : { background: "#FFF5F4" },
      ],
    },
    [
      _vm.isDisplayData
        ? _c("div", {}, [
            !_vm.isDisplayInfo
              ? _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("b-card-title", { staticClass: "pb-1" }, [
                      this.cleaned
                        ? _c(
                            "div",
                            { staticClass: "float-right" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn-sm",
                                  attrs: { variant: "light" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickViewMoreButton()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.qualityScoreWidget.showMoreButton"
                                      )
                                    ) + " "
                                  ),
                                  _c("i", {
                                    staticClass: "bx bx-right-arrow-alt ml-1",
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("h4", { staticClass: "card-title mb-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "qualityScoreWidgets.infomationScoreWidget.title"
                              )
                            ) +
                            " "
                        ),
                        _c("i", {
                          staticClass:
                            "bx bxs-info-circle font-size-20 text-info bx-tada ml-2",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.changeToShowInfo()
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "qualityScoreWidgets.infomationScoreWidget.description"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("span", [
                          _c("i", { staticClass: "bx bxs-circle text-mid" }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "qualityScoreWidgets.infomationScoreWidget.positiveNegativeTab.negative"
                                )
                              ) +
                              " % "
                          ),
                        ]),
                        _c("span", { staticClass: "ml-3" }, [
                          _c("i", {
                            staticClass: "bx bxs-circle text-success",
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "qualityScoreWidgets.infomationScoreWidget.positiveNegativeTab.positive"
                                )
                              ) +
                              " % "
                          ),
                        ]),
                      ]),
                    ]),
                    !_vm.isDisplayPosNegativeError
                      ? _c(
                          "b-progress",
                          {
                            staticClass: "mt-4",
                            staticStyle: { "font-size": "15px" },
                            attrs: { max: 100, height: "30px" },
                          },
                          [
                            _c("b-progress-bar", {
                              attrs: {
                                value:
                                  _vm.chartDataForPositiveNegative.negative,
                                variant: "mid",
                                "show-value": "",
                              },
                            }),
                            _c("b-progress-bar", {
                              attrs: {
                                value:
                                  _vm.chartDataForPositiveNegative.positive,
                                variant: "success",
                                "show-value": "",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "mt-3 text-center" },
                          [_c("DataFetchingErrorWidget")],
                          1
                        ),
                  ],
                  1
                )
              : _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-md-10" }, [
                      _c("h4", { staticClass: "card-title mb-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "qualityScoreWidgets.infomationScoreWidget.title"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-2 text-right" }, [
                      _c("i", {
                        staticClass:
                          "bx bxs-x-circle font-size-20 text-info bx-tada",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.changeToShowData()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text1"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text2"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text3"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text4"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text5"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text61"
                            )
                          ) +
                          " "
                      ),
                      _c("u", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text62"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text63"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text71"
                            )
                          ) +
                          " "
                      ),
                      _c("u", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text72"
                            )
                          )
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text73"
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text74"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.infomationScoreWidget.detailedInfo.useCase.title"
                            )
                          ) +
                          " "
                      ),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "qualityScoreWidgets.infomationScoreWidget.detailedInfo.useCase.text1"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "qualityScoreWidgets.infomationScoreWidget.detailedInfo.useCase.text2"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "qualityScoreWidgets.infomationScoreWidget.detailedInfo.useCase.text3"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "qualityScoreWidgets.infomationScoreWidget.detailedInfo.useCase.text4"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
          ])
        : _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center pt-5 pb-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }