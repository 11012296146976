var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { centered: "", "hide-footer": "", "hide-header": "" },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("b-card-title", { staticClass: "mb-4" }, [
              _c("div", { staticClass: "float-right" }, [
                _c("i", {
                  staticClass: "bx bx-x font-size-20",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.closeMode()
                    },
                  },
                }),
              ]),
              _c("h4", { staticClass: "card-title mb-3" }, [
                _vm._v("Grid-Question-Score"),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12 mb-3" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "qualityScoreWidgets.itemBatteryScoreWidget.detailedInfo.text1"
                )
              ) +
              " "
          ),
        ]),
        _c("div", { staticClass: "col-md-12 mb-3" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "qualityScoreWidgets.itemBatteryScoreWidget.detailedInfo.text2"
                )
              ) +
              " "
          ),
        ]),
        _c("div", { staticClass: "col-md-12 mb-3" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "qualityScoreWidgets.itemBatteryScoreWidget.detailedInfo.text3"
                )
              ) +
              " "
          ),
        ]),
        _c("div", { staticClass: "col-md-12 mb-3" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "qualityScoreWidgets.itemBatteryScoreWidget.detailedInfo.useCase.title"
                )
              ) +
              " "
          ),
          _c("ul", [
            _c("li", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "qualityScoreWidgets.itemBatteryScoreWidget.detailedInfo.useCase.text1"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }