<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-constant-condition */
import DatafileService from "@/api/services/datafile.service.js";
import DatafileResultService from "@/api/services/datafileResult.service.js";
import APIError from "@/shared/components/widgets/API-error.vue";
import { EventBus } from "@/shared/util/event-bus.js";
import cookie from "vue-cookies";
import CSResults from "./CS-results.vue";
import IBSResults from "./IBS-results.vue";
import ISResults from "./IS-results.vue";
import OESResults from "./OES-results.vue";
import OverviewResultsOneQualityScore from "./overview-result-for-one-quality-score.vue";
import OverviewResults from "./overview-results.vue";
import PSResults from "./PS-results.vue";
import SDSResults from "./SDS-results.vue";
import TSResults from "./TS-results.vue";
/**
 * Cleaned Results
 */
export default {
  components: {
    OverviewResults,
    TSResults,
    IBSResults,
    ISResults,
    CSResults,
    PSResults,
    SDSResults,
    OESResults,
    APIError,
    OverviewResultsOneQualityScore
  },
  data() {
    return {
      /*******  Data variables ********/
      datafileId: null,
      dbDatafile: {},
      algorithms: {},
      nonTimescoreDatapoints: [],
      numberOfQualityScores: 0,
      /******** Operational Variables *********/
      activatedTab: null,
      isDisplayData: false,
      errorAllDatapoints: false,
      fullWidth: "col-lg-12",
      halfWidth: "col-lg-6",
      quaterWidth: "col-lg-4",
      fullHeight: "height: 400px;",
      halfHeight: "height: 225px;",
      isDisplayTimeScoreResults: false,
      isDisplayItemBatteryScoreResults: false,
      isDisplayCertaintyScoreResults: false,
      isDisplaySocialDesirabilityScoreResults: false,
      isDisplayPredictionScoreResults: false,
      isDisplayOpenEndedScoreResults: false,
      socket: null
    };
  },
  async created() {
    this.datafileId = this.$route.query.datafileId;
    this.dbDatafile = await DatafileService.getDatafile(this.datafileId);
    if (this.dbDatafile.isLiveCleaning === "RUNNING") {
      this.initializeWebSocketSubscription();
    }
    DatafileResultService.getAllDataPoints(this.datafileId)
      .then(() => {
        this.isDisplayData = true;
      })
      .catch((e) => {
        this.errorAllDatapoints = true;
      });
    DatafileService.getAlgorithmsInDatafile(this.datafileId)
      .then((data) => {
        this.algorithms = data;
        for (const key in this.algorithms) {
          if (this.algorithms[key]) this.numberOfQualityScores += 1;
        }
      })
      .catch(() => {
        this.errorAllDatapoints = true;
      });
  },
  beforeDestroy() {
    EventBus.$emit("closeCleanedResults");
    this.destroySocket();
    EventBus.$off("closeCleanedResults");
  },
  watch: {},
  methods: {
    destroySocket() {
      if (this.socket) {
        this.socket.close(1000, String(this.datafileId) + ";CLEANED");
        this.socket = null;
      }
    },
    initializeWebSocketSubscription() {
      const socket = new WebSocket(process.env.VUE_APP_WEB_SOCKET_SERVER);
      this.socket = socket;
      const token = cookie.get("accessToken");
      socket.onopen = async () => {
        socket.send(this.datafileId + ";CLEANED;" + token);
      };
      socket.onmessage = () => {
        // Check if records are there if they are 0
        if (this.dbDatafile.numberOfCleanedRecords === 0) {
          DatafileService.getDatafile(this.datafileId).then((datafile) => {
            this.dbDatafile = datafile;
          });
        }
      };
    },
    changeActivatedTab(qualityScore) {
      this.activatedTab = qualityScore;
    }
  }
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <!-- Dispaly Results Mutiple Quality Scores -->
      <b-tabs
        v-if="
          numberOfQualityScores > 1 && dbDatafile.numberOfCleanedRecords > 0
        "
        content-class="pt-3"
      >
        <!-- Overview Tab -->
        <b-tab
          lazy
          :active="this.activatedTab === 'OVERVIEW'"
          @click="changeActivatedTab('OVERVIEW')"
        >
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">
              {{ $t("datafileResultCleanedTab.tabs.overview") }}
            </span>
          </template>
          <OverviewResults @changeTab="changeActivatedTab" />
        </b-tab>

        <!-- Open Ended Score Tab -->
        <b-tab
          lazy
          v-if="this.algorithms.OES"
          :active="this.activatedTab === 'OES'"
        >
          <template v-slot:title>
            <span class="d-none d-sm-inline-block"> Open-Ended-Score</span>
          </template>
          <OESResults />
        </b-tab>

        <!-- Time Score Tab -->
        <b-tab
          lazy
          v-if="this.algorithms.TS"
          :active="this.activatedTab === 'TS'"
        >
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">
              {{ $t("datafileResultCleanedTab.tabs.timeScore") }}
            </span>
          </template>
          <TSResults />
        </b-tab>

        <!-- Item Battery Score Tab  -->
        <b-tab
          lazy
          v-if="this.algorithms.IBS"
          :active="this.activatedTab === 'IBS'"
        >
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">
              {{ $t("datafileResultCleanedTab.tabs.itemBatteryScore") }}
            </span>
          </template>
          <IBSResults />
        </b-tab>

        <!--Prediction Score Tab -->
        <b-tab
          lazy
          v-if="this.algorithms.PS"
          :active="this.activatedTab === 'PS'"
        >
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">
              {{ $t("datafileResultCleanedTab.tabs.predictionScore") }}
            </span>
          </template>
          <PSResults />
        </b-tab>

        <!-- Certainty Score Tab -->
        <b-tab
          lazy
          v-if="this.algorithms.CS"
          :active="this.activatedTab === 'CS'"
        >
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">
              {{ $t("datafileResultCleanedTab.tabs.certaintyScore") }}
            </span>
          </template>
          <CSResults />
        </b-tab>

        <!-- Social Desirability Score Tab -->
        <b-tab
          lazy
          v-if="this.algorithms.SDS"
          :active="this.activatedTab === 'SDS'"
        >
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">
              {{ $t("datafileResultCleanedTab.tabs.socialDesirabilityScores") }}
            </span>
          </template>
          <SDSResults />
        </b-tab>

        <!-- Infomation Score Tab -->
        <b-tab
          lazy
          v-if="this.algorithms.IS"
          :active="this.activatedTab === 'IS'"
        >
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">
              {{ $t("datafileResultCleanedTab.tabs.informationScore") }}
            </span>
          </template>
          <ISResults />
        </b-tab>
      </b-tabs>

      <!-- Display Results Single Quality Score -->
      <div
        v-if="
          numberOfQualityScores === 1 && dbDatafile.numberOfCleanedRecords > 0
        "
      >
        <OverviewResultsOneQualityScore />
      </div>

      <!-- No Data records -->
      <div
        v-if="dbDatafile.numberOfCleanedRecords === 0"
        class="col-12 text-center mt-3"
        style="min-height: 200px"
      >
        <img
          src="@/assets/images/information.png"
          alt
          class="img-fluid mb-4 mt-3"
          style="width: 60px"
        />
        <h5>{{ $t("general.noDatarecords") }}</h5>
      </div>
    </div>
  </div>
</template>
