var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card",
      staticStyle: { height: "450px" },
      style: [
        this.cleaned ? { background: "#FFFFFF" } : { background: "#FFF5F4" },
      ],
    },
    [
      _vm.isDisplayData
        ? _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "card-title" }, [
              !_vm.displayBenchmarkChart
                ? _c(
                    "div",
                    { staticClass: "float-right" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-sm",
                          attrs: { variant: "light" },
                          on: {
                            click: function ($event) {
                              return _vm.clickViewMoreButton()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "datafileResultGeneral.qualityScoreWidget.showMoreButton"
                              )
                            ) + " "
                          ),
                          _c("i", {
                            staticClass: "bx bx-right-arrow-alt ml-1",
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(
                " " +
                  _vm._s(_vm.$t("datafileResultGeneral.rScoreWidget.title")) +
                  " "
              ),
              !this.showRScoreInfoProp
                ? _c("i", {
                    staticClass:
                      "bx bxs-info-circle font-size-16 text-info bx-tada",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.clickInfoRScoreWidget()
                      },
                    },
                  })
                : _c("i", {
                    staticClass:
                      "bx bxs-x-circle font-size-16 text-info bx-tada",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.clickInfoRScoreWidget()
                      },
                    },
                  }),
            ]),
            _c("div", { staticClass: "card-text mt-4" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("datafileResultGeneral.rScoreWidget.description")
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "card-text mt-3" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    !_vm.displayBenchmarkChart
                      ? _c(
                          "b-tabs",
                          [
                            _c(
                              "b-tab",
                              {
                                attrs: { active: "", lazy: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-none d-sm-inline-block",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "datafileResultGeneral.rScoreWidget.tab.meanLabel"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  735418767
                                ),
                              },
                              [
                                _c("div", [
                                  (!_vm.isDisplayRScoreError &&
                                    this.cleaned &&
                                    !_vm.displayBenchmarkChart) ||
                                  (!_vm.isDisplayRScoreError &&
                                    !this.cleaned &&
                                    !_vm.displayBenchmarkChart)
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-3" },
                                        [
                                          _c("apexchart", {
                                            ref: "meanChart",
                                            staticClass:
                                              "apex-charts apexChartData",
                                            attrs: {
                                              type: "radialBar",
                                              height: "280",
                                              dir: "ltr",
                                              series: [
                                                Math.round(
                                                  this.rScoreChartData
                                                ),
                                              ],
                                              options:
                                                _vm.rScoreChartOptions
                                                  .chartOptions,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : !_vm.isDisplayRScoreError &&
                                      !this.cleaned &&
                                      _vm.displayBenchmarkChart
                                    ? _c(
                                        "div",
                                        { staticClass: "col-12" },
                                        [
                                          _c("apexchart", {
                                            ref: "meanChart",
                                            staticClass:
                                              "apex-charts apexChartData",
                                            attrs: {
                                              type: "radialBar",
                                              height: "280",
                                              dir: "ltr",
                                              series: [
                                                Math.round(
                                                  this.rScoreChartData
                                                ),
                                                Math.round(
                                                  this.rScoreBenchmarkChartData
                                                ),
                                              ],
                                              options:
                                                _vm.rScoreBenchmarkChartOptions
                                                  .chartOptions,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm.benchmarkDataNotAvailable
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-center col-12 mt-2",
                                        },
                                        [
                                          _c(
                                            "b-alert",
                                            {
                                              attrs: {
                                                show: "",
                                                variant: "info",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "datafileResultGeneral.benchmarkNotAvailable"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "text-center mt-3" },
                                        [_c("DataFetchingErrorWidget")],
                                        1
                                      ),
                                ]),
                              ]
                            ),
                            !_vm.displayBenchmarkChart
                              ? _c(
                                  "b-tab",
                                  {
                                    attrs: { lazy: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "title",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-none d-sm-inline-block",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "datafileResultGeneral.rScoreWidget.tab.rScorePresentation"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2572693026
                                    ),
                                  },
                                  [
                                    _c("div", [
                                      !_vm.isDisplayRScorePresentationError
                                        ? _c(
                                            "div",
                                            [
                                              _c("apexchart", {
                                                ref: "RScorePresenattionChart",
                                                staticClass: "apex-charts mt-4",
                                                attrs: {
                                                  height: "280",
                                                  type: "pie",
                                                  dir: "ltr",
                                                  series:
                                                    _vm.rScorePresentationChartData,
                                                  options:
                                                    _vm
                                                      .rScorePresentationChartOptions
                                                      .chartOptions,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "text-center mt-3" },
                                            [_c("DataFetchingErrorWidget")],
                                            1
                                          ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c("div", [
                          (!_vm.isDisplayRScoreError &&
                            this.cleaned &&
                            !_vm.displayBenchmarkChart) ||
                          (!_vm.isDisplayRScoreError &&
                            !this.cleaned &&
                            !_vm.displayBenchmarkChart)
                            ? _c(
                                "div",
                                { staticClass: "mt-3" },
                                [
                                  _c("apexchart", {
                                    ref: "meanChart",
                                    staticClass: "apex-charts apexChartData",
                                    attrs: {
                                      type: "radialBar",
                                      height: "280",
                                      dir: "ltr",
                                      series: [
                                        Math.round(this.rScoreChartData),
                                      ],
                                      options:
                                        _vm.rScoreChartOptions.chartOptions,
                                    },
                                  }),
                                ],
                                1
                              )
                            : !_vm.isDisplayRScoreError &&
                              !this.cleaned &&
                              _vm.displayBenchmarkChart
                            ? _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("apexchart", {
                                    ref: "meanChart",
                                    staticClass: "apex-charts apexChartData",
                                    attrs: {
                                      type: "radialBar",
                                      height: "280",
                                      dir: "ltr",
                                      series: [
                                        Math.round(this.rScoreChartData),
                                        Math.round(
                                          this.rScoreBenchmarkChartData
                                        ),
                                      ],
                                      options:
                                        _vm.rScoreBenchmarkChartOptions
                                          .chartOptions,
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center mt-3",
                                    },
                                    [
                                      _c("div", { staticClass: "mx-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "text-center" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "bx bxs-circle text-primary",
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "datafileResultGeneral.yourData"
                                                  )
                                                ) +
                                                ": "
                                            ),
                                            _c("strong", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    Math.round(
                                                      this.rScoreChartData
                                                    )
                                                  )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "mx-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "text-center" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "bx bxs-circle text-success",
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "datafileResultGeneral.benchmark"
                                                  )
                                                ) +
                                                ": "
                                            ),
                                            _c("strong", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    Math.round(
                                                      this
                                                        .rScoreBenchmarkChartData
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm.benchmarkDataNotAvailable
                            ? _c(
                                "div",
                                { staticClass: "text-center col-12 mt-2" },
                                [
                                  _c(
                                    "b-alert",
                                    { attrs: { show: "", variant: "info" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "datafileResultGeneral.benchmarkNotAvailable"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "text-center mt-3" },
                                [_c("DataFetchingErrorWidget")],
                                1
                              ),
                        ]),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center pt-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }