<script>
import DatafileReportService from "@/api/services/datafileReport.service.js";
import S3Service from "@/api/services/s3.service";
import CompanyAvatarCropperModal from "@/shared/components/model/company-avatar-cropper-modal.vue";
import axios from "axios";
import QrcodeVue from "qrcode.vue";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    datafileId: String,
    datafileTitle: String
  },
  components: {
    CompanyAvatarCropperModal,
    QrcodeVue
  },
  data() {
    return {
      reportManageData: null,
      clientName: null,
      enablePasswordForReport: true,
      enebleQualityScoreWidgets: true,
      enebleShortVerionOfReport: false,
      datafileReportPassword: null,
      datafileReportOldPassword: null,
      updateClentLogoErrorMsg: null,
      clientLogoImageFile: null,
      croppedClientLogoImageFile: null,
      clientLogoImageURL: null,
      datafileReportURL: null,
      /***** Operational Variable *****/
      showCropperModal: false,
      enebleEditPassword: false,
      passwordCopySuccess: false,
      passwordCopyFail: false,
      updateClientLogoFailed: false,
      reportLinkCopySuccess: false,
      reportLinkCopyFail: false,
      setManageReportDataSuccess: false,
      setManageReportDataError: false,
      isPasswordRequiredError: false
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  created() {
    this.getDataForManageReport();
  },
  methods: {
    async getDataForManageReport() {
      let reportManageData = await DatafileReportService.getManageReportData(
        this.datafileId
      );
      if (reportManageData) {
        this.reportManageData = reportManageData;
        this.clientName = reportManageData.client.name;
        this.clientLogoImageURL = reportManageData.client.logo;
        this.enebleQualityScoreWidgets =
          reportManageData.isDisplayQualityScoreWidgets;
        this.enablePasswordForReport = reportManageData.isPasswordEnable;
        this.datafileReportURL = reportManageData.reportURL;
        this.datafileReportPassword = reportManageData.password;
        this.enebleShortVerionOfReport = reportManageData.isDisplayShortReport;

        if (!this.datafileReportPassword) {
          this.enebleEditPassword = true;
        }
      }
    },
    copyDatafileReportPasswordClipboard() {
      try {
        navigator.clipboard.writeText(this.datafileReportPassword);
        this.passwordCopySuccess = true;
      } catch (err) {
        this.passwordCopyFail = true;
        throw err;
      }
    },
    changeEditPassword() {
      if (this.enebleEditPassword) {
        this.datafileReportOldPassword = this.datafileReportPassword;
      } else {
        this.datafileReportPassword = this.datafileReportOldPassword;
      }
    },
    clickCancel() {
      this.$emit("close");
    },
    clientAvatarSelected() {
      // 1. Clearn the Canvers
      // 2. get image from computer and pass to cropper

      const imagePreviewCanvers = this.$refs.clientAvatarImageCanvas;
      const imagePreviewCanversContext = imagePreviewCanvers.getContext("2d");
      imagePreviewCanversContext.clearRect(
        0,
        0,
        imagePreviewCanvers.width,
        imagePreviewCanvers.height
      );

      const avatarImageFile = this.$refs.clientAvatarInput.files[0];
      if (!avatarImageFile.type.startsWith("image/")) {
        this.updateClientLogoFailed = true;
        this.updateClentLogoErrorMsg = this.$t(
          "editProfileModal.profileTab.form.notAnImageFileErrorMsg"
        );
      } else {
        this.openCropAvatarImageModal(avatarImageFile);
      }
    },
    openCropAvatarImageModal(avatarImageFile) {
      this.clientLogoImageFile = avatarImageFile;
      this.showCropperModal = true;
    },
    async setCroppedImagePreview(croppedImage) {
      // 1. set Crop image to Canvers
      // 2. set cropped image file to data variable
      // 3. get pre signed URL for user avatar
      // 4. upload imaeg to S3 using pre signed URL and get file URL as return
      // 5. cut and chop final user avatrt iamge URL and set to data variable

      const imagePreview = this.$refs.clientAvatarImagePreview;
      const imagePreviewCanvers = this.$refs.clientAvatarImageCanvas;
      if (imagePreview && imagePreviewCanvers.getContext) {
        const imagePreviewCanversContext = imagePreviewCanvers.getContext("2d");
        imagePreviewCanversContext.drawImage(
          croppedImage,
          imagePreviewCanvers.width / 2,
          0,
          40,
          40
        );
      }
      this.croppedClientLogoImageFile = croppedImage;
      this.showCropperModal = false;

      const preSignedURL = await S3Service.getClientLogoAvatarPreSignedURL(
        this.clientLogoImageFile.name,
        this.datafileId
      );

      if (preSignedURL) {
        const croppedClientLogoAvatar = this.croppedClientLogoImageFile;
        const originalClientLogoImageFile = this.clientLogoImageFile;

        const clientLogoAvatarImageFile = await new Promise(function (resolve) {
          return croppedClientLogoAvatar.toBlob(
            resolve,
            originalClientLogoImageFile.type
          );
        });

        // Upload Image to S3
        const getFileUploadResponse = await axios.put(
          preSignedURL.signedUrl,
          clientLogoAvatarImageFile
        );

        // is axios.put work assign file name to file URL
        if (getFileUploadResponse) {
          const fullImageURL = getFileUploadResponse.config.url;
          const splitedtImageURL = fullImageURL.split(
            "/datafile_reports_client_avatars"
          )[0];
          this.clientLogoImageURL =
            splitedtImageURL +
            "/datafile_reports_client_avatars/" +
            this.datafileId +
            "/" +
            originalClientLogoImageFile.name;
        }
      }
    },
    copyDatafileReportURLClipboard() {
      try {
        navigator.clipboard.writeText(this.datafileReportURL);
        this.reportLinkCopySuccess = true;
      } catch (err) {
        this.reportLinkCopyFail = true;
        throw err;
      }
    },
    async submitForm() {
      if (this.enablePasswordForReport && !this.datafileReportPassword) {
        this.isPasswordRequiredError = true;
      } else {
        let response = await DatafileReportService.setDatafileReportManageData({
          datafileId: this.datafileId,
          clientName: this.clientName,
          clientImage: this.clientLogoImageURL,
          isDisplayQualityScoreWidgets: this.enebleQualityScoreWidgets,
          isPasswordEnable: this.enablePasswordForReport,
          password: this.datafileReportPassword,
          isDisplayShortReport: this.enebleShortVerionOfReport
        });

        if (response.status === "SUCCESS") {
          this.setManageReportDataSuccess = true;
          this.$emit("reload");
        } else {
          this.setManageReportDataError = true;
        }
      }
    },
    async clickDownloadQRCode() {
      const el = this.$refs.printcontent;

      const options = {
        type: "dataURL"
      };
      const printCanvas = await this.$html2canvas(el, options);

      const link = document.createElement("a");
      const qrCodeImageName =
        this.datafileTitle + "- Redem Quality Report - QR.png";
      link.setAttribute("download", qrCodeImageName);
      link.setAttribute("href", printCanvas);
      link.click();
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    size="md"
  >
    <b-form @submit.prevent="submitForm">
      <!-- Title -->
      <div class="row">
        <div class="col-md-12">
          <h5>{{ $t("datafileResultGeneral.manageReportModal.title") }}</h5>
        </div>
      </div>

      <!-- Alerts -->
      <div class="row">
        <div class="col-md-12">
          <!-- Password is Required -->
          <b-alert
            v-model="isPasswordRequiredError"
            class="mt-3 mb-2"
            variant="danger"
            dismissible
            >{{
              $t(
                "datafileResultGeneral.manageReportModal.alerts.isPasswordRequired"
              )
            }}</b-alert
          >

          <!-- Datafile Report Manage Data Set Success-->
          <b-alert
            v-model="setManageReportDataSuccess"
            class="mt-3 mb-2"
            variant="success"
            dismissible
            >{{
              $t(
                "datafileResultGeneral.manageReportModal.alerts.setManageReportDataSuccess"
              )
            }}</b-alert
          >

          <!-- Datafile Report Manage Data Set Error-->
          <b-alert
            v-model="setManageReportDataError"
            class="mt-3 mb-2"
            variant="danger"
            dismissible
            >{{
              $t(
                "datafileResultGeneral.manageReportModal.alerts.setManageReportDataError"
              )
            }}</b-alert
          >

          <!-- Datafile Report password copy success -->
          <b-alert
            v-model="passwordCopySuccess"
            class="mt-3 mb-2"
            variant="success"
            dismissible
            >{{
              $t(
                "datafileResultGeneral.manageReportModal.alerts.passwordCopySuccess"
              )
            }}</b-alert
          >

          <!-- Datafile Report password copy fail -->
          <b-alert
            v-model="passwordCopyFail"
            class="mt-3"
            variant="danger"
            dismissible
            >{{
              $t(
                "datafileResultGeneral.manageReportModal.alerts.passwordCopyFails"
              )
            }}</b-alert
          >

          <!-- Datafile Report Link copy success -->
          <b-alert
            v-model="reportLinkCopySuccess"
            class="mt-3 mb-2"
            variant="success"
            dismissible
            >{{
              $t(
                "datafileResultGeneral.manageReportModal.alerts.reportLinkCopySuccess"
              )
            }}</b-alert
          >

          <!-- Datafile Report Link copy fail -->
          <b-alert
            v-model="reportLinkCopyFail"
            class="mt-3"
            variant="danger"
            dismissible
            >{{
              $t(
                "datafileResultGeneral.manageReportModal.alerts.reportLinkCopyFails"
              )
            }}</b-alert
          >

          <!-- Datafile Report Client Image Errors -->
          <b-alert
            v-model="updateClientLogoFailed"
            class="mt-3"
            variant="danger"
            dismissible
            >{{ this.updateClentLogoErrorMsg }}</b-alert
          >
        </div>
      </div>

      <!-- Form -->
      <div class="row mb-4 mt-4">
        <div class="col-md-12">
          <b-tabs>
            <b-tab>
              <template v-slot:title>
                {{
                  $t("datafileResultGeneral.manageReportModal.tabs.settings")
                }}
              </template>
              <div class="mt-3">
                <!-- Display Image Section -->
                <div class="col-md-12 text-center">
                  <div ref="clientAvatarImagePreview">
                    <canvas
                      id="clientAvatarImageCanvas"
                      ref="clientAvatarImageCanvas"
                      height="40"
                    ></canvas>
                    <div v-if="!croppedClientLogoImageFile">
                      <img
                        v-if="clientLogoImageURL"
                        :src="clientLogoImageURL"
                        alt
                        height="40"
                      />
                      <img
                        v-else
                        src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg"
                        alt
                        height="40"
                      />
                    </div>
                  </div>
                </div>

                <!-- Image Input Section -->
                <div class="col-md-12 text-center">
                  <div class="text-muted">
                    <p class="mb-1">
                      <input
                        id="clientAvatarInput"
                        ref="clientAvatarInput"
                        type="file"
                        name="file"
                        accept="image/*"
                        @change="clientAvatarSelected()"
                        style="width: 0.1px; opacity: 0; z-index: -1"
                      />
                      <label
                        for="clientAvatarInput"
                        class="text-info mr-2"
                        style="cursor: pointer"
                      >
                        {{
                          $t(
                            "datafileResultGeneral.manageReportModal.form.image.uploadButton"
                          )
                        }}
                      </label>
                      <br />
                      {{
                        $t(
                          "datafileResultGeneral.manageReportModal.form.image.whyText"
                        )
                      }}
                    </p>
                  </div>
                </div>

                <!-- Cient Name -->
                <div class="col-md-12 mt-3 text-center">
                  <b-form-group id="" label-for="clientName">
                    <template v-slot:label>
                      {{
                        $t(
                          "datafileResultGeneral.manageReportModal.form.clientName.label"
                        )
                      }}
                    </template>
                    <b-form-input
                      id="clientName"
                      v-model="clientName"
                      type="text"
                      :placeholder="
                        this.$t(
                          'datafileResultGeneral.manageReportModal.form.clientName.placeholder'
                        )
                      "
                    ></b-form-input>
                  </b-form-group>
                </div>

                <!-- Eneble Quality Score Widgets -->
                <div class="col-md-12 mb-2 mt-4">
                  <b-form-checkbox
                    v-model="enebleQualityScoreWidgets"
                    name="check-button"
                    switch
                  >
                    {{
                      $t(
                        "datafileResultGeneral.manageReportModal.form.qualityScores.enableLabel"
                      )
                    }}
                  </b-form-checkbox>
                </div>

                <!-- Eneble Short format of Report -->
                <div class="col-md-12 mb-2">
                  <b-form-checkbox
                    v-model="enebleShortVerionOfReport"
                    name="check-button"
                    switch
                  >
                    {{
                      $t(
                        "datafileResultGeneral.manageReportModal.form.shortFormat.enableLabel"
                      )
                    }}
                  </b-form-checkbox>
                </div>

                <!-- Eneble Password for Datafile -->
                <div class="col-md-12 mb-4">
                  <b-form-checkbox
                    v-model="enablePasswordForReport"
                    name="check-button"
                    switch
                  >
                    {{
                      $t(
                        "datafileResultGeneral.manageReportModal.form.password.enableLabel"
                      )
                    }}
                  </b-form-checkbox>
                </div>

                <!--  Password  -->
                <div v-if="enablePasswordForReport">
                  <hr />
                  <!--  Password  Label -->
                  <div class="row mt-4">
                    <div class="col-md-6">
                      <label>
                        {{
                          $t(
                            "datafileResultGeneral.manageReportModal.form.password.label"
                          )
                        }}</label
                      >
                    </div>
                    <div class="col-md-6 text-right">
                      <b-form-checkbox
                        v-model="enebleEditPassword"
                        name="check-button"
                        switch
                        @change="changeEditPassword()"
                      >
                        {{
                          $t(
                            "datafileResultGeneral.manageReportModal.form.password.editButton"
                          )
                        }}
                      </b-form-checkbox>
                    </div>
                  </div>

                  <!--  Password  Edit -->
                  <div class="row mb-4">
                    <div class="col-md-12">
                      <!-- Password Edit Disble -->
                      <div
                        class="input-group bg-light"
                        v-if="!this.enebleEditPassword"
                      >
                        <input
                          type="password"
                          class="form-control bg-transparent border-1"
                          v-model="datafileReportPassword"
                          disabled
                        />
                        <button
                          class="btn btn-primary"
                          type="button"
                          id="button-addon2"
                          @click="copyDatafileReportPasswordClipboard()"
                        >
                          <i class="bx bx-copy-alt"></i>
                        </button>
                      </div>
                      <!-- Password Edit Eneble -->
                      <div class="input-group" v-if="this.enebleEditPassword">
                        <input
                          type="text"
                          class="form-control bg-transparent border-1"
                          v-model="datafileReportPassword"
                          :placeholder="
                            this.$t(
                              'datafileResultGeneral.manageReportModal.form.password.placeholder'
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <template v-slot:title>
                {{ $t("datafileResultGeneral.manageReportModal.tabs.share") }}
              </template>
              <div class="text-center">
                <!-- Report QR -->
                <div class="row mt-4 mb-4">
                  <div class="col-md-3"></div>
                  <div class="col-md-6">
                    <div ref="printcontent" id="qrCode" class="p-2">
                      <qrcode-vue
                        :value="datafileReportURL"
                        :size="150"
                        level="H"
                      />
                    </div>
                    <div
                      class="text-info"
                      style="cursor: pointer"
                      @click="clickDownloadQRCode"
                    >
                      Click Here to Download QR
                    </div>
                  </div>
                  <div class="col-md-3"></div>
                </div>

                <!-- Report ULR -->
                <div class="col-md-12">
                  <label>
                    {{
                      $t(
                        "datafileResultGeneral.manageReportModal.form.link.label"
                      )
                    }}</label
                  >
                  <div class="input-group bg-light">
                    <input
                      type="text"
                      class="form-control bg-transparent border-1"
                      v-model="datafileReportURL"
                      disabled
                    />
                    <button
                      class="btn btn-primary"
                      type="button"
                      id="button-addon2"
                      @click="copyDatafileReportURLClipboard()"
                    >
                      <i class="bx bx-copy-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <!-- Action Buttons -->
      <div class="row mt-5 text-center">
        <div class="col-md-12">
          <b-button type="submit" variant="success" class="mr-2"
            ><i class="bx bx-save mr-2"></i
            >{{
              $t(
                "datafileResultGeneral.manageReportModal.form.actions.saveButton"
              )
            }}</b-button
          >

          <b-button type="reset" variant="light" @click="clickCancel()"
            ><i class="bx bx-x mr-2"></i
            >{{
              $t(
                "datafileResultGeneral.manageReportModal.form.actions.cancelButton"
              )
            }}</b-button
          >
        </div>
      </div>
    </b-form>

    <!-- Client Avatar Cropper Modal -->
    <CompanyAvatarCropperModal
      v-if="showCropperModal"
      :visible="showCropperModal"
      :imageFile="clientLogoImageFile"
      @close="showCropperModal = false"
      @apply="setCroppedImagePreview"
    />
  </b-modal>
</template>
