var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
        size: "md",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("h5", [
                _vm._v(
                  _vm._s(
                    _vm.$t("datafileResultGeneral.manageReportModal.title")
                  )
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-alert",
                  {
                    staticClass: "mt-3 mb-2",
                    attrs: { variant: "danger", dismissible: "" },
                    model: {
                      value: _vm.isPasswordRequiredError,
                      callback: function ($$v) {
                        _vm.isPasswordRequiredError = $$v
                      },
                      expression: "isPasswordRequiredError",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileResultGeneral.manageReportModal.alerts.isPasswordRequired"
                        )
                      )
                    ),
                  ]
                ),
                _c(
                  "b-alert",
                  {
                    staticClass: "mt-3 mb-2",
                    attrs: { variant: "success", dismissible: "" },
                    model: {
                      value: _vm.setManageReportDataSuccess,
                      callback: function ($$v) {
                        _vm.setManageReportDataSuccess = $$v
                      },
                      expression: "setManageReportDataSuccess",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileResultGeneral.manageReportModal.alerts.setManageReportDataSuccess"
                        )
                      )
                    ),
                  ]
                ),
                _c(
                  "b-alert",
                  {
                    staticClass: "mt-3 mb-2",
                    attrs: { variant: "danger", dismissible: "" },
                    model: {
                      value: _vm.setManageReportDataError,
                      callback: function ($$v) {
                        _vm.setManageReportDataError = $$v
                      },
                      expression: "setManageReportDataError",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileResultGeneral.manageReportModal.alerts.setManageReportDataError"
                        )
                      )
                    ),
                  ]
                ),
                _c(
                  "b-alert",
                  {
                    staticClass: "mt-3 mb-2",
                    attrs: { variant: "success", dismissible: "" },
                    model: {
                      value: _vm.passwordCopySuccess,
                      callback: function ($$v) {
                        _vm.passwordCopySuccess = $$v
                      },
                      expression: "passwordCopySuccess",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileResultGeneral.manageReportModal.alerts.passwordCopySuccess"
                        )
                      )
                    ),
                  ]
                ),
                _c(
                  "b-alert",
                  {
                    staticClass: "mt-3",
                    attrs: { variant: "danger", dismissible: "" },
                    model: {
                      value: _vm.passwordCopyFail,
                      callback: function ($$v) {
                        _vm.passwordCopyFail = $$v
                      },
                      expression: "passwordCopyFail",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileResultGeneral.manageReportModal.alerts.passwordCopyFails"
                        )
                      )
                    ),
                  ]
                ),
                _c(
                  "b-alert",
                  {
                    staticClass: "mt-3 mb-2",
                    attrs: { variant: "success", dismissible: "" },
                    model: {
                      value: _vm.reportLinkCopySuccess,
                      callback: function ($$v) {
                        _vm.reportLinkCopySuccess = $$v
                      },
                      expression: "reportLinkCopySuccess",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileResultGeneral.manageReportModal.alerts.reportLinkCopySuccess"
                        )
                      )
                    ),
                  ]
                ),
                _c(
                  "b-alert",
                  {
                    staticClass: "mt-3",
                    attrs: { variant: "danger", dismissible: "" },
                    model: {
                      value: _vm.reportLinkCopyFail,
                      callback: function ($$v) {
                        _vm.reportLinkCopyFail = $$v
                      },
                      expression: "reportLinkCopyFail",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileResultGeneral.manageReportModal.alerts.reportLinkCopyFails"
                        )
                      )
                    ),
                  ]
                ),
                _c(
                  "b-alert",
                  {
                    staticClass: "mt-3",
                    attrs: { variant: "danger", dismissible: "" },
                    model: {
                      value: _vm.updateClientLogoFailed,
                      callback: function ($$v) {
                        _vm.updateClientLogoFailed = $$v
                      },
                      expression: "updateClientLogoFailed",
                    },
                  },
                  [_vm._v(_vm._s(this.updateClentLogoErrorMsg))]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row mb-4 mt-4" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-tabs",
                  [
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.manageReportModal.tabs.settings"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "mt-3" }, [
                          _c("div", { staticClass: "col-md-12 text-center" }, [
                            _c("div", { ref: "clientAvatarImagePreview" }, [
                              _c("canvas", {
                                ref: "clientAvatarImageCanvas",
                                attrs: {
                                  id: "clientAvatarImageCanvas",
                                  height: "40",
                                },
                              }),
                              !_vm.croppedClientLogoImageFile
                                ? _c("div", [
                                    _vm.clientLogoImageURL
                                      ? _c("img", {
                                          attrs: {
                                            src: _vm.clientLogoImageURL,
                                            alt: "",
                                            height: "40",
                                          },
                                        })
                                      : _c("img", {
                                          attrs: {
                                            src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg",
                                            alt: "",
                                            height: "40",
                                          },
                                        }),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-12 text-center" }, [
                            _c("div", { staticClass: "text-muted" }, [
                              _c("p", { staticClass: "mb-1" }, [
                                _c("input", {
                                  ref: "clientAvatarInput",
                                  staticStyle: {
                                    width: "0.1px",
                                    opacity: "0",
                                    "z-index": "-1",
                                  },
                                  attrs: {
                                    id: "clientAvatarInput",
                                    type: "file",
                                    name: "file",
                                    accept: "image/*",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.clientAvatarSelected()
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-info mr-2",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { for: "clientAvatarInput" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.manageReportModal.form.image.uploadButton"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.manageReportModal.form.image.whyText"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-md-12 mt-3 text-center" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { id: "", "label-for": "clientName" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "datafileResultGeneral.manageReportModal.form.clientName.label"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "clientName",
                                      type: "text",
                                      placeholder: this.$t(
                                        "datafileResultGeneral.manageReportModal.form.clientName.placeholder"
                                      ),
                                    },
                                    model: {
                                      value: _vm.clientName,
                                      callback: function ($$v) {
                                        _vm.clientName = $$v
                                      },
                                      expression: "clientName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-12 mb-2 mt-4" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { name: "check-button", switch: "" },
                                  model: {
                                    value: _vm.enebleQualityScoreWidgets,
                                    callback: function ($$v) {
                                      _vm.enebleQualityScoreWidgets = $$v
                                    },
                                    expression: "enebleQualityScoreWidgets",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "datafileResultGeneral.manageReportModal.form.qualityScores.enableLabel"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-12 mb-2" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { name: "check-button", switch: "" },
                                  model: {
                                    value: _vm.enebleShortVerionOfReport,
                                    callback: function ($$v) {
                                      _vm.enebleShortVerionOfReport = $$v
                                    },
                                    expression: "enebleShortVerionOfReport",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "datafileResultGeneral.manageReportModal.form.shortFormat.enableLabel"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-12 mb-4" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { name: "check-button", switch: "" },
                                  model: {
                                    value: _vm.enablePasswordForReport,
                                    callback: function ($$v) {
                                      _vm.enablePasswordForReport = $$v
                                    },
                                    expression: "enablePasswordForReport",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "datafileResultGeneral.manageReportModal.form.password.enableLabel"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.enablePasswordForReport
                            ? _c("div", [
                                _c("hr"),
                                _c("div", { staticClass: "row mt-4" }, [
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("label", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "datafileResultGeneral.manageReportModal.form.password.label"
                                            )
                                          )
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 text-right" },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          attrs: {
                                            name: "check-button",
                                            switch: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeEditPassword()
                                            },
                                          },
                                          model: {
                                            value: _vm.enebleEditPassword,
                                            callback: function ($$v) {
                                              _vm.enebleEditPassword = $$v
                                            },
                                            expression: "enebleEditPassword",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "datafileResultGeneral.manageReportModal.form.password.editButton"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "row mb-4" }, [
                                  _c("div", { staticClass: "col-md-12" }, [
                                    !this.enebleEditPassword
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "input-group bg-light",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.datafileReportPassword,
                                                  expression:
                                                    "datafileReportPassword",
                                                },
                                              ],
                                              staticClass:
                                                "form-control bg-transparent border-1",
                                              attrs: {
                                                type: "password",
                                                disabled: "",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.datafileReportPassword,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.datafileReportPassword =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-primary",
                                                attrs: {
                                                  type: "button",
                                                  id: "button-addon2",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.copyDatafileReportPasswordClipboard()
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "bx bx-copy-alt",
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    this.enebleEditPassword
                                      ? _c(
                                          "div",
                                          { staticClass: "input-group" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.datafileReportPassword,
                                                  expression:
                                                    "datafileReportPassword",
                                                },
                                              ],
                                              staticClass:
                                                "form-control bg-transparent border-1",
                                              attrs: {
                                                type: "text",
                                                placeholder: this.$t(
                                                  "datafileResultGeneral.manageReportModal.form.password.placeholder"
                                                ),
                                              },
                                              domProps: {
                                                value:
                                                  _vm.datafileReportPassword,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.datafileReportPassword =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.manageReportModal.tabs.share"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "text-center" }, [
                          _c("div", { staticClass: "row mt-4 mb-4" }, [
                            _c("div", { staticClass: "col-md-3" }),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c(
                                "div",
                                {
                                  ref: "printcontent",
                                  staticClass: "p-2",
                                  attrs: { id: "qrCode" },
                                },
                                [
                                  _c("qrcode-vue", {
                                    attrs: {
                                      value: _vm.datafileReportURL,
                                      size: 150,
                                      level: "H",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "text-info",
                                  staticStyle: { cursor: "pointer" },
                                  on: { click: _vm.clickDownloadQRCode },
                                },
                                [_vm._v(" Click Here to Download QR ")]
                              ),
                            ]),
                            _c("div", { staticClass: "col-md-3" }),
                          ]),
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultGeneral.manageReportModal.form.link.label"
                                    )
                                  )
                              ),
                            ]),
                            _c("div", { staticClass: "input-group bg-light" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.datafileReportURL,
                                    expression: "datafileReportURL",
                                  },
                                ],
                                staticClass:
                                  "form-control bg-transparent border-1",
                                attrs: { type: "text", disabled: "" },
                                domProps: { value: _vm.datafileReportURL },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.datafileReportURL = $event.target.value
                                  },
                                },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: {
                                    type: "button",
                                    id: "button-addon2",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyDatafileReportURLClipboard()
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "bx bx-copy-alt" })]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row mt-5 text-center" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-2",
                    attrs: { type: "submit", variant: "success" },
                  },
                  [
                    _c("i", { staticClass: "bx bx-save mr-2" }),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileResultGeneral.manageReportModal.form.actions.saveButton"
                        )
                      )
                    ),
                  ]
                ),
                _c(
                  "b-button",
                  {
                    attrs: { type: "reset", variant: "light" },
                    on: {
                      click: function ($event) {
                        return _vm.clickCancel()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "bx bx-x mr-2" }),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileResultGeneral.manageReportModal.form.actions.cancelButton"
                        )
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.showCropperModal
        ? _c("CompanyAvatarCropperModal", {
            attrs: {
              visible: _vm.showCropperModal,
              imageFile: _vm.clientLogoImageFile,
            },
            on: {
              close: function ($event) {
                _vm.showCropperModal = false
              },
              apply: _vm.setCroppedImagePreview,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }