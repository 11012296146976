<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import DatafileService from "@/api/services/datafile.service.js";
import DatafileResultService from "@/api/services/datafileResult.service.js";
import RScoreWidgetInfo from "@/shared/components/model/r-score-widget-info.vue";
import { EventBus } from "@/shared/util/event-bus.js";
import {
checkDatafileSize,
initializeSocketBufferTimer
} from "@/shared/util/helpers.js";
// Const
import QualityScoresModal from "@/redem/datafiles/components/modals/quality-scores-modal.vue";
import AveragePredictionWidget from "@/redem/datafiles/components/widgets/CS/CS-average-prediction-widgets.vue";
import DataRecordTableWidget from "@/redem/datafiles/components/widgets/data-record-table-widget.vue";
import DistributionWidget from "@/redem/datafiles/components/widgets/distribution-widget.vue";
import ISDatapointWidget from "@/redem/datafiles/components/widgets/IS/IS-datapoints-widget.vue";
import PositiveNegativeWidget from "@/redem/datafiles/components/widgets/IS/is-positive-negative-widget-reactive.vue";
import ISQualityScoreWidget from "@/redem/datafiles/components/widgets/IS/IS-quality-widget.vue";
import MetaDataWidget from "@/redem/datafiles/components/widgets/meta-data-widget.vue";
import OESCategoryWidget from "@/redem/datafiles/components/widgets/OES/OES-category-widget.vue";
import PerformanceWidget from "@/redem/datafiles/components/widgets/performance-widget.vue";
import RScoreDistributionWidget from "@/redem/datafiles/components/widgets/RScore/R-score-distribution-widget.vue";
import RScoreTrafficLightWidget from "@/redem/datafiles/components/widgets/RScore/R-score-traffic-light-widget.vue";
import RScoreWidget from "@/redem/datafiles/components/widgets/RScore/R-score-widget.vue";
import ScoreWidget from "@/redem/datafiles/components/widgets/score-widget.vue";
import SDSQualityScoreWidget from "@/redem/datafiles/components/widgets/SDS/SDS-quality-widget.vue";
import SDSRateWidget from "@/redem/datafiles/components/widgets/SDS/SDS-rate-widget.vue";
import TrafficLightWidget from "@/redem/datafiles/components/widgets/traffic-light-widget.vue";
import TSSurveyDurationWidget from "@/redem/datafiles/components/widgets/TS/TS-survey-duration-widget.vue";

import {
MAX_REQUEST_TIME,
WEBSOCKET_UPDATE_INTERVAL
} from "@/shared/util/const.js";
import cookie from "vue-cookies";
/**
 * Raw Results
 */
export default {
  components: {
    RScoreWidgetInfo,
    QualityScoresModal,
    ISQualityScoreWidget,
    SDSQualityScoreWidget,
    RScoreWidget,
    RScoreDistributionWidget,
    RScoreTrafficLightWidget,
    MetaDataWidget,
    ScoreWidget,
    DistributionWidget,
    TrafficLightWidget,
    PerformanceWidget,
    DataRecordTableWidget,
    TSSurveyDurationWidget,
    AveragePredictionWidget,
    PositiveNegativeWidget,
    ISDatapointWidget,
    SDSRateWidget,
    OESCategoryWidget
  },
  data() {
    return {
      /*******  Data varibales ********/
      datafileId: this.$route.query.datafileId,
      dbDatafile: {},
      trafficLightData: [],
      scoreDistributionData: [],
      qualityScoreStats: {},
      rScorePieChartData: null,
      algorithms: null,
      numberOfQualityScores: 0,
      availableQualityScore: null,
      enableBenchmarks: false,
      /******** Operational Variables *********/
      showRScoreInfoModel: false,
      socket: null,
      websocketBuffer: 0,
      websocketUpdateInterval: WEBSOCKET_UPDATE_INTERVAL,
      maxRequestTime: MAX_REQUEST_TIME,
      isDisplayMainLoader: true,
      alreadyLoadedComponents: 0,
      numberOfComponentToBeLoaded: 4,
      isDisplayQualityScoreModal: false,
      isPerformanceWidgetAvailable: true
    };
  },
  async created() {
    this.datafileId = this.$route.query.datafileId;

    this.algorithms = await DatafileService.getAlgorithmsInDatafile(
      this.datafileId
    );

    for (const key in this.algorithms) {
      if (this.algorithms[key]) {
        this.numberOfQualityScores += 1;
        this.availableQualityScore = key;
      }
    }

    if (this.algorithms.IS) this.numberOfComponentToBeLoaded += 1;
    if (this.algorithms.SDS) this.numberOfComponentToBeLoaded += 1;

    this.dbDatafile = await DatafileService.getDatafile(this.datafileId);
    if (this.dbDatafile.isLiveCleaning === "RUNNING") {
      this.initializeWebSocketSubscription();
      initializeSocketBufferTimer(this);
    }
  },
  beforeDestroy() {
    this.destroySocket();
  },
  methods: {
    countTheComponents() {
      this.alreadyLoadedComponents += 1;

      if (this.alreadyLoadedComponents === this.numberOfComponentToBeLoaded)
        this.isDisplayMainLoader = false;
    },
    destroySocket() {
      if (this.socket) {
        this.socket.close(1000, String(this.datafileId) + ";CLEANED");
        this.socket = null;
      }
    },
    initializeWebSocketSubscription() {
      const socket = new WebSocket(process.env.VUE_APP_WEB_SOCKET_SERVER);
      this.socket = socket;
      const cleanedValues = false;
      const token = cookie.get("accessToken");
      socket.onopen = async (e) => {
        await checkDatafileSize(this, cleanedValues);
        socket.send(this.datafileId + ";RAW;" + token);
      };
      socket.onmessage = (event) => {
        // Check if records are there if they are 0
        if (this.dbDatafile.numberOfRecords === 0) {
          DatafileService.getDatafile(this.datafileId).then((datafile) => {
            this.dbDatafile = datafile;
          });
        }
        if (this.websocketBuffer < 1) {
          this.websocketBuffer++;
          // console.log(`[message][raw-results.vue] Data received from server: ${event.data}`);
          DatafileResultService.getTrafficLightData(
            this.datafileId,
            "R",
            null,
            cleanedValues
          )
            .then((data) => {
              this.trafficLightData = [
                data.Red,
                data.Yellow,
                data.LightGreen,
                data.Green,
                data.Grey
              ];
              this.errorTrafficLight = false;
              this.isDisplayTrafficLightData = true;
              EventBus.$emit("updateTrafficLightData", this.trafficLightData);
            })
            .catch((e) => {
              this.errorTrafficLight = true;
            });
          DatafileResultService.getDistributionData(
            this.datafileId,
            "R",
            null,
            cleanedValues
          )
            .then((data) => {
              this.scoreDistributionData = data;
              this.errorScoreDistribution = false;
              this.isDisplayScoreDistributionData = true;
              EventBus.$emit(
                "updateScoreDistributionData",
                this.scoreDistributionData
              );
            })
            .catch((e) => {
              this.errorScoreDistribution = true;
            });
          let t1 = new Date();
          let t2;
          DatafileResultService.getQualityScore(
            this.datafileId,
            cleanedValues
          )
            .then((data) => {
              if (data.requestQueue > 10) {
                const ADD_TO_BUFFER = 2;
                console.log(
                  `Request queue is long, pausing requests for: ${
                    (ADD_TO_BUFFER * this.websocketUpdateInterval) / 1000
                  } seconds.`
                );
                this.websocketBuffer += ADD_TO_BUFFER;
              }
              t2 = new Date();
              const dT = t2 - t1;
              if (dT > this.maxRequestTime && this.websocketBuffer < 2) {
                let ADD_TO_BUFFER = 2;
                if (dT > this.maxRequestTime * 10) ADD_TO_BUFFER = 6;
                console.log(
                  `[overview-results.vue]: Backend server is responding very slowly. Pausing requests for ${
                    ADD_TO_BUFFER * (this.websocketUpdateInterval / 1000)
                  } seconds.`
                );
                this.websocketUpdateInterval += 500;
                console.log(
                  `[overview-results.vue]: Increasing update interval to ${this.websocketUpdateInterval} ms`
                );
                this.websocketBuffer += ADD_TO_BUFFER;
              }
              console.log("[overview-results.vue]: deltaT: ", dT);
              this.qualityScoreStats = data;
              EventBus.$emit(
                "updateQualityScoreStatData",
                this.qualityScoreStats
              );
            })
            .catch((e) => {
              this.errorRScoreData = true;
            });
          DatafileService.getDatafileMetadata(
            this.datafileId,
            cleanedValues
          ).then(() => {
            EventBus.$emit("updateDatafileMetadata");
          });
          EventBus.$emit("updateQualityWidgets");
        }
      };
    },
    showRScoreInfo(show) {
      this.showRScoreInfoModel = show;
    },
    showQualityScoresModel() {
      this.isDisplayQualityScoreModal = true;
    },
    performanceWidgetAvaiability() {
      this.isPerformanceWidgetAvailable = false;
    },
    updateBenchmarkAvailablity(value) {
      this.enableBenchmarks = value;
    }
  }
};
</script>

<template>
  <div>
    <!-- Loading Components -->
    <div v-if="dbDatafile.numberOfRecords > 0 && this.isDisplayMainLoader">
      <div class="row">
        <div class="col-12 text-center p-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>

    <!-- Result Components -->
    <div
      v-if="dbDatafile.numberOfRecords > 0"
      class="row"
      v-show="!this.isDisplayMainLoader"
    >
      <div class="col-12">
        <!-- Metadata-->
        <div class="row">
          <div class="col-md-12">
            <MetaDataWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :onlyOneQulityScore="numberOfQualityScores === 1 ? true : false"
              :cleaned="false"
              @dataFetchingDone="countTheComponents"
              @toggleBenchMark="updateBenchmarkAvailablity"
            />
          </div>
        </div>

        <!-- R-Score widgets if more than one quality score  -->
        <div class="row" v-show="numberOfQualityScores > 1 || enableBenchmarks">
          <!-- R-Score Mean & Presentation Charts -->
          <div class="col-lg-4">
            <RScoreWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :cleaned="false"
              :toggleBenchmark="this.enableBenchmarks"
              @showQualityScores="this.showQualityScoresModel"
              @showRScoreInfo="showRScoreInfo(true)"
              @closeRScoreInfo="showRScoreInfo(false)"
              @dataFetchingDone="countTheComponents"
            />
          </div>
          <!-- R-Score Distribution Chart -->
          <div class="col-lg-4">
            <RScoreDistributionWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :cleaned="false"
              :toggleBenchmark="this.enableBenchmarks"
              @dataFetchingDone="countTheComponents"
            />
          </div>
          <!-- R-Score Traffic Light Chart -->
          <div class="col-lg-4">
            <RScoreTrafficLightWidget
              class="animate__animated animate__fadeInUp animate__slow"
              ref="rScoreTrafficLight"
              :datafileId="this.datafileId"
              :cleaned="false"
              :toggleBenchmark="this.enableBenchmarks"
              @dataFetchingDone="countTheComponents"
            />
          </div>
        </div>

        <!-- Other Quality Scores -->
        <div
          class="row"
          v-show="
            numberOfQualityScores > 1 && (algorithms.IS || algorithms.SDS)
          "
        >
          <div class="col-md-12 mt-3 mb-4"><hr /></div>
          <div class="col-12" v-show="algorithms.IS">
            <ISQualityScoreWidget
              :datafileId="this.datafileId"
              :cleaned="false"
              @dataFetchingDone="countTheComponents"
            />
          </div>
          <div class="col-12" v-show="algorithms.SDS">
            <SDSQualityScoreWidget
              :datafileId="this.datafileId"
              :cleaned="false"
              @dataFetchingDone="countTheComponents"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Quality Score Widgets -->
    <div
      class="row"
      v-if="
        dbDatafile.numberOfRecords > 0 &&
        availableQualityScore &&
        numberOfQualityScores === 1 &&
        !algorithms.IS &&
        !enableBenchmarks
      "
    >
      <!-- Score Widget -->
      <div class="col-lg-4">
        <ScoreWidget
          class="animate__animated animate__fadeInUp animate__slow"
          :datafileId="this.datafileId"
          :qualityScore="this.availableQualityScore"
          :dataPointIdentife="null"
          :isCleaned="false"
          :groupSelector="
            this.selectedGroupSelector === 'All'
              ? null
              : this.selectedGroupSelector
          "
          @dataFetchingDone="countTheComponents"
        />
      </div>
      <!-- Distribution Widget -->
      <div class="col-lg-4">
        <DistributionWidget
          class="animate__animated animate__fadeInUp animate__slow"
          :datafileId="this.datafileId"
          :qualityScore="this.availableQualityScore"
          :dataPointIdentife="null"
          :isCleaned="false"
          :groupSelector="
            this.selectedGroupSelector === 'All'
              ? null
              : this.selectedGroupSelector
          "
          @dataFetchingDone="countTheComponents"
        />
      </div>
      <!-- Traffic Light Widget -->
      <div class="col-lg-4">
        <TrafficLightWidget
          class="animate__animated animate__fadeInUp animate__slow"
          :datafileId="this.datafileId"
          :qualityScore="this.availableQualityScore"
          :dataPointIdentife="null"
          :isCleaned="false"
          :groupSelector="
            this.selectedGroupSelector === 'All'
              ? null
              : this.selectedGroupSelector
          "
          @dataFetchingDone="countTheComponents"
        />
      </div>
    </div>

    <!-- SDS Widgets -->
    <div
      class="row"
      v-if="
        dbDatafile.numberOfRecords > 0 &&
        !this.isDisplayMainLoading &&
        this.availableQualityScore === 'SDS'
      "
    >
      <div class="col-md-12">
        <SDSRateWidget
          class="animate__animated animate__fadeInUp animate__slow"
          :datafileId="this.datafileId"
          :dataPointIdentifier="null"
          :isCleaned="false"
          @dataFetchingDone="countTheComponents"
        />
      </div>
    </div>

    <!-- IS Widgets -->
    <div
      v-if="
        dbDatafile.numberOfRecords > 0 && this.availableQualityScore === 'IS'
      "
      class="row"
    >
      <!-- Positive Negetive Widget -->
      <div class="col-md-12">
        <PositiveNegativeWidget
          class="animate__animated animate__fadeInUp animate__slow"
          :datafileId="this.datafileId"
          :dataPointIdentifier="All"
          :isCleaned="false"
          @dataFetchingDone="countTheComponents"
        />
      </div>

      <!-- Datapoints Widget -->
      <div class="col-md-12">
        <ISDatapointWidget
          class="animate__animated animate__fadeInUp animate__slow"
          :datafileId="this.datafileId"
          :isCleaned="false"
          @dataFetchingDone="countTheComponents"
        />
      </div>
    </div>

    <!-- Performance Widget for All Quality Scores & Survey Duration Widget only for TS -->
    <div
      class="row"
      v-if="dbDatafile.numberOfRecords > 0"
      v-show="numberOfQualityScores === 1"
    >
      <!-- Performance widget -->
      <div
        :class="
          this.availableQualityScore === 'TS' ||
          this.availableQualityScore === 'OES'
            ? 'col-md-6'
            : 'col-md-12'
        "
      >
        <PerformanceWidget
          class="animate__animated animate__fadeInUp animate__slow"
          :datafileId="this.datafileId"
          :qualityScore="this.availableQualityScore"
          :dataPointIdentife="null"
          :isCleaned="false"
          :groupSelector="
            this.selectedGroupSelector === 'All'
              ? null
              : this.selectedGroupSelector
          "
          @dataFetchingDone="countTheComponents"
          @performanceWidgetAvaiable="performanceWidgetAvaiability"
        />
      </div>
      <!-- TS Survey Duration Widget -->
      <div class="col-md-6" v-if="this.availableQualityScore === 'TS'">
        <TSSurveyDurationWidget
          class="animate__animated animate__fadeInUp animate__slow"
          :datafileId="this.datafileId"
          :qualityScore="this.availableQualityScore"
          :dataPointIdentife="null"
          :isCleaned="false"
          :groupSelector="
            this.selectedGroupSelector === 'All'
              ? null
              : this.selectedGroupSelector
          "
          @dataFetchingDone="countTheComponents"
        />
      </div>

      <!-- OES Category widget Widget -->
      <div
        v-if="this.availableQualityScore === 'OES'"
        :class="!this.isPerformanceWidgetAvailable ? 'col-md-12' : 'col-md-6'"
      >
        <OESCategoryWidget
          class="animate__animated animate__fadeInUp animate__slow"
          :datafileId="this.datafileId"
          :isCleaned="false"
          :dataPointIdentife="null"
          @dataFetchingDone="countTheComponents"
        />
      </div>
    </div>

    <!-- CS Average Prediction Widget -->
    <div
      class="row"
      v-if="
        dbDatafile.numberOfRecords > 0 &&
        !this.isDisplayMainLoading &&
        this.availableQualityScore === 'CS'
      "
      v-show="numberOfQualityScores === 1"
    >
      <div class="col-md-12">
        <AveragePredictionWidget
          :datafileId="this.datafileId"
          :isCleaned="false"
        />
      </div>
    </div>

    <!-- Data Record table -->
    <div
      class="row"
      v-if="
        dbDatafile.numberOfRecords > 0 &&
        availableQualityScore &&
        numberOfQualityScores === 1 &&
        !algorithms.IS &&
        !algorithms.SDS
      "
    >
      <div class="col-md-12">
        <DataRecordTableWidget
          class="animate__animated animate__fadeInUp animate__slow"
          :datafileId="this.datafileId"
          :qualityScore="this.availableQualityScore"
          :dataPointIdentife="null"
          :isCleaned="false"
          :groupSelector="
            this.selectedGroupSelector === 'All'
              ? null
              : this.selectedGroupSelector
          "
          @dataFetchingDone="countTheComponents"
        />
      </div>
    </div>

    <!-- No Data Records  -->
    <div
      v-if="dbDatafile.numberOfRecords === 0"
      class="col-12 text-center mt-3"
      style="min-height: 200px"
    >
      <img
        src="@/assets/images/information.png"
        alt
        class="img-fluid mb-4 mt-3"
        style="width: 60px"
      />
      <h5>{{ $t("general.noDatarecords") }}</h5>
    </div>

    <!-- R-Score Info widget Model -->
    <RScoreWidgetInfo
      class="animate__animated animate__fadeInUp animate__slow"
      v-if="showRScoreInfoModel"
      :visible="showRScoreInfoModel"
      @close="showRScoreInfo(false)"
    />

    <!-- Quality Score Modal -->
    <QualityScoresModal
      v-if="isDisplayQualityScoreModal"
      :visible="this.isDisplayQualityScoreModal"
      :datafileId="this.datafileId"
      :isCleanedDatafile="false"
      @close="isDisplayQualityScoreModal = false"
    />
  </div>
</template>
