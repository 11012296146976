<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import DatafileService from "@/api/services/datafile.service.js";
import AveragePredictionWidget from "@/redem/datafiles/components/widgets/CS/CS-average-prediction-widgets.vue";
import MetadataWidget from "@/redem/datafiles/components/widgets/CS/CS-metadata-widget.vue";
import {
  checkDatafileSize,
  initializeSocketBufferTimer
} from "@/shared/util/helpers.js";

import DistributionWidget from "@/redem/datafiles/components/widgets/distribution-widget.vue";
import ScoreWidget from "@/redem/datafiles/components/widgets/score-widget.vue";
import TrafficLightWidget from "@/redem/datafiles/components/widgets/traffic-light-widget.vue";
import PerformanceWidget from "@/redem/datafiles/components/widgets/performance-widget.vue";
import DataRecordTableWidget from "@/redem/datafiles/components/widgets/data-record-table-widget.vue";

// Const
import {
  MAX_REQUEST_TIME,
  WEBSOCKET_UPDATE_INTERVAL
} from "@/shared/util/const.js";
import cookie from "vue-cookies";

export default {
  components: {
    MetadataWidget,
    AveragePredictionWidget,
    DistributionWidget,
    ScoreWidget,
    TrafficLightWidget,
    PerformanceWidget,
    DataRecordTableWidget
  },
  data() {
    return {
      /*******  Data varibales ********/
      datafileId: null,
      dbDatafile: {},
      /******** Operational Variables *********/
      socket: null,
      websocketBuffer: 0,
      websocketUpdateInterval: WEBSOCKET_UPDATE_INTERVAL,
      maxRequestTime: MAX_REQUEST_TIME,
      isDisplayMainLoader: true,
      alreadyLoadedComponents: 0,
      numberOfComponentToBeLoaded: 5
    };
  },
  async created() {
    this.datafileId = this.$route.query.datafileId;
    // If it's a live survey, subscribe to database updates here and trigger a re-render whenever a respondent gets added
    this.dbDatafile = await DatafileService.getDatafile(this.datafileId);
    if (this.dbDatafile.isLiveCleaning === "RUNNING") {
      this.initializeWebSocketSubscription();
      initializeSocketBufferTimer(this);
    }
  },
  beforeDestroy() {
    if (this.socket)
      this.socket.close(1000, String(this.datafileId) + ";CLEANED");
    this.socket = null;
  },
  methods: {
    initializeWebSocketSubscription() {
      const cleanedValues = true;
      const socket = new WebSocket(process.env.VUE_APP_WEB_SOCKET_SERVER);
      this.socket = socket;
      const token = cookie.get("accessToken");
      socket.onopen = async e => {
        await checkDatafileSize(this, cleanedValues);
        //console.log("[open][CS-results.vue] Connection to WebSocketServer on port 8002 established");
        socket.send(this.datafileId + ";CLEANED;" + token);
        //console.log(`[CS-results.vue]: websocket update interval: ${this.websocketUpdateInterval}`);
      };
      socket.onmessage = event => {
        // Check if records are there if they are 0
        if (this.dbDatafile.numberOfCleanedRecords === 0) {
          DatafileService.getDatafile(this.datafileId).then(datafile => {
            this.dbDatafile = datafile;
          });
        }
        if (this.websocketBuffer < 1) {
          this.websocketBuffer++;
          //console.log(`[message][CS-results.vue] Data received from server: ${event.data}`);
        }
      };
    },
    countTheComponents() {
      this.alreadyLoadedComponents += 1;

      if (this.alreadyLoadedComponents === this.numberOfComponentToBeLoaded)
        this.isDisplayMainLoader = false;
    }
  }
};
</script>

<template>
  <div>
    <!-- Results -->
    <div v-show="!this.isDisplayMainLoader">
      <!-- Metadata -->
      <MetadataWidget
        :datafileId="this.datafileId"
        class="animate__animated animate__fadeInUp animate__slow"
      />

      <!-- R-Score Widgets -->
      <div class="row">
        <div class="col-md-4">
          <ScoreWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'CS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="null"
            @dataFetchingDone="countTheComponents"
          />
        </div>
        <div class="col-md-4">
          <DistributionWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'CS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="null"
            @dataFetchingDone="countTheComponents"
          />
        </div>
        <div class="col-md-4">
          <TrafficLightWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'CS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="null"
            @dataFetchingDone="countTheComponents"
          />
        </div>
      </div>

      <!-- Performance Widget -->
      <div class="row">
        <div class="col-md-12">
          <PerformanceWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'CS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="null"
            @dataFetchingDone="countTheComponents"
          />
        </div>
      </div>

      <!-- Average Prediction Charts fro Certainty Scores -->
      <div class="row">
        <div class="col-md-12">
          <AveragePredictionWidget
            :datafileId="this.datafileId"
            :isCleaned="true"
          />
        </div>
      </div>

      <!-- Data Record Table Widget -->
      <div class="row">
        <div class="col-md-12">
          <DataRecordTableWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'CS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="null"
            @dataFetchingDone="countTheComponents"
          />
        </div>
      </div>
    </div>

    <!-- Loading Component -->
    <div v-if="this.isDisplayMainLoader">
      <div class="row">
        <div class="col-12 text-center p-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>
