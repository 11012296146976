<script>
import DatafileResultService from "@/api/services/datafileResult.service";
import {
  DistributionChart,
  MeanChart,
  TimeScorePerformanceChart,
  TrafficLightChart
} from "@/redem/datafiles/result-charts";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";
import { ALGORITHM_TYPES } from "@/shared/util/const";
import { EventBus } from "@/shared/util/event-bus.js";

/*****
 * Certainty Score Quality  Widget
 */
export default {
  props: {
    datafileId: String,
    datapointIdentifier: String,
    cleaned: Boolean
  },
  components: {
    DataFetchingErrorWidget
  },
  data() {
    return {
      /******* Data Variables *******/
      chartDataForMeanChart: 0,
      meanChartOptions: null,
      chartDataForTrafficLightChart: [],
      trafficLightChartOptions: null,
      chartDataForDistributionChart: null,
      distributionChartOptions: null,
      performanceChartOptions: null,
      chartDataForPerformanceChart: {
        name: "Average Certainty Score",
        data: []
      },
      /******* Operational Variables *******/
      isDisplayInfo: false,
      isDisplayData: false,
      isDisplayTrafficLightError: false,
      isDisplayDistributionError: false,
      isDisplayDataPointError: false
    };
  },
  async created() {
    this.initializeEventBus();
    // set mean chart, charts options
    this.meanChartOptions = new MeanChart();
    this.trafficLightChartOptions = new TrafficLightChart();
    this.distributionChartOptions = new DistributionChart();
    this.performanceChartOptions = new TimeScorePerformanceChart();
    await this.fetchAllData(true);
    this.isDisplayData = true;
  },
  methods: {
    async fetchAllData(initial = false) {
      const trafficLight = this.fetchTrafficLightData();
      const distribution = this.fetchDistributionData();
      const performance = this.fetchQualityScorePerformanceData(initial);

      try {
        await trafficLight;
      } catch (e) {
        this.isDisplayTrafficLightError = true;
        console.log("Error fetching CS traffic Light data");
      }
      try {
        await distribution;
      } catch (e) {
        this.isDisplayDistributionError = true;
        console.log("Error fetching CS distribution data");
      }
      try {
        await performance;
      } catch (e) {
        this.isDisplayDataPointError = true;
        console.log("Error fetching CS performance data");
      }
      this.$emit("fetchingFinished", ALGORITHM_TYPES.CS);
    },
    initializeEventBus() {
      // EventBus
      EventBus.$on("updateQualityWidgets", async () => {
        await this.fetchAllData(false);
      });
    },
    async fetchTrafficLightData() {
      let tempData = await DatafileResultService.getTrafficLightData(
        this.datafileId,
        ALGORITHM_TYPES.CS,
        this.datapointIdentifier,
        this.cleaned
      );
      tempData = tempData.trafficLightValues;
      const trafficLightData = [
        tempData.Red,
        tempData.Yellow,
        tempData.LightGreen,
        tempData.Green,
        tempData.Grey
      ];

      this.chartDataForTrafficLightChart = trafficLightData;
      this.chartDataForMeanChart = Math.round(tempData.Average);
    },
    async fetchDistributionData() {
      const tempDistributionData = await DatafileResultService.getDistributionData(
        this.datafileId,
        ALGORITHM_TYPES.CS,
        this.datapointIdentifier,
        this.cleaned
      );
      this.chartDataForDistributionChart = [
        {
          name: "Data-Records",
          data: tempDistributionData.scoreDistribution
        }
      ];
    },
    async fetchQualityScorePerformanceData(initial = false) {
      const tempProformanceionData = await DatafileResultService.getQualityScorePerformanceData(
        this.datafileId,
        null,
        this.cleaned,
        ALGORITHM_TYPES.CS
      );

      this.chartDataForPerformanceChart.data = tempProformanceionData;
      if (initial) {
        for (let i = 0; i < tempProformanceionData.length; i++) {
          this.performanceChartOptions.chartOptions.xaxis.categories.push(
            "Item " + (i + 1)
          );
        }
      }
    },
    changeToShowInfo() {
      this.isDisplayInfo = true;
    },
    changeToShowData() {
      this.isDisplayInfo = false;
    },
    clickViewMoreButton() {
      this.$emit("viewMore", ALGORITHM_TYPES.CS);
    }
  }
};
</script>
<template>
  <div
    class="card animate__animated animate__fadeInUp animate__slow"
    style="height: 900px"
    :style="[
      this.cleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
    ]"
  >
    <div class="" v-if="isDisplayData">
      <!-- Results -->
      <div class="card-body" v-if="!isDisplayInfo">
        <b-card-title class="pb-3">
          <div class="float-right" v-if="this.cleaned">
            <b-button
              class="btn-sm"
              variant="light"
              @click="clickViewMoreButton()"
              >{{
                $t("datafileResultGeneral.qualityScoreWidget.showMoreButton")
              }}
              <i class="bx bx-right-arrow-alt ml-1"></i
            ></b-button>
          </div>
          <h4 class="card-title mb-3">
            {{ $t("qualityScoreWidgets.certaintyScoreWidget.title") }}
            <i
              class="bx bxs-info-circle font-size-20 text-info bx-tada ml-1"
              style="cursor: pointer"
              @click="changeToShowInfo()"
            ></i>
          </h4>
        </b-card-title>
        <div class="row mb-2">
          <div class="col-md-12 text-center">
            <!-- Mean Chart -->
            <apexchart
              ref="CSMeanChart"
              class="apex-charts apexChartData"
              type="radialBar"
              height="250"
              dir="ltr"
              :series="[chartDataForMeanChart]"
              :options="meanChartOptions.chartOptions"
            ></apexchart>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1"></div>
          <div class="col-md-10 text-center">
            {{ $t("qualityScoreWidgets.certaintyScoreWidget.description") }}
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row pt-4">
          <div class="col-md-12">
            <b-tabs justified content-class="p-3 text-muted">
              <!-- Distribution Chart -->
              <b-tab active lazy>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="bx bx-line-chart"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{
                    $t(
                      "qualityScoreWidgets.certaintyScoreWidget.tabs.distribution"
                    )
                  }}</span>
                </template>
                <div class="mt-3">
                  <div v-if="!isDisplayDistributionError">
                    <apexchart
                      ref="CSDistributionChart"
                      class="apex-charts mt-5"
                      dir="ltr"
                      type="area"
                      height="300"
                      :series="chartDataForDistributionChart"
                      :options="distributionChartOptions.chartOptions"
                    ></apexchart>
                  </div>
                  <div v-else class="text-center">
                    <DataFetchingErrorWidget />
                  </div>
                </div>
              </b-tab>
              <!-- Traffic Light Chart -->
              <b-tab lazy>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="bx bxs-traffic"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{
                    $t(
                      "qualityScoreWidgets.certaintyScoreWidget.tabs.trafficLight"
                    )
                  }}</span>
                </template>
                <div class="mt-3">
                  <div v-if="!isDisplayTrafficLightError">
                    <apexchart
                      ref="CStrafficLightChart"
                      class="apex-charts mt-5 mb-4"
                      height="300"
                      type="pie"
                      dir="ltr"
                      :series="chartDataForTrafficLightChart"
                      :options="trafficLightChartOptions.chartOptions"
                    ></apexchart>
                  </div>
                  <div v-else class="text-center">
                    <DataFetchingErrorWidget />
                  </div>
                </div>
              </b-tab>
              <!-- Average Certainty Score Chart -->
              <b-tab
                v-if="this.chartDataForPerformanceChart.data.length > 1"
                lazy
              >
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="bx bx-trending-up"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{
                    $t(
                      "qualityScoreWidgets.certaintyScoreWidget.tabs.performance"
                    )
                  }}</span>
                </template>
                <div class="mt-3">
                  <div v-if="!isDisplayDataPointError">
                    <apexchart
                      ref="performanceChart"
                      class="apex-charts mt-5"
                      dir="ltr"
                      type="bar"
                      height="300"
                      :series="[chartDataForPerformanceChart]"
                      :options="performanceChartOptions.chartOptions"
                    ></apexchart>
                  </div>
                  <div v-else class="text-center">
                    <DataFetchingErrorWidget />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>

      <!-- Description -->
      <div class="card-body" v-else>
        <div class="row mb-3">
          <div class="col-md-10">
            <h4 class="card-title mb-3">
              {{ $t("qualityScoreWidgets.certaintyScoreWidget.title") }}
            </h4>
          </div>
          <div class="col-md-2 text-right">
            <i
              class="bx bxs-x-circle font-size-20 text-info bx-tada"
              style="cursor: pointer"
              @click="changeToShowData()"
            ></i>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.certaintyScoreWidget.detailedInfo.text1")
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.certaintyScoreWidget.detailedInfo.text2")
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.certaintyScoreWidget.detailedInfo.text3")
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.certaintyScoreWidget.detailedInfo.text4")
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t(
                "qualityScoreWidgets.certaintyScoreWidget.detailedInfo.useCase.title"
              )
            }}
            <ul>
              <li>
                {{
                  $t(
                    "qualityScoreWidgets.certaintyScoreWidget.detailedInfo.useCase.text1"
                  )
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div class="row justify-content-center" v-else>
      <div class="col-6 text-center pt-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>
