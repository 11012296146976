var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card" },
    [
      _vm.isDisplayData
        ? _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("div", { staticClass: "card-title" }, [
                _vm._v(" Grid-Question-Score "),
                _c("i", {
                  staticClass:
                    "bx bxs-info-circle font-size-18 text-info bx-tada ml-2",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.clickQualityScoreInfoModal()
                    },
                  },
                }),
              ]),
              this.noValidRecordError
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "b-alert",
                          { attrs: { show: "", variant: "info" } },
                          [
                            _c("i", {
                              staticClass: "mdi mdi-alert-circle-outline mr-2",
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafileResultIBSTab.metadataWidget.noValidRecordsError"
                                  )
                                )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              !this.isFetchingDataError
                ? _c(
                    "b-row",
                    [
                      _c("b-col", { staticClass: "mb-3", attrs: { sm: "4" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultIBSTab.metadataWidget.numberOfItemBatteries"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.metadata.numberOfItemBatteries)),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { staticClass: "mb-3", attrs: { sm: "4" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "uploadProcessStep3.summaryCard.columnDescription.IBS.patternCheckActivated"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.numberOfGQSDataPointsWithPatternCheck
                                ) +
                                "/" +
                                _vm._s(_vm.GQSDataPointsInfo.length) +
                                " "
                            ),
                          ]),
                          !_vm.liveDatafile
                            ? _c("i", {
                                staticClass:
                                  "bx bxs-info-circle text-info bx-tada",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickDetailsOfGQSPatternCheck()
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                      _c("b-col", { staticClass: "mb-3", attrs: { sm: "4" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultIBSTab.metadataWidget.numberOfItems"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.metadata.numberOfItems)),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { staticClass: "mb-3", attrs: { sm: "4" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultIBSTab.metadataWidget.numberOfDataRecords"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.metadata.numberOfRecords)),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { staticClass: "mb-3", attrs: { sm: "4" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultIBSTab.metadataWidget.numberOfInvalidDataRecords"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.metadata.numberOfInvalidRecords)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              this.isFetchingDataError
                ? _c(
                    "div",
                    { staticClass: "card-text mt-3" },
                    [_c("DataFetchingErrorWidget")],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-6 text-center" },
                [
                  _c("b-spinner", {
                    staticClass: "m-2",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { variant: "mid", role: "status" },
                  }),
                ],
                1
              ),
            ]),
          ]),
      _vm.isDisplayInfoModal
        ? _c("IBSInfoModal", {
            attrs: { visible: _vm.isDisplayInfoModal },
            on: {
              close: function ($event) {
                _vm.isDisplayInfoModal = false
              },
            },
          })
        : _vm._e(),
      _vm.isDisplayGQSDataPointsInforModal
        ? _c("GQSDataPointsInfoModal", {
            attrs: {
              visible: _vm.isDisplayGQSDataPointsInforModal,
              GQSDataPoints: _vm.GQSDataPointsInfo,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayGQSDataPointsInforModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }