<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import DatafileResultService from "@/api/services/datafileResult.service.js";
import { PerformanceChart } from "@/redem/datafiles/result-charts.js";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    datafileId: String,
    qualityScore: String,
    isCleaned: Boolean,
    groupSelector: String
  },
  components: {
    DataFetchingErrorWidget
  },
  data() {
    return {
      /***** Data varaiables *****/
      performanceChartData: null,
      performanceChartOptions: null,
      /***** Operational varaiables *****/
      isDisplayData: false,
      isFetchingDataError: false
    };
  },
  watch: {
    groupSelector: function(newValue, oldValue) {
      this.fetchChartData();
    }
  },
  async created() {
    await this.fetchChartData();

    this.performanceChartOptions = new PerformanceChart();

    for (let i = 0; i < this.performanceChartData.data.length; i++) {
      let qualityScoreMapping = this.qualityScore
      if(this.qualityScore === 'IBS') {
        qualityScoreMapping = 'GQS'
      }

      this.performanceChartOptions.chartOptions.xaxis.categories.push(
        qualityScoreMapping + " " + (i + 1)
      );
    }
    this.$emit("dataFetchingDone");
    this.initializeEventBus();
  },
  methods: {
    async fetchChartData(reload = true) {
      // enable Loading
      if (reload) this.isDisplayData = false;

      await DatafileResultService.getQualityScorePerformanceData(
        this.datafileId,
        this.groupSelector,
        this.isCleaned,
        String(this.qualityScore)
      )
        .then(data => {
          this.performanceChartData = {
            name: "Average " + this.qualityScore,
            data
          };
          this.isDisplayData = true;

          if (this.performanceChartData.data.length === 1) {
            this.$emit("performanceWidgetAvaiable");
          }
        })
        .catch(e => {
          this.isFetchingDataError = true;
          this.isDisplayData = true;
        });
    },
    initializeEventBus() {
      EventBus.$on("updateQualityWidgets", async () => {
        await this.fetchChartData(false);
      });
    },
    
  }
};
</script>

<template>
  <div>
    <!-- Results -->
    <div
      class="card"
      v-if="isDisplayData && performanceChartData.data.length > 1"
      :style="[
        this.isCleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
      ]"
    >
      <div class="card-body">
        <div class="card-title">
          {{
            $t("datafileResultGeneral.generalWidgets.performanceWidget.title")
          }}
        </div>
        <div class="card-text">
          {{
            $t(
              "datafileResultGeneral.generalWidgets.performanceWidget.description"
            )
          }}
        </div>

        <div class="card-text mt-3" v-if="!this.isFetchingDataError">
          <apexchart
            ref="performanceChart"
            class="apex-charts mt-5"
            dir="ltr"
            type="bar"
            height="300"
            :series="[performanceChartData]"
            :options="performanceChartOptions.chartOptions"
          ></apexchart>
        </div>
        <div class="card-text mt-3" v-if="this.isFetchingDataError">
          <DataFetchingErrorWidget />
        </div>
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div
      class="card"
      v-if="!isDisplayData"
      :style="[
        this.isCleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
      ]"
    >
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-6 text-center">
            <b-spinner
              style="width: 5rem; height: 5rem"
              class="m-2"
              variant="mid"
              role="status"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</template>
