<script>
import DatafileReportService from "@/api/services/datafileReport.service.js";
// import QrcodeVue from "qrcode.vue";

export default {
  props: {
    datafileId: String,
    datafileTitle: String,
    datafile: Object
  },
  components: {
    // QrcodeVue
  },
  data() {
    return {
      reportMetaData: null
    };
  },
  async mounted() {
    await this.getReportData();
    this.$emit("dataFetchingDone");
  },
  methods: {
    async getReportData() {
      this.reportMetaData = await DatafileReportService.getReportMetadata(
        this.datafileId
      );
    },
    clickViewDatafileReport() {
      let routeData = this.$router.resolve({
        name: "datafile-report",
        query: { datafileId: this.datafileId }
      });
      window.open(routeData.href, "_blank");
    },
    clickReportSettings() {
      this.$emit("displayManageReport");
    }
  }
};
</script>

<template>
  <div class="card" :style="{ height: '300px' }">
    <div class="card-body">
      <!-- Card Title -->
      <h5 class="card-title mb-3">
        {{ $t("datafileResultCleanedTab.reportWidget.title") }}
        <!-- Actions if certificate available -->
        <b-dropdown
          size="sm"
          variant="light"
          class="float-right"
          dropleft
        >
          <template v-slot:button-content>
            <i class="bx bx-dots-vertical-rounded"></i>
          </template>
          <b-dropdown-item @click="clickViewDatafileReport()" v-if="datafile.certificateLevel"
            ><i class="bx bxs-show mr-2"></i
            >{{
              $t("datafileResultCleanedTab.reportWidget.viewReportbutton")
            }}</b-dropdown-item
          >
          <b-dropdown-item :href="reportMetaData.certificateImageURL" download v-if="datafile.certificateLevel">
            <i class="bx bxs-download mr-2"></i>
            {{
              $t("datafileResultCleanedTab.reportWidget.downloadCertificate")
            }}
          </b-dropdown-item>
          <b-dropdown-item @click="clickReportSettings()">
            <i class="bx bxs-cog mr-2"></i>
            {{ $t("datafileResultCleanedTab.reportWidget.manageReport") }}
          </b-dropdown-item>
        </b-dropdown>
      </h5>

      <div>
        <span>
          {{ $t("datafileResultCleanedTab.reportWidget.certificateLevel") }}:
        </span>
        <span>
          <b-badge
            style="font-size: 12px"
            variant="gold"
            v-if="datafile.certificateLevel"
          >
            {{ datafile.certificateLevel }}
          </b-badge>
          <strong v-if="!datafile.certificateLevel">
            {{
              $t(
                "datafileResultCleanedTab.reportWidget.notEligibleForCertificate"
              )
            }}
          </strong>
        </span>
      </div>

      <!-- If there are no Certificate Level -->
      <div class="mt-5 text-center" v-if="!datafile.certificateLevel">
        {{ $t("datafileResultCleanedTab.reportWidget.noCertificateAvailable") }}
      </div>

      <!-- If there are Certificate Level show certificate image -->
      <div class="mt-1 text-center" v-if="datafile.certificateLevel">
        <br />
        <img
          src="@/assets/images/redem-certification.png"
          alt
          class="img-fluid"
          style="width: 150px"
        />
        <h5 class="mt-2">{{ reportMetaData.certificateCode }}</h5>
      </div>

      <!-- View Report Button if only certificate available -->
      <div class="row mt-3" v-if="!datafile.certificateLevel">
        <div class="col-md-12 text-center">
          <b-button size="sm" variant="light" @click="clickViewDatafileReport()"
            ><i class="bx bxs-show mr-2"></i
            >{{ $t("datafileResultCleanedTab.reportWidget.viewReportbutton") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
