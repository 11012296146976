<script>
import DatafileService from "@/api/services/datafile.service.js";
import CertinityScoreWidget from "@/redem/datafiles/components/widgets/CS/CS-quality-widget.vue";
import ItemBatteryScoreWidget from "@/redem/datafiles/components/widgets/IBS/IBS-quality-widget.vue";
import OpenEndedScoreWidget from "@/redem/datafiles/components/widgets/OES/OES-quality-widget.vue";
import PredictionScoreWidget from "@/redem/datafiles/components/widgets/PS/PS-quality-widget.vue";
import TimeScoreWidget from "@/redem/datafiles/components/widgets/TS/TS-quality-widget.vue";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    datafileId: String,
    isCleanedDatafile: Boolean
  },
  components: {
    TimeScoreWidget,
    ItemBatteryScoreWidget,
    OpenEndedScoreWidget,
    PredictionScoreWidget,
    CertinityScoreWidget
  },
  data() {
    return {
      /***** Operational Variable *****/
      algorithms: null,
      fullWidth: "col-lg-12",
      halfWidth: "col-lg-6",
      quaterWidth: "col-lg-4",
      isDisplayTS: false,
      isDisplayIBS: false,
      isDisplayOES: false,
      isDisplayPS: false,
      isDisplayCS: false,
      errorTS: false,
      errorIBS: false,
      errorOES: false,
      errorPS: false,
      errorCS: false
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  async created() {
    this.algorithms = await DatafileService.getAlgorithmsInDatafile(
      this.datafileId
    );
  },
  methods: {
    clickClose() {
      this.$emit("close");
    },
    finishLoadingTS() {
      this.isDisplayTS = true;
    },
    finishLoadingIBS() {
      this.isDisplayIBS = true;
    },
    finishLoadingOES() {
      this.isDisplayOES = true;
    },
    finishLoadingPS() {
      this.isDisplayPS = true;
    },
    finishLoadingCS() {
      this.isDisplayCS = true;
    },
    clickViewMoreButton(QualityScoreType) {
      this.$emit("clickViewMoreQualitySocre", QualityScoreType);
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    no-close-on-backdrop
    size="xl"
    scrollable
    :title="this.$t('datafileReport.qualityScoresModal.title')"
    @hide="clickClose()"
  >
    <!-- Quality Scores -->
    <div class="row" v-if="this.algorithms">
      <!-- Open Ended Score Score -->
      <div v-if="algorithms.OES" class="col-md-6">
        <OpenEndedScoreWidget
          :datafileId="datafileId"
          @finishLoading="finishLoadingOES"
          :cleaned="this.isCleanedDatafile"
          @viewMore="clickViewMoreButton"
        />
      </div>
      <!-- Time Score -->
      <div v-if="algorithms.TS" class="col-md-6">
        <TimeScoreWidget
          :datafileId="datafileId"
          @finishLoading="finishLoadingTS"
          :cleaned="this.isCleanedDatafile"
          @viewMore="clickViewMoreButton"
        />
      </div>
      <!-- Item Battery Score -->
      <div v-if="algorithms.IBS" class="col-md-6">
        <ItemBatteryScoreWidget
          :datafileId="datafileId"
          @finishLoading="finishLoadingIBS"
          :cleaned="this.isCleanedDatafile"
          @viewMore="clickViewMoreButton"
        />
      </div>
      <!-- Prediction Score -->
      <div v-if="algorithms.PS" class="col-md-6">
        <PredictionScoreWidget
          :datafileId="datafileId"
          @finishLoading="finishLoadingPS"
          :cleaned="this.isCleanedDatafile"
          @viewMore="clickViewMoreButton"
        />
      </div>
      <!-- Certinity Score -->
      <div v-if="algorithms.CS" class="col-md-6">
        <CertinityScoreWidget
          :datafileId="datafileId"
          @finishLoading="finishLoadingCS"
          :cleaned="this.isCleanedDatafile"
          @viewMore="clickViewMoreButton"
        />
      </div>
    </div>

    <!-- Loading -->
    <div class="min-height col-12 text-center p-5" v-if="!this.algorithms">
      <b-spinner
        style="width: 5rem; height: 5rem"
        class="m-2"
        variant="mid"
        role="status"
      ></b-spinner>
    </div>
  </b-modal>
</template>
<style scoped>
.min-height {
  min-height: 350px;
}
</style>
