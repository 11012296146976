var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card",
      staticStyle: { height: "480px" },
      style: [
        this.isCleaned ? { background: "#FFFFFF" } : { background: "#FFF5F4" },
      ],
    },
    [
      _vm.isDisplayData
        ? _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "card-title" }, [
              _vm.qualityScore === "OES"
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultOESTab.averageScoreWidget.title"
                          )
                        )
                    ),
                  ])
                : _vm._e(),
              _vm.qualityScore === "TS"
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("datafileResultTSTab.timeScoreWidget.title")
                        )
                    ),
                  ])
                : _vm._e(),
              _vm.qualityScore === "IBS"
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultIBSTab.itemBatteryScoreWidget.title"
                          )
                        )
                    ),
                  ])
                : _vm._e(),
              _vm.qualityScore === "PS"
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("datafileResultPSTab.averageScoreWidget.title")
                        )
                    ),
                  ])
                : _vm._e(),
              _vm.qualityScore === "CS"
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("datafileResultCSTab.averageScoreWidget.title")
                        )
                    ),
                  ])
                : _vm._e(),
              _vm.qualityScore === "SDS"
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultSDSTab.averageScoreWidget.title"
                          )
                        )
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "card-text" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "datafileResultGeneral.generalWidgets.scoreWidget.description"
                    )
                  ) +
                  " "
              ),
            ]),
            !this.isFetchingDataError && this.scoreChartData !== -999
              ? _c(
                  "div",
                  { staticClass: "card-text mt-3" },
                  [
                    _c("apexchart", {
                      ref: "meanChart",
                      staticClass: "apex-charts apexChartData",
                      attrs: {
                        type: "radialBar",
                        height: "300",
                        dir: "ltr",
                        series: [Math.round(this.scoreChartData)],
                        options: _vm.scoreChartOptions.chartOptions,
                      },
                    }),
                    _c("h6", { staticClass: "font-weight-bold text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultGeneral.rScoreWidget.tab.meanLabel"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            this.isFetchingDataError
              ? _c(
                  "div",
                  { staticClass: "card-text mt-3" },
                  [_c("DataFetchingErrorWidget")],
                  1
                )
              : _vm._e(),
            this.scoreChartData === -999
              ? _c(
                  "div",
                  { staticClass: "card-text mt-3" },
                  [_c("NoValidScoresAvailableWidget")],
                  1
                )
              : _vm._e(),
          ])
        : _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-6 text-center pt-5" },
                [
                  _c("b-spinner", {
                    staticClass: "m-2",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { variant: "mid", role: "status" },
                  }),
                ],
                1
              ),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }