<script>
export default {
  props: {
    width: String,
    height: String
  },
  data() {
    return {
      isDisplayError: true
    };
  },
  computed: {
    style() {
      return `${this.height}`;
    }
  }
};
</script>
<template>
  <!-- Loading untile data get set --->
  <div :class="this.width">
    <div class="card" :style="this.style">
      <div class="text-center">
        <div class="row justify-content-center">
          <div class="col-11">
            <b-alert v-model="isDisplayError" class="mb-4" variant="danger">{{
              $t("general.errors.dataFetchingError")
            }}</b-alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.text-center {
  margin-top: 3rem;
  text-align: center;
}
</style>
