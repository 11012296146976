var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        size: "xl",
        scrollable: "",
        title: this.$t("datafileReport.qualityScoresModal.title"),
      },
      on: {
        hide: function ($event) {
          return _vm.clickClose()
        },
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      this.algorithms
        ? _c("div", { staticClass: "row" }, [
            _vm.algorithms.OES
              ? _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("OpenEndedScoreWidget", {
                      attrs: {
                        datafileId: _vm.datafileId,
                        cleaned: this.isCleanedDatafile,
                      },
                      on: {
                        finishLoading: _vm.finishLoadingOES,
                        viewMore: _vm.clickViewMoreButton,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.algorithms.TS
              ? _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("TimeScoreWidget", {
                      attrs: {
                        datafileId: _vm.datafileId,
                        cleaned: this.isCleanedDatafile,
                      },
                      on: {
                        finishLoading: _vm.finishLoadingTS,
                        viewMore: _vm.clickViewMoreButton,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.algorithms.IBS
              ? _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("ItemBatteryScoreWidget", {
                      attrs: {
                        datafileId: _vm.datafileId,
                        cleaned: this.isCleanedDatafile,
                      },
                      on: {
                        finishLoading: _vm.finishLoadingIBS,
                        viewMore: _vm.clickViewMoreButton,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.algorithms.PS
              ? _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("PredictionScoreWidget", {
                      attrs: {
                        datafileId: _vm.datafileId,
                        cleaned: this.isCleanedDatafile,
                      },
                      on: {
                        finishLoading: _vm.finishLoadingPS,
                        viewMore: _vm.clickViewMoreButton,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.algorithms.CS
              ? _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("CertinityScoreWidget", {
                      attrs: {
                        datafileId: _vm.datafileId,
                        cleaned: this.isCleanedDatafile,
                      },
                      on: {
                        finishLoading: _vm.finishLoadingCS,
                        viewMore: _vm.clickViewMoreButton,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      !this.algorithms
        ? _c(
            "div",
            { staticClass: "min-height col-12 text-center p-5" },
            [
              _c("b-spinner", {
                staticClass: "m-2",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { variant: "mid", role: "status" },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }