<script>
import DatafileService from "@/api/services/datafile.service.js";
import { dataRecordSummaryChart } from "@/redem/datafiles/result-charts.js";
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    datafileId: String,
    cleaned: Boolean,
    onlyOneQulityScore: Boolean,
    availableQualityScore: String,
    isCleaningApply: Boolean
  },
  components: {},
  data() {
    return {
      data: null,
      dataRecordSummaryChartData: [60, 10],
      dataRecordSummaryChartOptions: null,
      /***** Operational Data *****/
      isDisplayCleanDataModal: false,
      isDisplayData: false,
      isFetchingDataError: false,
    };
  },
  async created() {
    await this.fetchData();
    this.dataRecordSummaryChartOptions = new dataRecordSummaryChart()

    this.$emit("dataFetchingDone");
    this.initializeEventBus();
  },
  methods: {
    async fetchData(reload = true) {
      // enable Loading
      if (reload) this.isDisplayData = false;

      try {
        const data = await DatafileService.getDataRecordSummaryForDatafile(this.datafileId);
        this.data = data

        this.dataRecordSummaryChartData = []
        let excludedPercentage = (data.numberOfExcludedDataRecords/data.totalNumberOfDataRecords)*100
        let includedPercentage = 100 - excludedPercentage

        this.dataRecordSummaryChartData.push(includedPercentage, excludedPercentage)
        this.isDisplayData = true
      } catch (e) {
        this.isFetchingDataError = true;
        this.isDisplayData = true
        console.log("Error fetching data");
      }
    },
    clickCleaningSettings() {
      EventBus.$emit("showCleaningModal");
    },
    initializeEventBus() {
      // EventBus
      EventBus.$on("updateQualityWidgets", async () => {
        await this.fetchData(false);
      });
    },
  }
};
</script>

<template>
  <div
    class="card"
    style="height: 300px"
  >
    <div class="card-body" v-if="isDisplayData">
      <!-- Card Title -->
      <h5 class="card-title mb-3">
        {{$t("datafileResultCleanedTab.cleanignSummaryWidget.title")}}
        
        <b-button
          class="float-right"
          size="sm"
          variant="light"
          @click="clickCleaningSettings()"
        >
          <i class="bx bx-brush mr-2"></i>
          {{$t("datafileResultCleanedTab.cleanignSummaryWidget.cleanignSettingsButton")}}</b-button
        >
      </h5>
      
      <!-- Numbers  -->
      <div class="card-text mt-4">
        <b-row>
          <!-- Total Data Records -->
          <b-col sm="4" class="mb-2">
            <div>
              {{$t("datafileResultCleanedTab.cleanignSummaryWidget.totalRecords")}}
            </div>
            <div>
              <strong>{{ data.numberOfIncludedDataRecords }}</strong>
            </div>
          </b-col>

          <!-- Excluded Data Records -->
          <b-col sm="4" class="mb-2">
            <div>
              {{$t("datafileResultCleanedTab.cleanignSummaryWidget.excludedRecords")}}
            </div>
            <div>
              <strong>{{ data.numberOfExcludedDataRecords }}</strong>
            </div>
          </b-col>

          <!-- Data Records without Scores -->
          <b-col sm="4" class="mb-2" v-if="onlyOneQulityScore">
            <div>
              {{$t("datafileResultCleanedTab.cleanignSummaryWidget.recordsWithOutScores")}}
            </div>
            <div>
              <strong>{{ data.numberOfDataRecordsWithInvalidScore }}</strong>
            </div>
          </b-col>
        </b-row>


      </div>

      <!-- Chart -->
      <div class="row" v-if="isCleaningApply">
        <div class="col-md-12">
          <apexchart type="donut" :options="dataRecordSummaryChartOptions.chartOptions" :series="dataRecordSummaryChartData" height="290"/>
        </div>
      </div>

      <!-- Information nO cleaning apply -->
      <div class="row" v-if="!isCleaningApply">
        <div class="col-md-12">
          <b-alert
            show
            variant="info"
            class="mt-2 text-center"
            v-if="!isCleaningApply"
          >
            {{
              $t(
                "datafileResultCleanedTab.cleanignSummaryWidget.cleaningNotAppliedYet"
              )
            }}
          </b-alert>
        </div>
      </div>

    </div>

    <!-- Loading untile data get set --->
    <div class="card-body" v-else>
      <div class="row justify-content-center">
        <div class="col-6 text-center pt-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
</style>
