<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import DatafileResultService from "@/api/services/datafileResult.service.js";
import { DistributionHistogramChart } from "@/redem/datafiles/result-charts.js";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    datafileId: String,
    qualityScore: String,
    dataPointIdentifer: String,
    isCleaned: Boolean,
    groupSelector: String
  },
  components: {
    DataFetchingErrorWidget
  },
  data() {
    return {
      /***** Data varaiables *****/
      scoreDistributionChartData: null,
      distributionHistogramChartOptions: null,
      /***** Operational varaiables *****/
      isDisplayData: false,
      isFetchingDataError: false
    };
  },
  watch: {
    groupSelector: function(newValue, oldValue) {
      this.fetchChartData();
    }
  },
  async created() {
    await this.fetchChartData();
    this.distributionHistogramChartOptions = new DistributionHistogramChart();
    this.$emit("dataFetchingDone");
    this.initializeEventBus();
  },
  methods: {
    async fetchChartData(reload = true) {
      // enable Loading
      if (reload) this.isDisplayData = false;

      await DatafileResultService.getDistributionData(
        this.datafileId,
        this.qualityScore,
        this.dataPointIdentifer,
        this.isCleaned,
        this.groupSelector
      )
        .then(data => {
          this.scoreDistributionChartData = [{ name: "Data-Records", data: data.scoreDistribution}];
          this.isDisplayData = true;
        })
        .catch(e => {
          this.isFetchingDataError = true;
          this.isDisplayData = true;
        });
    },
    initializeEventBus() {
      // EventBus
      EventBus.$on("updateQualityWidgets", async () => {
        await this.fetchChartData(false);
      });
    },
  }
};
</script>

<template>
  <div
    class="card"
    style="height: 480px"
    :style="[
      this.isCleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
    ]"
  >
    <div class="card-body" v-if="isDisplayData">
      <div class="card-title">
        {{
          $t("datafileResultGeneral.generalWidgets.distributionWidget.title")
        }}
      </div>
      <div class="card-text">
        {{
          $t(
            "datafileResultGeneral.generalWidgets.distributionWidget.description"
          )
        }}
      </div>
      <div class="card-text mt-3" v-if="!this.isFetchingDataError">
        <apexchart
          ref="distributionChart"
          class="apex-charts"
          dir="ltr"
          type="bar"
          height="300"
          :series="scoreDistributionChartData"
          :options="distributionHistogramChartOptions.chartOptions"
        ></apexchart>
      </div>
      <div class="card-text mt-3" v-if="this.isFetchingDataError">
        <DataFetchingErrorWidget />
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div class="card-body" v-else>
      <div class="row justify-content-center">
        <div class="col-6 text-center pt-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>
