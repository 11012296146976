var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card",
      staticStyle: { height: "450px" },
      style: [
        this.cleaned ? { background: "#FFFFFF" } : { background: "#FFF5F4" },
      ],
    },
    [
      _vm.isDisplayData
        ? _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "card-title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("datafileResultGeneral.trafficLightWidget.title")
                  ) +
                  " "
              ),
              _vm.displayBenchmarkChart
                ? _c("i", {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover",
                        value: this.propoverMethodInfo,
                        expression: "this.propoverMethodInfo",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "ml-2 bx bxs-info-circle text-info",
                    staticStyle: { cursor: "pointer" },
                  })
                : _vm._e(),
            ]),
            _c("div", { staticClass: "card-text mt-4" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "datafileResultGeneral.trafficLightWidget.description"
                    )
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "card-text mt-4" }, [
              _c("div", { staticClass: "row" }, [
                (!_vm.isDisplayRScoreTrafficLightError &&
                  this.cleaned &&
                  !_vm.displayBenchmarkChart) ||
                (!_vm.isDisplayRScoreTrafficLightError &&
                  !this.cleaned &&
                  !_vm.displayBenchmarkChart)
                  ? _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("apexchart", {
                          ref: "trafficLightChart",
                          staticClass: "apex-charts",
                          attrs: {
                            height: "300",
                            type: "donut",
                            dir: "ltr",
                            series: _vm.rScoreTrafficLightChartData,
                            options:
                              _vm.rScoreTrafficLighChartOptions.chartOptions,
                          },
                        }),
                      ],
                      1
                    )
                  : !_vm.isDisplayRScoreTrafficLightError &&
                    !this.cleaned &&
                    _vm.displayBenchmarkChart
                  ? _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("h6", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultRawTab.benchmarkWidget.yourData"
                                )
                              ) +
                              ": "
                          ),
                        ]),
                        _c(
                          "b-progress",
                          {
                            staticClass: "mt-2",
                            staticStyle: { "font-size": "15px" },
                            attrs: {
                              max: 100,
                              "show-value": "",
                              height: "30px",
                            },
                          },
                          [
                            _c("b-progress-bar", {
                              attrs: {
                                value: _vm.rScoreTrafficLightChartData[0],
                                variant: "danger",
                              },
                            }),
                            _c("b-progress-bar", {
                              attrs: {
                                value: _vm.rScoreTrafficLightChartData[1],
                                variant: "warning",
                              },
                            }),
                            _c("b-progress-bar", {
                              attrs: {
                                value: _vm.rScoreTrafficLightChartData[2],
                                variant: "average",
                              },
                            }),
                            _c("b-progress-bar", {
                              attrs: {
                                value: _vm.rScoreTrafficLightChartData[3],
                                variant: "success",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("h6", { staticClass: "mt-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultRawTab.benchmarkWidget.benchmark"
                                )
                              ) +
                              ": "
                          ),
                        ]),
                        _c(
                          "b-progress",
                          {
                            staticClass: "mt-2",
                            staticStyle: { "font-size": "15px" },
                            attrs: {
                              max: 100,
                              "show-value": "",
                              height: "30px",
                            },
                          },
                          [
                            _c("b-progress-bar", {
                              attrs: {
                                value: _vm.benchmarkTrafficLightChartData[0],
                                variant: "danger",
                              },
                            }),
                            _c("b-progress-bar", {
                              attrs: {
                                value: _vm.benchmarkTrafficLightChartData[1],
                                variant: "warning",
                              },
                            }),
                            _c("b-progress-bar", {
                              attrs: {
                                value: _vm.benchmarkTrafficLightChartData[2],
                                variant: "average",
                              },
                            }),
                            _c("b-progress-bar", {
                              attrs: {
                                value: _vm.benchmarkTrafficLightChartData[3],
                                variant: "success",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "row mt-5" }, [
                          _c("div", { staticClass: "col-3 text-center" }, [
                            _c("i", {
                              staticClass: "bx bxs-circle text-danger",
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafileResultRawTab.benchmarkWidget.range1"
                                  )
                                )
                            ),
                          ]),
                          _c("div", { staticClass: "col-3 text-center" }, [
                            _c("i", {
                              staticClass: "bx bxs-circle text-warning",
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafileResultRawTab.benchmarkWidget.range2"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "col-3 text-center" }, [
                            _c("i", {
                              staticClass: "bx bxs-circle text-average",
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafileResultRawTab.benchmarkWidget.range3"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "col-3 text-center" }, [
                            _c("i", {
                              staticClass: "bx bxs-circle text-success",
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafileResultRawTab.benchmarkWidget.range4"
                                  )
                                )
                            ),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm.benchmarkDataNotAvailable
                  ? _c(
                      "div",
                      { staticClass: "text-center col-12 mt-2" },
                      [
                        _c(
                          "b-alert",
                          { attrs: { show: "", variant: "info" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafileResultGeneral.benchmarkNotAvailable"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c("div", [_c("DataFetchingErrorWidget")], 1),
              ]),
            ]),
          ])
        : _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center pt-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }