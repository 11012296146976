var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "b-alert",
          {
            staticClass: "mb-4",
            attrs: { variant: "info" },
            model: {
              value: _vm.isDisplayError,
              callback: function ($$v) {
                _vm.isDisplayError = $$v
              },
              expression: "isDisplayError",
            },
          },
          [_vm._v(_vm._s(_vm.$t("general.errors.versionCompatibleError")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }