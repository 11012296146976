var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card", style: { height: "300px" } }, [
    _c("div", { staticClass: "card-body" }, [
      _c("h5", { staticClass: "card-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("datafileResultCleanedTab.quotaSettingsWidget.title")
            ) +
            " "
        ),
        _c("i", {
          staticClass: "bx bxs-info-circle font-size-16 text-info bx-tada ml-2",
          staticStyle: { cursor: "pointer" },
          on: {
            click: function ($event) {
              return _vm.clickInfQuotaWidget()
            },
          },
        }),
        !this.quotaSettingsNotSaved && !this.isLoading
          ? _c(
              "div",
              { staticClass: "float-right" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { size: "sm", variant: "light" },
                    on: {
                      click: function ($event) {
                        return _vm.clickQuotaSettingsModal()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "bx bxs-pie-chart-alt-2 mr-2" }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultCleanedTab.quotaSettingsWidget.button"
                          )
                        )
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      this.isLoading
        ? _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center pt-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !this.isLoading
        ? _c("div", [
            this.quotaSettingsNotSaved
              ? _c("div", { staticClass: "card-text text-center mt-5" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "datafileResultCleanedTab.quotaSettingsWidget.noSettings.text"
                        )
                      ) +
                      " "
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.clickQuotaSettingsModal()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "bx bxs-pie-chart-alt-2 mr-2",
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultCleanedTab.quotaSettingsWidget.noSettings.button"
                                )
                              )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            !this.quotaSettingsNotSaved && this.targetQuotaSettingsPercentage
              ? _c("div", { staticClass: "card-text mt-5" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "datafileResultCleanedTab.quotaSettingsWidget.description"
                        )
                      ) +
                      " "
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-progress",
                        {
                          staticClass: "mt-4",
                          staticStyle: { "font-size": "15px" },
                          attrs: { max: 100, height: "30px" },
                        },
                        [
                          _c("b-progress-bar", {
                            attrs: {
                              value:
                                (this.targetQuotaSettingsPercentage[0] /
                                  this.targetQuotaSettingsPercentage[1]) *
                                100,
                              label: `${this.targetQuotaSettingsPercentage[0]} / ${this.targetQuotaSettingsPercentage[1]}`,
                              variant: "success",
                              "show-value": "",
                            },
                          }),
                        ],
                        1
                      ),
                      this.targetQuotaSettingsPercentage === 0
                        ? _c("i", { staticClass: "mt-3" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafileResultCleanedTab.quotaSettingsWidget.tragetNotSet"
                                  )
                                )
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }