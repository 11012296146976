<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import DatafileService from "@/api/services/datafile.service.js";
import DataRecordsSummaryWidget from "@/redem/datafiles/components/widgets/data-records-summary-widget.vue";
import DatafileResultService from "@/api/services/datafileResult.service.js";
import QuotaSettingsInfoModal from "@/redem/datafiles/components/modals/quota-settings-info-modal.vue";
import AveragePredictionWidget from "@/redem/datafiles/components/widgets/CS/CS-average-prediction-widgets.vue";
import DataRecordTableWidget from "@/redem/datafiles/components/widgets/data-record-table-widget.vue";
import DistributionWidget from "@/redem/datafiles/components/widgets/distribution-widget.vue";
import ISDatapointWidget from "@/redem/datafiles/components/widgets/IS/IS-datapoints-widget.vue";
import PositiveNegativeWidget from "@/redem/datafiles/components/widgets/IS/is-positive-negative-widget-reactive.vue";
import MetaDataWidget from "@/redem/datafiles/components/widgets/meta-data-widget.vue";
import OESCategoryWidget from "@/redem/datafiles/components/widgets/OES/OES-category-widget.vue";
import PerformanceWidget from "@/redem/datafiles/components/widgets/performance-widget.vue";
import QuotaSettingsWidget from "@/redem/datafiles/components/widgets/quota-settings-widget.vue";
import ReportWidget from "@/redem/datafiles/components/widgets/report-widget.vue";
import ScoreWidget from "@/redem/datafiles/components/widgets/score-widget.vue";
import SDSRateWidget from "@/redem/datafiles/components/widgets/SDS/SDS-rate-widget.vue";
import TrafficLightWidget from "@/redem/datafiles/components/widgets/traffic-light-widget.vue";
import TSSurveyDurationWidget from "@/redem/datafiles/components/widgets/TS/TS-survey-duration-widget.vue";
import { EventBus } from "@/shared/util/event-bus.js";
import {
checkDatafileSize,
initializeSocketBufferTimer
} from "@/shared/util/helpers.js";
import cookie from "vue-cookies";
// Const
import {
MAX_REQUEST_TIME,
WEBSOCKET_UPDATE_INTERVAL
} from "@/shared/util/const.js";
import Multiselect from "vue-multiselect";

export default {
  components: {
    MetaDataWidget,
    QuotaSettingsWidget,
    QuotaSettingsInfoModal,
    ScoreWidget,
    DistributionWidget,
    TrafficLightWidget,
    PerformanceWidget,
    DataRecordTableWidget,
    TSSurveyDurationWidget,
    AveragePredictionWidget,
    PositiveNegativeWidget,
    ISDatapointWidget,
    SDSRateWidget,
    Multiselect,
    OESCategoryWidget,
    ReportWidget,
    DataRecordsSummaryWidget
  },
  data() {
    return {
      /*******  Data varibales ********/
      datafileId: null,
      dbDatafile: null,
      algorithms: null,
      availableQualityScore: null,
      selectedGroupSelector: "All",
      groupSelectors: null,
      /******** Operational Variables *********/
      customWidgetWidth: "col-md-4 col-lg-4",
      socket: null,
      websocketBuffer: 0,
      websocketUpdateInterval: WEBSOCKET_UPDATE_INTERVAL,
      maxRequestTime: MAX_REQUEST_TIME,
      isDisplayMainLoading: true,
      showQuotaSettingsInfoModel: false,
      alreadyLoadedComponents: 0,
      numberOfComponentToBeLoaded: 7,
      isPerformanceWidgetAvailable: true,
      isDisplayQuotaSettingsWidget: true
    };
  },
  async created() {
    this.datafileId = this.$route.query.datafileId;
    this.dbDatafile = await DatafileService.getDatafile(this.datafileId);

    if (this.dbDatafile && this.dbDatafile.importType !== "XLXS") {
      this.numberOfComponentToBeLoaded -= 1;
      this.isDisplayQuotaSettingsWidget = false;
      this.customWidgetWidth = "col-md-6 col-lg-6";
    }

    this.algorithms = await DatafileService.getAlgorithmsInDatafile(
      this.datafileId
    );

    for (const key in this.algorithms) {
      if (this.algorithms[key]) this.availableQualityScore = key;
    }

    if (this.availableQualityScore === "TS") {
      this.groupSelectors = await DatafileResultService.getGroupSelectors(
        this.datafileId
      );
      this.groupSelectors.splice(0, 0, "All");
    }
    if (this.dbDatafile.isLiveCleaning === "RUNNING") {
      this.initializeWebSocketSubscription();
      initializeSocketBufferTimer(this);
    }
  },
  methods: {
    countTheComponents() {
      this.alreadyLoadedComponents += 1;

      if (this.alreadyLoadedComponents === this.numberOfComponentToBeLoaded)
        this.isDisplayMainLoading = false;
    },
    showQuotaSettingsInfo(show) {
      this.showQuotaSettingsInfoModel = show;
    },
    performanceWidgetAvaiability() {
      this.isPerformanceWidgetAvailable = false;
    },
    showManageReprot(show) {
      this.showManageReprotModal = show;
    },
    initializeWebSocketSubscription() {
      const cleanedValues = true;
      const socket = new WebSocket(process.env.VUE_APP_WEB_SOCKET_SERVER);
      this.socket = socket;
      const token = cookie.get("accessToken");
      socket.onopen = async (e) => {
        await checkDatafileSize(this, cleanedValues);
        socket.send(this.datafileId + ";CLEANED;" + token);
      };
      socket.onmessage = (event) => {
        // Check if records are there if they are 0
        if (this.dbDatafile.numberOfCleanedRecords === 0) {
          DatafileService.getDatafile(this.datafileId).then((datafile) => {
            this.dbDatafile = datafile;
          });
        }
        if (this.websocketBuffer < 1) {
          this.websocketBuffer++;
          EventBus.$emit("updateQualityWidgets");
          EventBus.$emit("updateDatafileMetadata");
        }
      };
    },
    quotaSettingWidgetAvaiability() {
      this.isDisplayQuotaSettingsWidget = false
      this.customWidgetWidth = "col-md-6 col-lg-6";
    }
  }
};
</script>

<template>
  <div>
    <!-- Loading Components -->
    <div v-if="this.isDisplayMainLoading">
      <div class="row">
        <div class="col-12 text-center p-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>
    
    <!-- Result Components -->
    <div v-if="availableQualityScore">
      <div v-show="!this.isDisplayMainLoading">
        <!-- Group Selector -->
        <div
          class="row mb-3"
          v-if="this.groupSelectors && this.groupSelectors.length > 1"
        >
          <div class="col-md-4">
            <label>
              {{ $t("datafileResultTSTab.groupSelectorDropdown.label") }}
            </label>
            <Multiselect
              v-model="selectedGroupSelector"
              :options="groupSelectors"
              :placeholder="
                $t('uploadProcessStep2.form.general.selectColumn.placeholder')
              "
            ></Multiselect>
          </div>
        </div>

        <!-- Metadata Widget -->
        <div class="row">
          <div class="col-md-12">
            <MetaDataWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :cleaned="true"
              :onlyOneQulityScore="true"
              :availableQualityScore="availableQualityScore"
              @dataFetchingDone="countTheComponents"
            />
          </div>
        </div>

        <!-- Header Row -->
        <div class="row">
          <div :class="customWidgetWidth">
            <DataRecordsSummaryWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :cleaned="true"
              :isCleaningApply="dbDatafile.isCleaned"
              @dataFetchingDone="countTheComponents"
              :onlyOneQulityScore="true"
            />
          </div>

          <!-- Report Widget -->
          <div :class="customWidgetWidth">
            <ReportWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :datafileTitle="this.dbDatafile.title"
              :datafile="this.dbDatafile"
              @displayManageReport="showManageReprot(true)"
              @dataFetchingDone="countTheComponents"
            />
          </div>

          <!-- Quota Setting Widget -->
          <div class="col-md-4" v-if="this.dbDatafile.importType === 'XLXS' && this.isDisplayQuotaSettingsWidget">
            <QuotaSettingsWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              @showQuotaInfo="showQuotaSettingsInfo(true)"
              @dataFetchingDone="countTheComponents"
              @quotaSettingStatus="quotaSettingWidgetAvaiability"
            />
          </div>
        </div>

        <!-- Quality Score Widgets -->
        <div class="row" v-if="this.availableQualityScore !== 'IS'">
          <!-- Score Widget -->
          <div class="col-lg-4">
            <ScoreWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :qualityScore="this.availableQualityScore"
              :dataPointIdentife="null"
              :isCleaned="true"
              :groupSelector="
                this.selectedGroupSelector === 'All'
                  ? null
                  : this.selectedGroupSelector
              "
              @dataFetchingDone="countTheComponents"
            />
          </div>

          <!-- Distribution Widget -->
          <div class="col-lg-4">
            <DistributionWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :qualityScore="this.availableQualityScore"
              :dataPointIdentife="null"
              :isCleaned="true"
              :groupSelector="
                this.selectedGroupSelector === 'All'
                  ? null
                  : this.selectedGroupSelector
              "
              @dataFetchingDone="countTheComponents"
            />
          </div>

          <!-- Traffic Light Widget -->
          <div class="col-lg-4">
            <TrafficLightWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :qualityScore="this.availableQualityScore"
              :dataPointIdentife="null"
              :isCleaned="true"
              :groupSelector="
                this.selectedGroupSelector === 'All'
                  ? null
                  : this.selectedGroupSelector
              "
              @dataFetchingDone="countTheComponents"
            />
          </div>
        </div>

        <!-- SDS Widgets -->
        <div
          class="row"
          v-if="this.availableQualityScore === 'SDS'"
        >
          <div class="col-md-12">
            <SDSRateWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :dataPointIdentifier="null"
              :isCleaned="true"
              @dataFetchingDone="countTheComponents"
            />
          </div>
        </div>

        <!-- IS Widgets -->
        <div
          v-if="this.availableQualityScore === 'IS'"
          class="row"
        >
          
          <div class="col-md-12">
            <PositiveNegativeWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :dataPointIdentifier="All"
              :isCleaned="true"
              @dataFetchingDone="countTheComponents"
            />
          </div>

          
          <div class="col-md-12">
            <ISDatapointWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :isCleaned="true"
              @dataFetchingDone="countTheComponents"
            />
          </div>
        </div>

        <!-- Performance Widget for All Quality Scores & Survey Duration Widget only for TS -->
        <div class="row">
          <!-- Performance Widget -->
          <div
            :class="
              this.availableQualityScore === 'TS' ||
              this.availableQualityScore === 'OES'
                ? 'col-md-6'
                : 'col-md-12'
            "
          >
            <PerformanceWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :qualityScore="this.availableQualityScore"
              :dataPointIdentife="null"
              :isCleaned="true"
              :groupSelector="
                this.selectedGroupSelector === 'All'
                  ? null
                  : this.selectedGroupSelector
              "
              @dataFetchingDone="countTheComponents"
              @performanceWidgetAvaiable="performanceWidgetAvaiability"
            />
          </div>

          <!-- TS survey Widget -->
          <div class="col-md-6" v-if="this.availableQualityScore === 'TS'">
            <TSSurveyDurationWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :qualityScore="this.availableQualityScore"
              :dataPointIdentife="null"
              :isCleaned="true"
              :groupSelector="
                this.selectedGroupSelector === 'All'
                  ? null
                  : this.selectedGroupSelector
              "
              @dataFetchingDone="countTheComponents"
            />
          </div>

          <!-- OES Category Widget -->
          <div
            v-if="this.availableQualityScore === 'OES'"
            :class="!this.isPerformanceWidgetAvailable ? 'col-md-12' : 'col-md-6'"
          >
            <OESCategoryWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :isCleaned="true"
              :dataPointIdentife="null"
              @dataFetchingDone="countTheComponents"
            />
          </div>
        </div>

        <!-- CS Average Prediction Widgets -->
        <div
          class="row"
          v-if="!this.isDisplayMainLoading && this.availableQualityScore === 'CS'"
        >
          <div class="col-md-12">
            <AveragePredictionWidget
              :datafileId="this.datafileId"
              :isCleaned="true"
            />
          </div>
        </div>

        <!-- Data Record table -->
        <div
          class="row"
          v-if="
            this.availableQualityScore !== 'IS' &&
            this.availableQualityScore !== 'SDS'
          "
        >
          <div class="col-md-12">
            <DataRecordTableWidget
              class="animate__animated animate__fadeInUp animate__slow"
              :datafileId="this.datafileId"
              :qualityScore="this.availableQualityScore"
              :dataPointIdentife="null"
              :isCleaned="true"
              :groupSelector="
                this.selectedGroupSelector === 'All'
                  ? null
                  : this.selectedGroupSelector
              "
              @dataFetchingDone="countTheComponents"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Quota Settings Info widget Model -->
    <QuotaSettingsInfoModal
      v-if="showQuotaSettingsInfoModel"
      :visible="showQuotaSettingsInfoModel"
      @close="showQuotaSettingsInfo(false)"
    />
  </div>
</template>
