<script>
export default {
  props: {
    visible: Boolean,
    closeable: Boolean
  },
  data() {
    return {};
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  mounted() {},
  methods: {
    closeMode() {
      this.$emit("close");
    }
  }
};
</script>

<template>
  <!-- SDS Info -->
  <b-modal v-model="showModal" centered hide-footer hide-header>
    <div class="row">
      <div class="col-md-12">
        <b-card-title class="mb-4">
          <div class="float-right">
            <i
              class="bx bx-x font-size-20"
              style="cursor: pointer"
              @click="closeMode()"
            ></i>
          </div>
          <h4 class="card-title mb-3">
            {{ $t("qualityScoreWidgets.openEndedScoreWidget.title") }}
          </h4>
        </b-card-title>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mb-3">
        {{ $t("qualityScoreWidgets.openEndedScoreWidget.detailedInfo.text1") }}
      </div>
      <ul>
        <li>
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.openEndedScoreWidget.detailedInfo.text2")
            }}
          </div>
        </li>
        <li>
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.openEndedScoreWidget.detailedInfo.text3")
            }}
          </div>
        </li>
        <li>
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.openEndedScoreWidget.detailedInfo.text4")
            }}
          </div>
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-md-12 mb-3">
        {{ $t("qualityScoreWidgets.openEndedScoreWidget.useCase.title") }}
        <div>
          <ul>
            <li>
              {{ $t("qualityScoreWidgets.openEndedScoreWidget.useCase.text1") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-modal>
</template>
