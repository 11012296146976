var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    this.isDisplayMainLoader
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 text-center p-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !this.isDisplayMainLoader,
            expression: "!this.isDisplayMainLoader",
          },
        ],
      },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("PositiveNegativeWidget", {
                staticClass:
                  "animate__animated animate__fadeInUp animate__slow",
                attrs: {
                  datafileId: this.datafileId,
                  dataPointIdentifier: _vm.All,
                  isCleaned: true,
                },
                on: { dataFetchingDone: _vm.countTheComponents },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("DatapointWidget", {
                staticClass:
                  "animate__animated animate__fadeInUp animate__slow",
                attrs: { datafileId: this.datafileId, isCleaned: true },
                on: { dataFetchingDone: _vm.countTheComponents },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }