var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { centered: "", "hide-footer": "", "hide-header": "", size: "lg" },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "p-2" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h5", [
              _vm._v(
                _vm._s(_vm.$t("datafileResultIBSTab.dataPointInfoModal.title"))
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row mt-4" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultIBSTab.dataPointInfoModal.table.dataPoint"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("th", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultIBSTab.dataPointInfoModal.table.columns"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("th", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultIBSTab.dataPointInfoModal.table.patternCheck.label"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.GQSDataPoints, function (dataPoint, dataPointIndex) {
                  return _c("tr", { key: dataPointIndex }, [
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "dataRecordModal.itemBatteryScoreTab.items.text"
                            )
                          ) +
                          " " +
                          _vm._s(dataPointIndex + 1) +
                          " "
                      ),
                    ]),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.truncatedText(dataPoint.startColumn)) +
                          " "
                      ),
                      _c("i", {
                        staticClass:
                          "bx bx-arrow-back bx-flip-horizontal ml-2 mr-2",
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.truncatedText(dataPoint.endColumn)) +
                          " "
                      ),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v("(" + _vm._s(dataPoint.numberOfItems) + ")"),
                      ]),
                    ]),
                    _c("td", [
                      dataPoint.enablePatternCheck
                        ? _c(
                            "span",
                            { staticClass: "ml-2" },
                            [
                              _c(
                                "b-badge",
                                {
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { variant: "info" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "datafileResultIBSTab.dataPointInfoModal.table.patternCheck.yes"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "span",
                            { staticClass: "ml-2" },
                            [
                              _c(
                                "b-badge",
                                {
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { variant: "light" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "datafileResultIBSTab.dataPointInfoModal.table.patternCheck.no"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }