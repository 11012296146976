var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      this.isDisplayMainLoading
        ? _c("div", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 text-center p-5" },
                [
                  _c("b-spinner", {
                    staticClass: "m-2",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { variant: "mid", role: "status" },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !this.isDisplayMainLoading,
              expression: "!this.isDisplayMainLoading",
            },
          ],
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("MetaDataWidget", {
                  staticClass:
                    "animate__animated animate__fadeInUp animate__slow",
                  attrs: { datafileId: this.datafileId, cleaned: true },
                  on: { dataFetchingDone: _vm.countTheComponents },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { class: _vm.customWidgetWidth },
              [
                _c("DataRecordsSummaryWidget", {
                  staticClass:
                    "animate__animated animate__fadeInUp animate__slow",
                  attrs: {
                    datafileId: this.datafileId,
                    cleaned: true,
                    isCleaningApply: _vm.dbDatafile.isCleaned,
                    onlyOneQulityScore: false,
                  },
                  on: { dataFetchingDone: _vm.countTheComponents },
                }),
              ],
              1
            ),
            _c(
              "div",
              { class: _vm.customWidgetWidth },
              [
                _c("ReportWidget", {
                  staticClass:
                    "animate__animated animate__fadeInUp animate__slow",
                  attrs: {
                    datafileId: this.datafileId,
                    datafileTitle: this.dbDatafile.title,
                    datafile: this.dbDatafile,
                  },
                  on: {
                    displayManageReport: function ($event) {
                      return _vm.showManageReprot(true)
                    },
                    dataFetchingDone: _vm.countTheComponents,
                  },
                }),
              ],
              1
            ),
            this.dbDatafile.importType === "XLXS" &&
            _vm.isDisplayQuotaSettingsWidget
              ? _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("QuotaSettingsWidget", {
                      staticClass:
                        "animate__animated animate__fadeInUp animate__slow",
                      attrs: { datafileId: this.datafileId },
                      on: {
                        showQuotaInfo: function ($event) {
                          return _vm.showQuotaSettingsInfo(true)
                        },
                        dataFetchingDone: _vm.countTheComponents,
                        quotaSettingStatus: _vm.quotaSettingWidgetAvaiability,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-lg-4" },
              [
                _c("RScoreWidget", {
                  staticClass:
                    "animate__animated animate__fadeInUp animate__slow",
                  attrs: { datafileId: this.datafileId, cleaned: true },
                  on: {
                    showQualityScores: this.showQualityScoresModel,
                    showRScoreInfo: function ($event) {
                      return _vm.showRScoreInfo(true)
                    },
                    closeRScoreInfo: function ($event) {
                      return _vm.showRScoreInfo(false)
                    },
                    dataFetchingDone: _vm.countTheComponents,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-lg-4" },
              [
                _c("RScoreDistributionWidget", {
                  staticClass:
                    "animate__animated animate__fadeInUp animate__slow",
                  attrs: { datafileId: this.datafileId, cleaned: true },
                  on: { dataFetchingDone: _vm.countTheComponents },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-lg-4" },
              [
                _c("RScoreTrafficLightWidget", {
                  staticClass:
                    "animate__animated animate__fadeInUp animate__slow",
                  attrs: { datafileId: this.datafileId, cleaned: true },
                  on: { dataFetchingDone: _vm.countTheComponents },
                }),
              ],
              1
            ),
          ]),
          _vm.algorithms.IS || _vm.algorithms.SDS
            ? _c("div", { staticClass: "row" }, [
                _vm._m(0),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.algorithms.IS,
                        expression: "algorithms.IS",
                      },
                    ],
                    staticClass: "col-12",
                  },
                  [
                    _c("ISQualityScoreWidget", {
                      attrs: { datafileId: this.datafileId, cleaned: true },
                      on: {
                        dataFetchingDone: _vm.countTheComponents,
                        viewMore: _vm.clickViewMoreQulityScore,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.algorithms.SDS,
                        expression: "algorithms.SDS",
                      },
                    ],
                    staticClass: "col-12",
                  },
                  [
                    _c("SDSQualityScoreWidget", {
                      attrs: { datafileId: this.datafileId, cleaned: true },
                      on: {
                        dataFetchingDone: _vm.countTheComponents,
                        viewMore: _vm.clickViewMoreQulityScore,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm.showRScoreInfoModel
        ? _c("RScoreWidgetInfoModal", {
            attrs: { visible: _vm.showRScoreInfoModel },
            on: {
              close: function ($event) {
                return _vm.showRScoreInfo(false)
              },
            },
          })
        : _vm._e(),
      _vm.showQuotaSettingsInfoModel
        ? _c("QuotaSettingsInfoModal", {
            attrs: { visible: _vm.showQuotaSettingsInfoModel },
            on: {
              close: function ($event) {
                return _vm.showQuotaSettingsInfo(false)
              },
            },
          })
        : _vm._e(),
      _c("ManageReportModal", {
        attrs: {
          visible: this.showManageReprotModal,
          datafileId: this.datafileId,
        },
        on: {
          close: function ($event) {
            _vm.showManageReprotModal = false
          },
          reload: function ($event) {
            return _vm.reloadPage()
          },
        },
      }),
      _vm.isDisplayQualityScoreModal
        ? _c("QualityScoresModal", {
            attrs: {
              visible: this.isDisplayQualityScoreModal,
              datafileId: this.datafileId,
              isCleanedDatafile: true,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayQualityScoreModal = false
              },
              clickViewMoreQualitySocre: this.clickViewMoreQulityScore,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12 mt-3 mb-4" }, [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }