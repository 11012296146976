<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import DatafileResultService from "@/api/services/datafileResult.service.js";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";

export default {
  props: {
    datafileId: String,
    dataPointIdentifier: String,
    isCleaned: Boolean
  },
  components: {
    DataFetchingErrorWidget
  },
  data() {
    return {
      chartDataForPositiveNegative: {
        positive: 0,
        negative: 0
      },
      /******* Operational Variables *******/
      isDisplayData: false,
      isFetchingDataError: false
    };
  },
  async created() {
    await this.fetchCahrtData();
    this.isDisplayData = true;
    this.$emit("dataFetchingDone");
  },
  methods: {
    async fetchCahrtData() {
      await DatafileResultService.getISPositiveNegative(
        this.datafileId,
        this.dataPointIdentifier
      )
        .then(data => {
          this.chartDataForPositiveNegative = {
            positive: data.Positive,
            negative: data.Negative
          };

          this.isDisplayData = true;
        })
        .catch(e => {
          this.isFetchingDataError = true;
          this.isDisplayData = true;
        });
    }
  }
};
</script>
<template>
  <div
    class="card"
    :style="[
      this.isCleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
    ]"
  >
    <!-- Results -->
    <div class="card-body" v-if="this.isDisplayData">
      <b-card-title class="pb-1">
        <h4 class="card-title mb-1">
          {{ $t("datafileResultISTab.positiveNegativeWidget.title") }}
        </h4>
      </b-card-title>
      <div class="row mb-3">
        <div class="col-md-12">
          {{ $t("datafileResultISTab.positiveNegativeWidget.description") }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <span>
            <i class="bx bxs-circle text-mid"> </i>
            {{ $t("datafileResultISTab.positiveNegativeWidget.negativeLabel") }}
            %
          </span>
          <span class="ml-3">
            <i class="bx bxs-circle text-success"> </i>
            {{ $t("datafileResultISTab.positiveNegativeWidget.positiveLabel") }}
            %
          </span>
        </div>
      </div>

      <b-progress
        v-if="!this.isFetchingDataError"
        class="mt-4"
        :max="100"
        height="30px"
        style="font-size: 15px"
      >
        <b-progress-bar
          :value="chartDataForPositiveNegative.negative"
          variant="mid"
          show-value
        ></b-progress-bar>
        <b-progress-bar
          :value="chartDataForPositiveNegative.positive"
          variant="success"
          show-value
        ></b-progress-bar>
      </b-progress>

      <div class="card-text mt-3" v-if="this.isFetchingDataError">
        <DataFetchingErrorWidget />
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div class="card-body" v-else>
      <div class="row justify-content-center">
        <div class="col-6 text-center">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>
