var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card",
      staticStyle: { height: "480px" },
      style: [
        this.isCleaned ? { background: "#FFFFFF" } : { background: "#FFF5F4" },
      ],
    },
    [
      _vm.isDisplayData
        ? _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "card-title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "datafileResultGeneral.generalWidgets.trafficLightWidget.title"
                    )
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "card-text" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "datafileResultGeneral.generalWidgets.trafficLightWidget.description"
                    )
                  ) +
                  " "
              ),
            ]),
            !this.isFetchingDataError
              ? _c(
                  "div",
                  { staticClass: "card-text mt-5" },
                  [
                    _c("apexchart", {
                      ref: "trafficLightChart",
                      staticClass: "apex-charts",
                      attrs: {
                        dir: "ltr",
                        type: "donut",
                        height: "300",
                        series: _vm.trafficLightChartData,
                        options: _vm.trafficLightChartOptions.chartOptions,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            this.isFetchingDataError
              ? _c(
                  "div",
                  { staticClass: "card-text mt-3" },
                  [_c("DataFetchingErrorWidget")],
                  1
                )
              : _vm._e(),
          ])
        : _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-6 text-center pt-5" },
                [
                  _c("b-spinner", {
                    staticClass: "m-2",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { variant: "mid", role: "status" },
                  }),
                ],
                1
              ),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }