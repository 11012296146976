<script>
import DatafileResultService from "@/api/services/datafileResult.service.js";
import { DistributionHistogramChart, BenchmarkDistributionHistoramChart } from "@/redem/datafiles/result-charts.js";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";
import { EventBus } from "@/shared/util/event-bus.js";
export default {
  props: {
    datafileId: String,
    cleaned: Boolean,
    toggleBenchmark: Boolean
  },
  components: {
    DataFetchingErrorWidget
  },
  data() {
    return {
      rScoreDistributionChartData: null,
      rScoreDistributionChartOptions: null,
      benchmarkDistributionData: null,
      benchmarkDistributionChartOptions: null,
      displayBenchmarkChart: false,
      benchmarkDistributionChartData: [
        {
          name: this.$i18n.t("datafileResultRawTab.benchmarkWidget.yourData"),
          data: [
            { x: '0-10', y:0, goals: [ {name: 'Benchmark', value: 0, strokeHeight: 5, strokeColor: '#00a181'}]},
            { x: '10-20', y:0, goals: [ {name: 'Benchmark', value: 0, strokeHeight: 5, strokeColor: '#00a181'}]},
            { x: '20-30', y:0, goals: [ {name: 'Benchmark', value: 0, strokeHeight: 5, strokeColor: '#00a181'}]},
            { x: '30-40', y:0, goals: [ {name: 'Benchmark', value: 0, strokeHeight: 5, strokeColor: '#00a181'}]},
            { x: '40-50', y:0, goals: [ {name: 'Benchmark', value: 0, strokeHeight: 5, strokeColor: '#00a181'}]},
            { x: '50-60', y:0, goals: [ {name: 'Benchmark', value: 0, strokeHeight: 5, strokeColor: '#00a181'}]},
            { x: '60-70', y:0, goals: [ {name: 'Benchmark', value: 0, strokeHeight: 5, strokeColor: '#00a181'}]},
            { x: '70-80', y:0, goals: [ {name: 'Benchmark', value: 0, strokeHeight: 5, strokeColor: '#00a181'}]},
            { x: '80-90', y:0, goals: [ {name: 'Benchmark', value: 0, strokeHeight: 5, strokeColor: '#00a181'}]},
            { x: '90-100', y:0, goals: [ {name: 'Benchmark', value: 0, strokeHeight: 5, strokeColor: '#00a181'}]}
          ]
        }
      ],
      /******* Operational Varaibles *****/
      isDisplayData: false,
      isDisplayRScoreDistributionError: false,
      benchmarkDataNotAvailable: false
    };
  },
  computed: {
    propoverMethodInfo() {
      return {
        html: true,
        content: () =>
        this.$i18n.t("datafileResultGeneral.distributionWidget.benchmarkInfo")
      };
    } 
  },
  watch: {
    async toggleBenchmark(newValue) {
      this.displayBenchmarkChart = newValue;
      await this.fetchAllData();
    }
  },
  async created() {
    this.rScoreDistributionChartOptions = new DistributionHistogramChart();
    this.benchmarkDistributionChartOptions = new BenchmarkDistributionHistoramChart();
    await this.fetchAllData();
    this.$emit("dataFetchingDone");

    EventBus.$on("updateScoreDistributionData", async (distributionData) => {
      const chartSeries = [{
        name: "Number of Datarecords",
        data: distributionData
      }];
      this.rScoreDistributionChartData = chartSeries;
      if (this.$refs.distributionChart) this.$refs.distributionChart.updateSeries(chartSeries, true);
    });
  },
  methods: {
    async fetchAllData() {
      this.isDisplayData = false;
      this.benchmarkDataNotAvailable = false;
      this.isDisplayRScoreDistributionError = false;
      try {
        const response = await DatafileResultService.getDistributionData(this.datafileId,"R", null, this.cleaned, null, this.displayBenchmarkChart);

        if(response && response.scoreDistribution) {
          this.rScoreDistributionChartData = [{
            name: "Number of Datarecords",
            data: response.scoreDistribution
          }]
        }

        if(response && response.benchmarkScoreDistribution) {
          this.benchmarkDistributionData = [{
            name: "Number of Datarecords Benchmark",
            data: response.benchmarkScoreDistribution
          }]

          for(let i=0; i < this.benchmarkDistributionChartData[0].data.length ; i++) {
            this.benchmarkDistributionChartData[0].data[i].y = this.rScoreDistributionChartData[0].data[i];
            this.benchmarkDistributionChartData[0].data[i].goals[0].value = this.benchmarkDistributionData[0].data[i];
          }
        }
        
        this.isDisplayData = true;
      } catch(error) {
        this.isDisplayData = true;
        if (error.code === "BENCHMARK_NOT_FOUND") {
          this.benchmarkDataNotAvailable = true;
          this.isDisplayRScoreDistributionError = true;
        } else {
          this.isDisplayRScoreDistributionError = true;
        }
      }
    }   
  }
};
</script>

<template>
  <div
    class="card"
    style="height: 450px"
    :style="[
      this.cleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
    ]"
  >
    <div class="card-body" v-if="isDisplayData">
      <!-- Card Title  -->
      <div class="card-title">
        {{ $t("datafileResultGeneral.distributionWidget.title") }}
        <!-- Info Button -->
        <i v-if="displayBenchmarkChart"
          v-b-popover.hover="this.propoverMethodInfo"
          class="ml-2 bx bxs-info-circle text-info"
          style="cursor: pointer"
        ></i>         
      </div>

      <!-- Card Description  -->
      <div class="card-text mt-4">
        {{ $t("datafileResultGeneral.distributionWidget.description") }}
      </div>

      <!-- Card Data -->
      <div class="card-text mt-4">
        <div class="row">
          <!-- Regular Distribution Chart -->
          <div class="col-12"
            v-if="
              (!isDisplayRScoreDistributionError && this.cleaned && !displayBenchmarkChart) ||
              (!isDisplayRScoreDistributionError && !this.cleaned && !displayBenchmarkChart)
            ">
            <apexchart
              ref="distributionChart"
              class="apex-charts"
              dir="ltr"
              type="bar"
              height="300"
              :series="rScoreDistributionChartData"
              :options="rScoreDistributionChartOptions.chartOptions"
            ></apexchart>
          </div>
          <!-- Benchmark Distribution Chart -->
          <div class="col-12" v-else-if="!isDisplayRScoreDistributionError && !this.cleaned && displayBenchmarkChart">
            <apexchart
              ref="benchmarkDistributionChart"
              class="apex-charts"
              dir="ltr"
              type="bar"
              height="300"
              :series="benchmarkDistributionChartData"
              :options="benchmarkDistributionChartOptions.chartOptions"
            ></apexchart>
          </div>
          <div v-else-if="benchmarkDataNotAvailable" class="text-center col-12 mt-2">
            <b-alert
              show
              variant="info"
            >
              {{ $t("datafileResultGeneral.benchmarkNotAvailable") }}
            </b-alert>
          </div>          
          <div v-else>
            <DataFetchingErrorWidget />
          </div>
        </div>
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div class="row justify-content-center" v-else>
      <div class="col-6 text-center pt-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>
