<script>
export default {
  data() {
    return {
      isDisplayError: true
    };
  }
};
</script>

<template>
  <div class="row">
    <div
      class="col-md-12"
      style="
        display: flex;
        text-align: center;
        color: #adb5bd;
        height: 250px;
        justify-content: center;
        align-items: center;
      "
    >
      <div style="text-align: center; color: #adb5bd">
        {{ $t("general.info.noValidScoresAvailable") }}
      </div>
    </div>
  </div>
</template>
