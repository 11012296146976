var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card",
      style: [
        this.isCleaned ? { background: "#FFFFFF" } : { background: "#FFF5F4" },
      ],
    },
    [
      this.isDisplayData
        ? _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("b-card-title", { staticClass: "pb-1" }, [
                _c("h4", { staticClass: "card-title mb-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "datafileResultISTab.positiveNegativeWidget.title"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "datafileResultISTab.positiveNegativeWidget.description"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("span", [
                    _c("i", { staticClass: "bx bxs-circle text-mid" }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultISTab.positiveNegativeWidget.negativeLabel"
                          )
                        ) +
                        " % "
                    ),
                  ]),
                  _c("span", { staticClass: "ml-3" }, [
                    _c("i", { staticClass: "bx bxs-circle text-success" }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultISTab.positiveNegativeWidget.positiveLabel"
                          )
                        ) +
                        " % "
                    ),
                  ]),
                ]),
              ]),
              !this.isFetchingDataError
                ? _c(
                    "b-progress",
                    {
                      staticClass: "mt-4",
                      staticStyle: { "font-size": "15px" },
                      attrs: { max: 100, height: "30px" },
                    },
                    [
                      _c("b-progress-bar", {
                        attrs: {
                          value: _vm.chartDataForPositiveNegative.negative,
                          variant: "mid",
                          "show-value": "",
                        },
                      }),
                      _c("b-progress-bar", {
                        attrs: {
                          value: _vm.chartDataForPositiveNegative.positive,
                          variant: "success",
                          "show-value": "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              this.isFetchingDataError
                ? _c(
                    "div",
                    { staticClass: "card-text mt-3" },
                    [_c("DataFetchingErrorWidget")],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-6 text-center" },
                [
                  _c("b-spinner", {
                    staticClass: "m-2",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { variant: "mid", role: "status" },
                  }),
                ],
                1
              ),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }