var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: this.width }, [
    _c("div", { staticClass: "card", style: this.style }, [
      _c("div", { staticClass: "text-center" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-11" },
            [
              _c(
                "b-alert",
                {
                  staticClass: "mb-4",
                  attrs: { variant: "danger" },
                  model: {
                    value: _vm.isDisplayError,
                    callback: function ($$v) {
                      _vm.isDisplayError = $$v
                    },
                    expression: "isDisplayError",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("general.errors.dataFetchingError")))]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }