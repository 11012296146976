var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !this.isDisplayMainLoader,
            expression: "!this.isDisplayMainLoader",
          },
        ],
      },
      [
        this.groupSelectors.length > 1
          ? _c("div", { staticClass: "row mb-3" }, [
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultTSTab.groupSelectorDropdown.label"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("Multiselect", {
                    attrs: {
                      options: _vm.groupSelectors,
                      placeholder: _vm.$t(
                        "uploadProcessStep2.form.general.selectColumn.placeholder"
                      ),
                    },
                    model: {
                      value: _vm.selectedGroupSelector,
                      callback: function ($$v) {
                        _vm.selectedGroupSelector = $$v
                      },
                      expression: "selectedGroupSelector",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("MetadataWidget", {
                staticClass:
                  "animate__animated animate__fadeInUp animate__slow",
                attrs: {
                  datafileId: this.datafileId,
                  groupSelector:
                    this.selectedGroupSelector === "All"
                      ? null
                      : this.selectedGroupSelector,
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ScoreWidget", {
                staticClass:
                  "animate__animated animate__fadeInUp animate__slow",
                attrs: {
                  datafileId: this.datafileId,
                  qualityScore: "TS",
                  dataPointIdentife: null,
                  isCleaned: true,
                  groupSelector:
                    this.selectedGroupSelector === "All"
                      ? null
                      : this.selectedGroupSelector,
                },
                on: { dataFetchingDone: _vm.countTheComponents },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("DistributionWidget", {
                staticClass:
                  "animate__animated animate__fadeInUp animate__slow",
                attrs: {
                  datafileId: this.datafileId,
                  qualityScore: "TS",
                  dataPointIdentife: null,
                  isCleaned: true,
                  groupSelector:
                    this.selectedGroupSelector === "All"
                      ? null
                      : this.selectedGroupSelector,
                },
                on: { dataFetchingDone: _vm.countTheComponents },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("TrafficLightWidget", {
                staticClass:
                  "animate__animated animate__fadeInUp animate__slow",
                attrs: {
                  datafileId: this.datafileId,
                  qualityScore: "TS",
                  dataPointIdentife: null,
                  isCleaned: true,
                  groupSelector:
                    this.selectedGroupSelector === "All"
                      ? null
                      : this.selectedGroupSelector,
                },
                on: { dataFetchingDone: _vm.countTheComponents },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("PerformanceWidget", {
                staticClass:
                  "animate__animated animate__fadeInUp animate__slow",
                attrs: {
                  datafileId: this.datafileId,
                  qualityScore: "TS",
                  dataPointIdentife: null,
                  isCleaned: true,
                  groupSelector:
                    this.selectedGroupSelector === "All"
                      ? null
                      : this.selectedGroupSelector,
                },
                on: { dataFetchingDone: _vm.countTheComponents },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("TSSurveyDurationWidget", {
                staticClass:
                  "animate__animated animate__fadeInUp animate__slow",
                attrs: {
                  datafileId: this.datafileId,
                  qualityScore: "TS",
                  dataPointIdentife: null,
                  isCleaned: true,
                  groupSelector:
                    this.selectedGroupSelector === "All"
                      ? null
                      : this.selectedGroupSelector,
                },
                on: { dataFetchingDone: _vm.countTheComponents },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("DataRecordTableWidget", {
                staticClass:
                  "animate__animated animate__fadeInUp animate__slow",
                attrs: {
                  datafileId: this.datafileId,
                  qualityScore: "TS",
                  dataPointIdentife: null,
                  isCleaned: true,
                  groupSelector:
                    this.selectedGroupSelector === "All"
                      ? null
                      : this.selectedGroupSelector,
                },
                on: { dataFetchingDone: _vm.countTheComponents },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
    this.isDisplayMainLoader
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 text-center p-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }