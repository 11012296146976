var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card animate__animated animate__fadeInUp animate__slow",
      staticStyle: { height: "850px" },
      style: [
        this.cleaned ? { background: "#FFFFFF" } : { background: "#FFF5F4" },
      ],
    },
    [
      _vm.isDisplayData
        ? _c("div", {}, [
            !_vm.isDisplayInfo
              ? _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("b-card-title", { staticClass: "pb-2" }, [
                      this.cleaned
                        ? _c(
                            "div",
                            { staticClass: "float-right" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn-sm",
                                  attrs: { variant: "light" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickViewMoreButton()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.qualityScoreWidget.showMoreButton"
                                      )
                                    ) + " "
                                  ),
                                  _c("i", {
                                    staticClass: "bx bx-right-arrow-alt ml-1",
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("h4", { staticClass: "card-title mb-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "qualityScoreWidgets.itemBatteryScoreWidget.title"
                              )
                            ) +
                            " "
                        ),
                        _c("i", {
                          staticClass:
                            "bx bxs-info-circle font-size-20 text-info bx-tada ml-1",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.changeToShowInfo()
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "row mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12 text-center" },
                        [
                          _c("apexchart", {
                            ref: "PSMeanChart",
                            staticClass: "apex-charts apexChartData",
                            attrs: {
                              type: "radialBar",
                              height: "250",
                              dir: "ltr",
                              series: [_vm.chartDataForMeanChart],
                              options: _vm.meanChartOptions.chartOptions,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-md-1" }),
                      _c("div", { staticClass: "col-md-10 text-center" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "qualityScoreWidgets.itemBatteryScoreWidget.description"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "col-md-1" }),
                    ]),
                    _c("div", { staticClass: "row pt-4" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "b-tabs",
                            {
                              attrs: {
                                justified: "",
                                "content-class": "p-3 text-muted",
                              },
                            },
                            [
                              _c(
                                "b-tab",
                                {
                                  attrs: { active: "", lazy: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-inline-block d-sm-none",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bx bx-line-chart",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-none d-sm-inline-block",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "qualityScoreWidgets.itemBatteryScoreWidget.tabs.distribution"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    735185931
                                  ),
                                },
                                [
                                  _c("div", { staticClass: "mt-3" }, [
                                    !_vm.isDisplayDistributionError
                                      ? _c(
                                          "div",
                                          [
                                            _c("apexchart", {
                                              ref: "PSDistributionChart",
                                              staticClass: "apex-charts mt-5",
                                              attrs: {
                                                dir: "ltr",
                                                type: "area",
                                                height: "300",
                                                series:
                                                  _vm.chartDataForDistributionChart,
                                                options:
                                                  _vm.distributionChartOptions
                                                    .chartOptions,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          [_c("DataFetchingErrorWidget")],
                                          1
                                        ),
                                  ]),
                                ]
                              ),
                              _c(
                                "b-tab",
                                {
                                  attrs: { lazy: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-inline-block d-sm-none",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "bx bxs-traffic",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-none d-sm-inline-block",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "qualityScoreWidgets.itemBatteryScoreWidget.tabs.trafficLight"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    934618195
                                  ),
                                },
                                [
                                  _c("div", { staticClass: "mt-3" }, [
                                    !_vm.isDisplayTrafficLightError
                                      ? _c(
                                          "div",
                                          [
                                            _c("apexchart", {
                                              ref: "PStrafficLightChart",
                                              staticClass:
                                                "apex-charts mt-5 mb-4",
                                              attrs: {
                                                height: "300",
                                                type: "pie",
                                                dir: "ltr",
                                                series:
                                                  _vm.chartDataForTrafficLightChart,
                                                options:
                                                  _vm.trafficLightChartOptions
                                                    .chartOptions,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          [_c("DataFetchingErrorWidget")],
                                          1
                                        ),
                                  ]),
                                ]
                              ),
                              this.chartDataForPerformanceChart.data.length > 1
                                ? _c(
                                    "b-tab",
                                    {
                                      attrs: { lazy: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "title",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-inline-block d-sm-none",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bx bx-trending-up",
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-none d-sm-inline-block",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "qualityScoreWidgets.itemBatteryScoreWidget.tabs.performance"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2434667335
                                      ),
                                    },
                                    [
                                      _c("div", { staticClass: "mt-3" }, [
                                        !_vm.isDisplayDataPointError
                                          ? _c(
                                              "div",
                                              [
                                                _c("apexchart", {
                                                  ref: "performanceChart",
                                                  staticClass:
                                                    "apex-charts mt-5",
                                                  attrs: {
                                                    dir: "ltr",
                                                    type: "bar",
                                                    height: "300",
                                                    series: [
                                                      _vm.chartDataForPerformanceChart,
                                                    ],
                                                    options:
                                                      _vm
                                                        .performanceChartOptions
                                                        .chartOptions,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "text-center" },
                                              [_c("DataFetchingErrorWidget")],
                                              1
                                            ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              : _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-md-10" }, [
                      _c("h4", { staticClass: "card-title mb-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "qualityScoreWidgets.itemBatteryScoreWidget.title"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-2 text-right" }, [
                      _c("i", {
                        staticClass:
                          "bx bxs-x-circle font-size-20 text-info bx-tada",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.changeToShowData()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.itemBatteryScoreWidget.detailedInfo.text1"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.itemBatteryScoreWidget.detailedInfo.text2"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.itemBatteryScoreWidget.detailedInfo.text3"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.itemBatteryScoreWidget.detailedInfo.useCase.title"
                            )
                          ) +
                          " "
                      ),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "qualityScoreWidgets.itemBatteryScoreWidget.detailedInfo.useCase.text1"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
          ])
        : _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center pt-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }