<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import DatafileResultService from "@/api/services/datafileResult.service.js";
import OESFeedbackModal from "@/redem/datafiles/components/modals/OES-suggest-score-modal.vue";
import DatarecordModal from "@/redem/datafiles/components/modals/respondent-modal/index.vue";
import { ALGORITHM_TYPES } from "@/shared/util/const";
import { EventBus } from "@/shared/util/event-bus.js";
import { getLanaguegName, mapOESCategories } from "@/shared/util/helpers.js";

export default {
  props: {
    datafileId: String,
    qualityScore: String,
    dataPointIdentifer: String,
    isCleaned: Boolean,
    groupSelector: String
  },
  components: {
    DatarecordModal,
    OESFeedbackModal
  },
  data() {
    return {
      /***** Data varaiables *****/
      allDatarecords: null,
      includeTS: false,
      includePS: false,
      includeCS: false,
      includeIBS: false,
      includeOES: false,
      activatedTabForOES: null,
      activatedTabForRespondentModal: 0,
      allTabsInRespondentModal: null,
      OESDataPoints: null,
      OESDataPointIdentifiers: [],
      OESQuestionTitles: [],
      selectedOESQuestion: "All",
      selectedDataPointforGetAllDataRecordEndpoint: null,
      respondentForRespondentModal: null,
      dataForOESFeedbackModal: {
        dataRecord: {
          dataRecordId: null,
          answerFromRespondent: null,
          suggestedBy: null,
          comment: null,
          qualityScore: null
        },
        dataPointIndentifier: null,
        questionTitle: null
      },
      isOpenOESFeedbackModalFromRespondentModal: false,
      isShowExcludedDataRecordsAsWell: false,
      /******* Tebal Varaibles ******/
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "qualityScore",
      sortDesc: true,
      vGravatarSize: 20,
      /***** Operational varaiables *****/
      isDisplayData: false,
      isFetchingOESData: false,
      isFetchingDataError: false,
      isDisplayRecordModal: false,
      isDisplayOESFeedbackModal: false
    };
  },
  watch: {
    groupSelector: function (newValue, oldValue) {
      this.fetchTableData();
    },
    async isShowExcludedDataRecordsAsWell() {
      await this.fetchTableData();
    }
  },
  async created() {
    EventBus.$on(
      "showOESFeedbackModal",
      async (
        dataPointIdentifierForFeedback,
        questionTitle,
        selectedDataRecord
      ) => {
        this.dataForOESFeedbackModal.dataPointIndentifier =
          dataPointIdentifierForFeedback;
        this.dataForOESFeedbackModal.questionTitle = questionTitle;
        this.dataForOESFeedbackModal.dataRecord.dataRecordId =
          selectedDataRecord.dataRecordId;
        this.dataForOESFeedbackModal.dataRecord.answerFromRespondent =
          selectedDataRecord.answerFromRespondent;
        this.dataForOESFeedbackModal.dataRecord.qualityScore =
          selectedDataRecord.qualityScore;

        this.isOpenOESFeedbackModalFromRespondentModal = true;
        this.isDisplayRecordModal = false;
        this.isDisplayOESFeedbackModal = true;
      }
    );
    EventBus.$on("showDuplicatedRespondent", (respondentId) => {
      this.openRespondentModal(respondentId.trim());
    });

    this.selectedDataPointforGetAllDataRecordEndpoint = null;
    await this.fetchAllDataPoints();
    await this.fetchTableData();
    this.$emit("dataFetchingDone");
    this.initializeEventBus();
  },
  computed: {
    fieldsForTable() {
      let fields;
      let trafficLightSortingFormatter = (v) => {
        if (v === "Green") return 0;
        if (v === "Yellow") return 1;
        if (v === "LightGreen") return 2;
        if (v === "Red") return 3;
        if (v === "Grey") return 4;
      };
      if (this.qualityScore === ALGORITHM_TYPES.TS) {
        fields = [
          {
            key: "rank",
            label: this.$t("datafileResultTSTab.dataRecordTable.rank"),
            sortable: true
          },
          {
            key: "recordId",
            label: this.$t("datafileResultTSTab.dataRecordTable.recordID"),
            sortable: false
          },
          {
            key: "qualityScore",
            label: this.$t("datafileResultTSTab.dataRecordTable.timeScore"),
            sortable: true
          },
          {
            key: "validDatapoints",
            label: this.$t(
              "datafileResultTSTab.dataRecordTable.validDataPoints"
            ),
            sortable: true
          },
          {
            key: "group",
            label: this.$t("datafileResultTSTab.dataRecordTable.group"),
            sortable: true,
            thClass: this.groupSelectorInclude ? "" : "d-none",
            tdClass: this.groupSelectorInclude ? "" : "d-none"
          },
          {
            key: "trafficLight",
            label: this.$t("datafileResultTSTab.dataRecordTable.trafficLight"),
            sortable: true,
            sortByFormatted: trafficLightSortingFormatter
          }
        ];
      } else if (this.qualityScore === ALGORITHM_TYPES.IBS) {
        fields = [
          {
            key: "rank",
            label: this.$t("datafileResultIBSTab.dataRecordTable.rank"),
            sortable: true
          },
          {
            key: "recordId",
            label: this.$t("datafileResultIBSTab.dataRecordTable.recordID"),
            sortable: false
          },
          {
            key: "qualityScore",
            label: this.$t(
              "datafileResultIBSTab.dataRecordTable.itemBatteryScore"
            ),
            sortable: true
          },
          {
            key: "validDatapoints",
            label: this.$t(
              "datafileResultIBSTab.dataRecordTable.validDataPoints"
            ),
            sortable: true
          },
          {
            key: "trafficLight",
            label: this.$t("datafileResultIBSTab.dataRecordTable.trafficLight"),
            sortable: true,
            sortByFormatted: trafficLightSortingFormatter
          }
        ];
      } else if (this.qualityScore === ALGORITHM_TYPES.CS) {
        fields = [
          {
            key: "rank",
            label: this.$t("datafileResultCSTab.dataRecordTable.rank"),
            sortable: true
          },
          {
            key: "recordId",
            label: this.$t("datafileResultCSTab.dataRecordTable.recordID"),
            sortable: false
          },
          {
            key: "qualityScore",
            label: this.$t(
              "datafileResultCSTab.dataRecordTable.certaintyScore"
            ),
            sortable: true
          },
          {
            key: "validDatapoints",
            label: this.$t(
              "datafileResultCSTab.dataRecordTable.validDataPoints"
            ),
            sortable: true
          },
          {
            key: "trafficLight",
            label: this.$t("datafileResultCSTab.dataRecordTable.trafficLight"),
            sortable: true,
            sortByFormatted: trafficLightSortingFormatter
          }
        ];
      } else if (this.qualityScore === ALGORITHM_TYPES.PS) {
        fields = [
          {
            key: "rank",
            label: this.$t("datafileResultPSTab.dataRecordTable.rank"),
            sortable: true
          },
          {
            key: "recordId",
            label: this.$t("datafileResultPSTab.dataRecordTable.recordID"),
            sortable: false
          },
          {
            key: "qualityScore",
            label: this.$t(
              "datafileResultPSTab.dataRecordTable.predictionScore"
            ),
            sortable: true
          },
          {
            key: "validDatapoints",
            label: this.$t(
              "datafileResultPSTab.dataRecordTable.validDataPoints"
            ),
            sortable: true
          },
          {
            key: "trafficLight",
            label: this.$t("datafileResultPSTab.dataRecordTable.trafficLight"),
            sortable: true,
            sortByFormatted: trafficLightSortingFormatter
          }
        ];
      } else if (this.qualityScore === ALGORITHM_TYPES.OES) {
        fields = [
          {
            key: "rank",
            label: this.$t("datafileResultPSTab.dataRecordTable.rank"),
            sortable: true,
            thStyle: { width: "100px" }
          },
          {
            key: "recordId",
            label: this.$t("datafileResultPSTab.dataRecordTable.recordID"),
            sortable: true,
            thStyle: { width: "150px" }
          },
          {
            key: "qualityScore",
            label: "Open-Ended-Score",
            sortable: true,
            thStyle: { width: "180px" }
          },
          {
            key: "validDatapoints",
            label: this.$t(
              "datafileResultPSTab.dataRecordTable.validDataPoints"
            ),
            sortable: true,
            thClass:
              this.selectedDataPointforGetAllDataRecordEndpoint === null
                ? ""
                : "d-none",
            tdClass:
              this.selectedDataPointforGetAllDataRecordEndpoint === null
                ? ""
                : "d-none"
          },
          {
            key: "oesAnswer",
            label: this.$t("datafileResultOESTab.dataRecordTable.answer"),
            thClass:
              this.selectedDataPointforGetAllDataRecordEndpoint !== null
                ? ""
                : "d-none",
            tdClass:
              this.selectedDataPointforGetAllDataRecordEndpoint !== null
                ? ""
                : "d-none",
            sortable: true
          },
          {
            key: "trafficLight",
            label: this.$t("datafileResultPSTab.dataRecordTable.trafficLight"),
            sortable: true,
            sortByFormatted: trafficLightSortingFormatter,
            thStyle: { width: "120px" }
          },
          {
            key: "oesAnswerCategory",
            sortable: true,
            label: this.$t(
              "datafileResultOESTab.dataRecordTable.answerCategory"
            ),
            thClass:
              this.selectedDataPointforGetAllDataRecordEndpoint !== null
                ? ""
                : "d-none",
            tdClass:
              this.selectedDataPointforGetAllDataRecordEndpoint !== null
                ? ""
                : "d-none"
          }
        ];
      } else {
        fields = [
          {
            key: "rank",
            label: this.$t("datafileResultDataPointTab.dataRecordTable.rank"),
            sortable: true
          },
          {
            key: "recordId",
            label: this.$t(
              "datafileResultDataPointTab.dataRecordTable.recordID"
            ),
            sortable: false
          },
          {
            key: "qualityScore",
            label: this.$t("datafileResultDataPointTab.dataRecordTable.rScore"),
            sortable: true
          },
          {
            key: "trafficLight",
            label: this.$t(
              "datafileResultDataPointTab.dataRecordTable.trafficLight"
            ),
            sortable: true,
            sortByFormatted: trafficLightSortingFormatter
          }
        ];
      }

      return fields;
    },
    rows() {
      return this.allDatarecords.length;
    }
  },
  methods: {
    async fetchAllDataPoints() {
      return new Promise((resolve, reject) => {
        DatafileResultService.getAllDataPoints(
          this.datafileId,
          ALGORITHM_TYPES.OES
        )
          .then(async (data) => {
            this.OESDataPoints = data;
            data.forEach((datapoint) => {
              this.OESDataPointIdentifiers.push(datapoint.dataPointIdentifier);
              this.OESQuestionTitles.push(datapoint.questionTitle);
            });

            if (this.OESDataPointIdentifiers.length === 1) {
              this.selectedDataPointforGetAllDataRecordEndpoint =
                this.OESDataPointIdentifiers[0];
            }

            this.isDisplayaDatapointData = true;
            resolve();
          })
          .catch((e) => {
            reject();
            this.errorDatapointData = true;
          });
      });
    },
    async fetchTableData(reload = true) {
      // enable Loading
      if (reload) this.isDisplayData = false;
      if (String(this.qualityScore) !== "OES") {
        this.selectedDataPointforGetAllDataRecordEndpoint = null;
      }
      await DatafileResultService.getAllDatarecords(
        this.datafileId,
        this.selectedDataPointforGetAllDataRecordEndpoint,
        String(this.qualityScore),
        this.groupSelector,
        this.isCleaned ? this.isShowExcludedDataRecordsAsWell : true
      )
        .then(async (data) => {
          this.allDatarecords = data.allDatarecords;
          this.includeTS = data.includeTimestamp;
          this.includePS = data.includePS;
          this.includeCS = data.includeCS;
          this.includeIBS = data.includeIBS;
          this.includeOES = data.includeOES;

          await this.calculateTabsInRespondentModal();
          await this.setActivatedtabInRespondentModal();

          this.isDisplayData = true;
        })
        .catch((e) => {
          this.isDisplayData = true;
          this.isFetchingDataError = true;
        });
    },
    calculateTabsInRespondentModal() {
      // usual Tab order in Respondent Modal
      // OES, TS, IBS, PS, CS

      this.allTabsInRespondentModal = [];
      if (this.includeOES) {
        this.allTabsInRespondentModal.push("OES");
      }
      if (this.includeTS) {
        this.allTabsInRespondentModal.push("TS");
      }
      if (this.includeIBS) {
        this.allTabsInRespondentModal.push("IBS");
      }
      if (this.includePS) {
        this.allTabsInRespondentModal.push("PS");
      }
      if (this.includeCS) {
        this.allTabsInRespondentModal.push("CS");
      }
    },
    setActivatedtabInRespondentModal() {
      if (this.qualityScore === ALGORITHM_TYPES.OES) {
        const OESTabIndex = this.allTabsInRespondentModal.findIndex(
          (type) => type === ALGORITHM_TYPES.OES
        );
        this.activatedTabForRespondentModal = OESTabIndex + 1; // +1 is for the overview page
      } else if (this.qualityScore === ALGORITHM_TYPES.TS) {
        const TSTabIndex = this.allTabsInRespondentModal.findIndex(
          (type) => type === ALGORITHM_TYPES.TS
        );
        this.activatedTabForRespondentModal = TSTabIndex + 1; // +1 is for the overview page
      } else if (this.qualityScore === ALGORITHM_TYPES.IBS) {
        const IBSTabIndex = this.allTabsInRespondentModal.findIndex(
          (type) => type === ALGORITHM_TYPES.IBS
        );
        this.activatedTabForRespondentModal = IBSTabIndex + 1; // +1 is for the overview page
      } else if (this.qualityScore === ALGORITHM_TYPES.PS) {
        const PSTabIndex = this.allTabsInRespondentModal.findIndex(
          (type) => type === ALGORITHM_TYPES.PS
        );
        this.activatedTabForRespondentModal = PSTabIndex + 1; // +1 is for the overview page
      } else if (this.qualityScore === ALGORITHM_TYPES.CS) {
        const CSTabIndex = this.allTabsInRespondentModal.findIndex(
          (type) => type === ALGORITHM_TYPES.CS
        );
        this.activatedTabForRespondentModal = CSTabIndex + 1; // +1 is for the overview page
      } else {
        this.activatedTabForRespondentModal = 0;
      }
    },
    async changeOESDataPointTab(dataPointIndex) {
      this.isFetchingOESData = true;
      this.selectedDataPointforGetAllDataRecordEndpoint =
        this.OESDataPointIdentifiers[dataPointIndex];
      await this.fetchTableData();
      this.isFetchingOESData = false;
      this.activatedTabForOES = dataPointIndex + 1;
    },
    async changeToOESOverviewTab() {
      this.isFetchingOESData = true;
      this.selectedDataPointforGetAllDataRecordEndpoint = null;
      await this.fetchTableData();
      this.isFetchingOESData = false;
      this.activatedTabForOES = 0;
    },
    openRespondent(event) {
      this.isDisplayRecordModal = true;
      this.respondentForRespondentModal = event.recordId;
    },
    openRespondentModal(recordId) {
      this.respondentForRespondentModal = recordId;
    },
    clickOESFeedbackScore(data, dataPointIndex) {
      this.dataForOESFeedbackModal.dataPointIndentifier =
        this.OESDataPointIdentifiers[dataPointIndex];
      this.dataForOESFeedbackModal.questionTitle =
        this.OESQuestionTitles[dataPointIndex];
      this.dataForOESFeedbackModal.dataRecord.dataRecordId = data.item.recordId;
      this.dataForOESFeedbackModal.dataRecord.answerFromRespondent =
        data.item.oesAnswer;
      this.dataForOESFeedbackModal.dataRecord.suggestedBy =
        data.item.oesFeedback;
      this.dataForOESFeedbackModal.dataRecord.comment = data.item.oesComment;
      this.dataForOESFeedbackModal.dataRecord.qualityScore =
        data.item.qualityScore;

      this.isDisplayOESFeedbackModal = true;
    },
    suggestScoreFromRespondentModal(dataRecordId) {
      setTimeout(() => {
        this.isDisplayOESFeedbackModal = false;
        // Reload data records
        let dataRecord = { recordId: dataRecordId };
        this.openRespondent(dataRecord);
      }, 500);
    },
    finalizeOESSuggestScore() {
      setTimeout(() => {
        this.isDisplayOESFeedbackModal = false;
        // Reload data records
        this.fetchTableData();
      }, 1000);
    },
    findLanguageWithCode(code) {
      return getLanaguegName(code);
    },
    findOESCategory(category) {
      return mapOESCategories(category);
    },
    initializeEventBus() {
      // EventBus
      EventBus.$on("updateQualityWidgets", async () => {
        await this.fetchTableData(false);
      });
    }
  }
};
</script>

<template>
  <div
    class="card"
    :style="[
      this.isCleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
    ]"
  >
    <div class="card-body" v-if="isDisplayData">
      <!-- Table Title -->
      <div class="row">
        <div class="col-md-6">
          <h4 class="card-title mb-2">
            {{ $t("datafileResultCleanedTab.dataRecordTable.title") }}
          </h4>
          <span>
            {{ $t("datafileResultCleanedTab.dataRecordTable.description") }}
          </span>
        </div>
      </div>

      <!-- Data Record Table Not For OES --->
      <div class="card-text mt-3" v-if="this.qualityScore !== 'OES'">
        <!-- Search -->
        <div class="row mt-4 mb-4">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-1">
                {{ $t("datafileResultCleanedTab.dataRecordTable.show") }}
              </div>
              <div class="col-md-2">
                <b-form-select
                  class=""
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select>
              </div>
              <div class="col-md-2">
                {{ $t("datafileResultCleanedTab.dataRecordTable.dataRecords") }}
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="
                    this.$t(
                      'datafileResultCleanedTab.dataRecordTable.searchPlaceholder'
                    )
                  "
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
        </div>

        <!-- Data Record Table -->
        <div class="table-responsive mb-0">
          <b-table
            :items="allDatarecords"
            :fields="fieldsForTable"
            :filter="filter"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @row-clicked="openRespondent($event)"
          >
            <template v-slot:cell(rank)="data">
              <span class="ml-2"> {{ data.value }} </span>
            </template>
            <template v-slot:cell(recordId)="data">
              <v-gravatar
                :email="data.value"
                class="rounded-circle"
                :size="vGravatarSize"
              />
              <span class="ml-2"> {{ data.value }} </span>
            </template>
            <template v-slot:cell(qualityScore)="data">
              <span v-if="data.value === -999">
                {{ $t("datafileResultGeneral.dataRecordTable.notAvailable") }}
              </span>
              <span v-else>
                {{
                  data.value.toLocaleString("de-DE", {
                    maximumFractionDigits: 2
                  })
                }}
              </span>
            </template>
            <template v-slot:cell(group)="data">
              <span v-if="data.value === -999">
                {{ $t("datafileResultGeneral.dataRecordTable.notAvailable") }}
              </span>
              <span v-else>
                {{
                  data.value.toLocaleString("de-DE", {
                    maximumFractionDigits: 2
                  })
                }}
              </span>
            </template>
            <template v-slot:cell(validDatapoints)="data">
              <span v-if="data.value === -999">
                {{ $t("datafileResultGeneral.dataRecordTable.notAvailable") }}
              </span>
              <span v-else>
                {{
                  data.value.toLocaleString("de-DE", {
                    maximumFractionDigits: 2
                  })
                }}
              </span>
            </template>
            <template v-slot:cell(trafficLight)="data">
              <i
                class="bx bxs-circle text-primary"
                style="font-size: 20px"
                :class="{
                  'text-success': `${data.value}` === 'Green',
                  'text-average': `${data.value}` === 'LightGreen',
                  'text-danger': `${data.value}` === 'Red',
                  'text-warning': `${data.value}` === 'Yellow',
                  'text-mid': `${data.value}` === 'Grey'
                }"
              ></i>
            </template>
            <template v-slot:cell(action)="data">
              <b-button
                v-if="data.item.qualityScore !== -999"
                variant="warning"
                @click="
                  clickSuggestScore(
                    data.item.recordId,
                    data.item.oesAnswer,
                    data.item.oesFeedback,
                    data.item.oesComment,
                    data.item.qualityScore
                  )
                "
                size="sm"
                ><i class="bx bxs-pencil"></i>
              </b-button>
            </template>
            <template v-slot:cell(oesSwearword)="data">
              <i
                v-if="data.value == 'true'"
                style="font-size: 20px"
                :class="{
                  'bx bxs-flag text-danger': `${data.value}` == 'true'
                }"
              ></i>
              <i v-else style="font-size: 12px" class="bx bx-minus"></i>
            </template>
          </b-table>
        </div>

        <!-- pagination -->
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Data Record Table For OES --->
      <div class="card-text mt-3" v-if="this.qualityScore === 'OES'">
        <b-tabs v-model="activatedTabForOES">
          <b-tab
            v-if="this.OESDataPointIdentifiers.length > 1"
            @click="changeToOESOverviewTab()"
          >
            <template v-slot:title
              ><span class="d-none d-sm-inline-block">
                {{ $t("datafileResultCleanedTab.tabs.overview") }}
              </span></template
            >
            <div class="p-3">
              <!-- Search -->
              <div class="row mt-4 mb-4">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-1">
                      {{ $t("datafileResultCleanedTab.dataRecordTable.show") }}
                    </div>
                    <div class="col-md-2">
                      <b-form-select
                        class=""
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select>
                    </div>
                    <div class="col-md-2">
                      {{
                        $t(
                          "datafileResultCleanedTab.dataRecordTable.dataRecords"
                        )
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <label class="d-inline-flex align-items-center">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        :placeholder="
                          this.$t(
                            'datafileResultCleanedTab.dataRecordTable.searchPlaceholder'
                          )
                        "
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
              </div>

              <!-- Show All Data Records -->
              <div class="row mb-4" v-if="isCleaned">
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="isShowExcludedDataRecordsAsWell"
                    name="check-button"
                    switch
                    class="mt-2"
                  >
                    {{
                      $t("datafileResultOESTab.dataRecordTable.showAllRecords")
                    }}
                  </b-form-checkbox>
                </div>
              </div>

              <!-- Data Record Table -->
              <div class="table-responsive mb-0">
                <b-table
                  :items="allDatarecords"
                  :fields="fieldsForTable"
                  :filter="filter"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  @row-clicked="openRespondent($event)"
                >
                  <template v-slot:cell(rank)="data">
                    <span class="ml-2"> {{ data.value }} </span>
                  </template>
                  <template v-slot:cell(recordId)="data">
                    <v-gravatar
                      :email="data.value"
                      class="rounded-circle"
                      :size="vGravatarSize"
                    />
                    <span class="ml-2"> {{ data.value }} </span>
                  </template>
                  <template v-slot:cell(qualityScore)="data">
                    <span v-if="data.value === -999">
                      {{
                        $t("datafileResultGeneral.dataRecordTable.notAvailable")
                      }}
                    </span>
                    <span v-else>
                      {{
                        data.value.toLocaleString("de-DE", {
                          maximumFractionDigits: 2
                        })
                      }}
                    </span>
                  </template>
                  <template v-slot:cell(group)="data">
                    <span v-if="data.value === -999">
                      {{
                        $t("datafileResultGeneral.dataRecordTable.notAvailable")
                      }}
                    </span>
                    <span v-else>
                      {{
                        data.value.toLocaleString("de-DE", {
                          maximumFractionDigits: 2
                        })
                      }}
                    </span>
                  </template>
                  <template v-slot:cell(validDatapoints)="data">
                    <span v-if="data.value === -999">
                      {{
                        $t("datafileResultGeneral.dataRecordTable.notAvailable")
                      }}
                    </span>
                    <span v-else>
                      {{
                        data.value.toLocaleString("de-DE", {
                          maximumFractionDigits: 2
                        })
                      }}
                    </span>
                  </template>
                  <template v-slot:cell(trafficLight)="data">
                    <i
                      class="bx bxs-circle text-primary"
                      style="font-size: 20px"
                      :class="{
                        'text-success': `${data.value}` === 'Green',
                        'text-average': `${data.value}` === 'LightGreen',
                        'text-danger': `${data.value}` === 'Red',
                        'text-warning': `${data.value}` === 'Yellow',
                        'text-mid': `${data.value}` === 'Grey'
                      }"
                    ></i>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                      v-if="data.item.qualityScore !== -999"
                      variant="warning"
                      @click="
                        clickSuggestScore(
                          data.item.recordId,
                          data.item.oesAnswer,
                          data.item.oesFeedback,
                          data.item.oesComment,
                          data.item.qualityScore
                        )
                      "
                      size="sm"
                      ><i class="bx bxs-pencil"></i>
                    </b-button>
                  </template>
                  <template v-slot:cell(oesSwearword)="data">
                    <i
                      v-if="data.value == 'true'"
                      style="font-size: 20px"
                      :class="{
                        'bx bxs-flag text-danger': `${data.value}` == 'true'
                      }"
                    ></i>
                    <i v-else style="font-size: 12px" class="bx bx-minus"></i>
                  </template>
                  <template v-slot:cell(oesAnswerCategory)="data">
                    <span
                      class="badge badge-silver font-size-12"
                      :class="{
                        'oes-answer-cat-va': `${data.value}` == 1,
                        'oes-answer-cat-ga': `${data.value}` == 4,
                        'oes-answer-cat-pr': `${data.value}` == 6,
                        'oes-answer-cat-no': `${data.value}` == 7,
                        'oes-answer-cat-dr': `${data.value}` == 8,
                        'oes-answer-cat-da': `${data.value}` == 9,
                        'oes-answer-cat-wt': `${data.value}` == 10,
                        'oes-answer-cat-wl': `${data.value}` == 11,
                        'oes-answer-cat-cp': `${data.value}` == 12,
                        'oes-answer-cat-ai-error': `${data.value}` == -2
                      }"
                    >
                      {{ findOESCategory(data.value) }}</span
                    >
                  </template>
                </b-table>
              </div>

              <!-- pagination -->
              <div class="row">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab
            v-for="(dataPoint, dataPointIndex) in this.OESDataPointIdentifiers"
            :key="dataPointIndex"
            @click="changeOESDataPointTab(dataPointIndex)"
          >
            <template v-slot:title>
              <span class="d-none d-sm-inline-block text-limt">{{
                OESDataPointIdentifiers[dataPointIndex]
              }}</span>
            </template>
            <div class="p-3">
              <!-- loading until Table data get Fetch -->
              <div class="row justify-content-center" v-if="isFetchingOESData">
                <div class="col-6 text-center">
                  <b-spinner
                    style="width: 5rem; height: 5rem"
                    class="m-2"
                    variant="mid"
                    role="status"
                  ></b-spinner>
                </div>
              </div>
              <!-- Data Record Table After Fetching Data -->
              <div v-if="!isFetchingOESData">
                <!-- Question Title -->
                <div class="row mb-3">
                  <div class="col-md-12">
                    <strong
                      >{{
                        $t(
                          "datafileResultOESTab.dataRecordTable.oesQuestionTitle"
                        )
                      }}
                    </strong>
                    {{ OESQuestionTitles[dataPointIndex] }}
                  </div>
                </div>
                <!-- Question Keywords -->
                <div class="row mb-3" v-if="OESDataPoints[dataPointIndex].keywords && OESDataPoints[dataPointIndex].keywords.length > 0">
                  <div class="col-md-12">
                    <strong
                      >{{
                        $t(
                          "datafileResultOESTab.dataRecordTable.oesQuestionKeywords"
                        )
                      }}
                    </strong>
                    <span
                      v-for="(keyword, keywordIndex) in OESDataPoints[dataPointIndex]
                        .keywords"
                      :key="keywordIndex"
                    >
                      <b-badge
                        style="font-size: 12px"
                        variant="success"
                        class="mr-2 mb-1"
                      >
                        {{keyword}}
                      </b-badge>
                    </span>
                  </div>
                </div>
                <!-- Expected Lanaguegs-->
                <div class="row mb-2" v-if="OESDataPoints[dataPointIndex].languagesToCheck && OESDataPoints[dataPointIndex].languagesToCheck.length > 0">
                  <div class="col-md-12">
                    <strong
                      >{{
                        $t(
                          "datafileResultOESTab.dataRecordTable.oesQuestionLanguagesToCheck"
                        )
                      }}
                    </strong>
                    <span
                      v-for="(lan, langIndex) in OESDataPoints[dataPointIndex]
                        .languagesToCheck"
                      :key="langIndex"
                    >
                      <b-badge
                        style="font-size: 12px"
                        variant="primary"
                        class="mr-2 mb-1"
                      >
                        {{ lan }} - {{ findLanguageWithCode(lan) }}
                      </b-badge>
                    </span>
                    <span
                      v-if="
                        OESDataPoints[dataPointIndex].languagesToCheck
                          .length === 0
                      "
                    >
                      -
                    </span>
                  </div>
                </div>

                <!-- Search -->
                <div class="row mt-4 mb-4">
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-1">
                        {{
                          $t("datafileResultCleanedTab.dataRecordTable.show")
                        }}
                      </div>
                      <div class="col-md-2">
                        <b-form-select
                          class=""
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select>
                      </div>
                      <div class="col-md-2">
                        {{
                          $t(
                            "datafileResultCleanedTab.dataRecordTable.dataRecords"
                          )
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        <b-form-input
                          v-model="filter"
                          type="search"
                          :placeholder="
                            $t(
                              'datafileResultCleanedTab.dataRecordTable.searchPlaceholder'
                            )
                          "
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                </div>

                <!-- Show All Data Records -->
                <div class="row mb-4" v-if="isCleaned">
                  <div class="col-md-12">
                    <b-form-checkbox
                      v-model="isShowExcludedDataRecordsAsWell"
                      name="check-button"
                      switch
                      class="mt-2"
                    >
                      {{
                        $t(
                          "datafileResultOESTab.dataRecordTable.showAllRecords"
                        )
                      }}
                    </b-form-checkbox>
                  </div>
                </div>

                <!-- Data Record Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    :items="allDatarecords"
                    :fields="fieldsForTable"
                    :filter="filter"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    @row-clicked="openRespondent($event)"
                  >
                    <template v-slot:cell(rank)="data">
                      <span class="ml-2"> {{ data.value }} </span>
                    </template>
                    <template v-slot:cell(recordId)="data">
                      <v-gravatar
                        :email="data.value"
                        class="rounded-circle"
                        :size="vGravatarSize"
                      />
                      <span class="ml-2"> {{ data.value }} </span>
                    </template>
                    <template v-slot:cell(qualityScore)="data">
                      <span v-if="data.value === -999">
                        {{
                          $t(
                            "datafileResultGeneral.dataRecordTable.notAvailable"
                          )
                        }}
                      </span>
                      <span v-else>
                        {{
                          data.value.toLocaleString("de-DE", {
                            maximumFractionDigits: 2
                          })
                        }}
                      </span>
                    </template>
                    <template v-slot:cell(group)="data">
                      <span v-if="data.value === -999">
                        {{
                          $t(
                            "datafileResultGeneral.dataRecordTable.notAvailable"
                          )
                        }}
                      </span>
                      <span v-else>
                        {{
                          data.value.toLocaleString("de-DE", {
                            maximumFractionDigits: 2
                          })
                        }}
                      </span>
                    </template>
                    <template v-slot:cell(validDatapoints)="data">
                      <span v-if="data.value === -999">
                        {{
                          $t(
                            "datafileResultGeneral.dataRecordTable.notAvailable"
                          )
                        }}
                      </span>
                      <span v-else>
                        {{
                          data.value.toLocaleString("de-DE", {
                            maximumFractionDigits: 2
                          })
                        }}
                      </span>
                    </template>
                    <template v-slot:cell(trafficLight)="data">
                      <i
                        class="bx bxs-circle text-primary"
                        style="font-size: 20px"
                        :class="{
                          'text-success': `${data.value}` === 'Green',
                          'text-average': `${data.value}` === 'LightGreen',
                          'text-danger': `${data.value}` === 'Red',
                          'text-warning': `${data.value}` === 'Yellow',
                          'text-mid': `${data.value}` === 'Grey'
                        }"
                      ></i>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button
                        v-if="data.item.qualityScore !== -999"
                        variant="warning"
                        @click="clickOESFeedbackScore(data, dataPointIndex)"
                        size="sm"
                        ><i class="bx bxs-pencil"></i>
                      </b-button>
                    </template>
                    <template v-slot:cell(oesSwearword)="data">
                      <i
                        v-if="data.value == 'true'"
                        style="font-size: 20px"
                        :class="{
                          'bx bxs-flag text-danger': `${data.value}` == 'true'
                        }"
                      ></i>
                      <i v-else style="font-size: 12px" class="bx bx-minus"></i>
                    </template>
                    <template v-slot:cell(oesAnswerCategory)="data">
                      <span
                        class="badge badge-silver font-size-12"
                        :class="{
                          'oes-answer-cat-va': `${data.value}` == 1,
                          'oes-answer-cat-ga': `${data.value}` == 4,
                          'oes-answer-cat-ni': `${data.value}` == 5,
                          'oes-answer-cat-pr': `${data.value}` == 6,
                          'oes-answer-cat-no': `${data.value}` == 7,
                          'oes-answer-cat-dr': `${data.value}` == 8,
                          'oes-answer-cat-da': `${data.value}` == 9,
                          'oes-answer-cat-wt': `${data.value}` == 10,
                          'oes-answer-cat-wl': `${data.value}` == 11,
                          'oes-answer-cat-cp': `${data.value}` == 12,
                          'oes-answer-cat-ar': `${data.value}` == 13,
                          'oes-answer-cat-ai-error': `${data.value}` == -2
                        }"
                      >
                        {{ findOESCategory(data.value) }}</span
                      >
                    </template>
                  </b-table>
                </div>

                <!-- pagination -->
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div
      class="card-body"
      v-else
      style="min-height: 1000px; padding-top: 100px"
    >
      <div class="row justify-content-center">
        <div class="col-6 text-center">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>

    <!-- Data Record Modal / Respondent Modal -->
    <DatarecordModal
      v-if="isDisplayRecordModal"
      :dataRecord="respondentForRespondentModal"
      :datafile="datafileId"
      :activatedTab="activatedTabForRespondentModal"
      :visible="isDisplayRecordModal"
      @close="isDisplayRecordModal = false"
      :includeTimestamp="includeTS"
      :includeIBS="includeIBS"
      :includeCS="includeCS"
      :includePS="includePS"
      :includeOES="includeOES"
    />

    <!-- OES Feedback Modal -->
    <OESFeedbackModal
      v-if="isDisplayOESFeedbackModal"
      :visible="isDisplayOESFeedbackModal"
      @close="isDisplayOESFeedbackModal = false"
      @suggestAndClose="finalizeOESSuggestScore"
      @suggestScoreFromRespondent="suggestScoreFromRespondentModal"
      :datafileId="datafileId"
      :dataPointIndentifier="dataForOESFeedbackModal.dataPointIndentifier"
      :questionTitle="dataForOESFeedbackModal.questionTitle"
      :selectedDataRecord="dataForOESFeedbackModal.dataRecord"
      :openFromRespondentModal="isOpenOESFeedbackModalFromRespondentModal"
    />
  </div>
</template>

<style scoped>
.text-limt {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
}
</style>
