var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        staticClass: "col-md-12",
        staticStyle: {
          display: "flex",
          "text-align": "center",
          color: "#adb5bd",
          height: "250px",
          "justify-content": "center",
          "align-items": "center",
        },
      },
      [
        _c(
          "div",
          { staticStyle: { "text-align": "center", color: "#adb5bd" } },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("general.info.noValidScoresAvailable")) + " "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }