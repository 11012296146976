<script>
import { EventBus } from "@/shared/util/event-bus.js";
import DatafileResultService from "../../../../../api/services/datafileResult.service";
import VersionCompatibleError from "../../../../../shared/components/widgets/version-compatible-error.vue";
import { OESCategoryChart } from "../../../result-charts";

export default {
  props: {
    datafileId: String,
    isCleaned: Boolean
  },
  data() {
    return {
      oesCategoriesChartOptions: null,
      oesCategoryChartData: [],
      showCategoryChart: false,
      isCategoriesAreNotAvailable: false
    };
  },
  components: {
    VersionCompatibleError
  },
  created() {
    this.oesCategoriesChartOptions = new OESCategoryChart();
    this.initializeEventBus();
  },
  async mounted() {
    await this.setNumberOfCategoriesData();
  },
  methods: {
    async setNumberOfCategoriesData() {
      const oesCategories = [];

      // fetch count that oes has used
      const response = await DatafileResultService.getOESPieChartData(
        this.datafileId,
        this.isCleaned
      );

      if (response === "CATEGORIES_NOT_AVAILABLE") {
        this.showCategoryChart = true;
        this.isCategoriesAreNotAvailable = true;
      } else {
        // console.log("response.categories: ", response.categories);
        // console.log("response.numOfTotalAnswers: ", response.numOfTotalAnswers);

        //this is same as you dont have any valid data (included)
        if (response.numOfTotalAnswers === 0) {
          this.showCategoryChart = false;
          this.isCategoriesAreNotAvailable = true;
        } else {
          for (const categoryValue of Object.values(response.categories)) {
            oesCategories.push(
              (Math.round(categoryValue) / response.numOfTotalAnswers) * 100
            );
          }
        }
        this.oesCategoryChartData = oesCategories;
        // console.log("oesCategoryChartData: ", this.oesCategoryChartData);
        this.showCategoryChart = true;
        this.oesCategoriesChartOptions.chartOptions.labels = Object.keys(
          response.categories
        );
      }

      this.$emit("dataFetchingDone");
    },
    initializeEventBus() {
      // EventBus
      EventBus.$on("updateQualityWidgets", async () => {
        await this.setNumberOfCategoriesData();
      });
    }
  }
};
</script>
<template>
  <div
    class="card"
    :style="[
      this.isCleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
    ]"
  >
    <div class="card-body" v-if="showCategoryChart">
      <div class="card-title">
        {{ $t("datafileResultOESTab.categoryWidget.title") }}
        <!-- <span>
          <b-badge variant="info" class="ml-2"> BETA </b-badge>
        </span> -->
      </div>
      <div class="card-text">
        {{ $t("datafileResultOESTab.categoryWidget.description") }}
      </div>
      <div class="card-text mt-5" v-if="!this.isCategoriesAreNotAvailable">
        <apexchart
          ref="OESCategoryChart"
          class="apex-charts mt-2"
          height="300"
          type="pie"
          dir="ltr"
          :series="oesCategoryChartData"
          :options="oesCategoriesChartOptions.chartOptions"
        ></apexchart>
      </div>
      <div
        style="height: 300px"
        class="card-text mt-5"
        v-if="this.isCategoriesAreNotAvailable"
      >
        <VersionCompatibleError />
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div class="card-body" v-else>
      <div class="row justify-content-center">
        <div class="col-6 text-center pt-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>
