<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import DatafileResultService from "@/api/services/datafileResult.service.js";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";
import { EventBus } from "@/shared/util/event-bus.js";
import IBSInfoModal from "./IBS-info-modal.vue";
import GQSDataPointsInfoModal from "./GQS-datapoints-details-modal.vue";

export default {
  props: {
    datafileId: String,
    liveDatafile: String
  },
  components: {
    IBSInfoModal,
    DataFetchingErrorWidget,
    GQSDataPointsInfoModal
  },
  data() {
    return {
      /**** Data Varaibles ****/
      metadata: null,
      GQSDataPointsInfo: null,
      numberOfGQSDataPointsWithPatternCheck: 0,
      /**** Operationsal Varaibles ****/
      isDisplayGQSDataPointsInforModal: false,
      isDisplayData: false,
      isFetchingDataError: false,
      isDisplayInfoModal: false,
      noValidRecordError: false
    };
  },
  async created() {
    await this.fetchMetaData();

    // Fetch GQS Data Point information
    await this.fetchGQSDataPointsInfo()

    this.$emit("dataFetchingDone");
    this.initializeEventBus();
  },
  methods: {
    async fetchMetaData() {
      await DatafileResultService.getIBSMetadata(this.datafileId, true)
        .then((data) => {
          this.metadata = data;
          this.isDisplayData = true;

          if (data.numberOfValidRecords == 0) {
            this.noValidRecordError = true;
          }
        })
        .catch((e) => {
          this.isFetchingDataError = true;
          this.isDisplayData = true;
        });
    },
    clickQualityScoreInfoModal() {
      this.isDisplayInfoModal = true;
    },
    initializeEventBus() {
      EventBus.$on("updateQualityWidgets", async () => {
        await this.fetchMetaData();
      });
    },
    async fetchGQSDataPointsInfo(reload = true) {
      // enable Loading
      if (reload) this.isDisplayData = false;

      await DatafileResultService.getGQSDataPointsInfo(
        this.datafileId,
      )
        .then(data => {
          this.GQSDataPointsInfo = data
          this.GQSDataPointsInfo.forEach(datapoint => {
            if(datapoint.enablePatternCheck){ 
              this.numberOfGQSDataPointsWithPatternCheck += 1
            }
          });

          if(this.liveDatafile) this.numberOfGQSDataPointsWithPatternCheck = this.GQSDataPointsInfo.length
          this.isDisplayData = true;
        })
        .catch(e => {
          this.isFetchingDataError = true;
          this.isDisplayData = true;
        });
    },
    clickDetailsOfGQSPatternCheck(){
      this.isDisplayGQSDataPointsInforModal = true;
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body" v-if="isDisplayData">
      <div class="card-title">
        Grid-Question-Score
        <i
          class="bx bxs-info-circle font-size-18 text-info bx-tada ml-2"
          style="cursor: pointer"
          @click="clickQualityScoreInfoModal()"
        ></i>
      </div>

      <!-- Valid score not Avaiable Msg -->
      <div class="row" v-if="this.noValidRecordError">
        <div class="col-md-12">
          <b-alert show variant="info">
            <i class="mdi mdi-alert-circle-outline mr-2"></i>
            {{
              $t("datafileResultIBSTab.metadataWidget.noValidRecordsError")
            }}</b-alert
          >
        </div>
      </div>

      <b-row v-if="!this.isFetchingDataError">
        <!-- Number of Item Batteries -->
        <b-col sm="4" class="mb-3">
          <div>
            {{
              $t("datafileResultIBSTab.metadataWidget.numberOfItemBatteries")
            }}
          </div>
          <div>
            <strong>{{ metadata.numberOfItemBatteries }}</strong>
          </div>
        </b-col>

        <!-- Pattern Check Avaialablity -->
        <b-col sm="4" class="mb-3">
          <div>
            {{ $t("uploadProcessStep3.summaryCard.columnDescription.IBS.patternCheckActivated") }}
          </div>
          <div>
            <strong> {{ numberOfGQSDataPointsWithPatternCheck }}/{{ GQSDataPointsInfo.length }} </strong>
            <i
              v-if="!liveDatafile"
              class="bx bxs-info-circle text-info bx-tada"
              style="cursor: pointer"
              @click="clickDetailsOfGQSPatternCheck()"
            ></i>
          </div>
        </b-col>

        <!-- Number of Items -->
        <b-col sm="4" class="mb-3">
          <div>
            {{ $t("datafileResultIBSTab.metadataWidget.numberOfItems") }}
          </div>
          <div>
            <strong>{{ metadata.numberOfItems }}</strong>
          </div>
        </b-col>

        <!-- All Data Records -->
        <b-col sm="4" class="mb-3">
          <div>
            {{ $t("datafileResultIBSTab.metadataWidget.numberOfDataRecords") }}
          </div>
          <div>
            <strong>{{ metadata.numberOfRecords }}</strong>
          </div>
        </b-col>

        <!-- Data Records without Scores -->
        <b-col sm="4" class="mb-3">
          <div>
            {{
              $t("datafileResultIBSTab.metadataWidget.numberOfInvalidDataRecords")
            }}
          </div>
          <div>
            <strong>{{ metadata.numberOfInvalidRecords }}</strong>
          </div>
        </b-col>
      </b-row>

      <div class="card-text mt-3" v-if="this.isFetchingDataError">
        <DataFetchingErrorWidget />
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div class="card-body" v-else>
      <div class="row justify-content-center">
        <div class="col-6 text-center">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>

    <!-- Info Modal -->
    <IBSInfoModal
      v-if="isDisplayInfoModal"
      :visible="isDisplayInfoModal"
      @close="isDisplayInfoModal = false"
    />

    <!-- GQS Datapoint Info Modal -->
    <GQSDataPointsInfoModal
      v-if="isDisplayGQSDataPointsInforModal"
      :visible="isDisplayGQSDataPointsInforModal"
      @close="isDisplayGQSDataPointsInforModal = false"
      :GQSDataPoints="GQSDataPointsInfo"
    />
  </div>
</template>
