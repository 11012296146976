var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card",
      style: [
        this.isCleaned ? { background: "#FFFFFF" } : { background: "#FFF5F4" },
      ],
    },
    [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "card-title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("datafileResultCSTab.datapointWidget.title")) +
              " "
          ),
        ]),
        _c("div", { staticClass: "card-text" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("datafileResultCSTab.datapointWidget.description")
              ) +
              " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "card-text mt-3" },
          [
            _c(
              "b-tabs",
              _vm._l(
                this.averagePredictionChartData,
                function (averagePrediction, averagePredictionIndex) {
                  return _c(
                    "b-tab",
                    {
                      key: averagePredictionIndex,
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "d-none d-sm-inline-block text-limt",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.averagePredictionData[
                                          averagePredictionIndex
                                        ].datapointIdentifier
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "p-3" },
                        [
                          _c("apexchart", {
                            ref: "performanceChart",
                            refInFor: true,
                            staticClass: "apex-charts mt-5",
                            attrs: {
                              dir: "ltr",
                              type: "bar",
                              height: "300",
                              series: averagePrediction,
                              options:
                                _vm.averagePredictionChartOptions[
                                  averagePredictionIndex
                                ].chartOptions,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }