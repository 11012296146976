var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: {
          title: this.$t("datafileResultGeneral.pageTitle"),
          items: _vm.computedItems,
        },
      }),
      _c("div", { staticClass: "row justify-content-between" }, [
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _vm.isDisplayRawResults
              ? _c(
                  "b-button",
                  {
                    staticClass: "mr-2",
                    attrs: { variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.clickOnRaw()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "d-inline-block d-sm-none" }, [
                      _c("i", { staticClass: "fas fa-poll-h" }),
                    ]),
                    _c("span", { staticClass: "d-none d-sm-inline-block" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("datafileResultGeneral.buttonRow.rawButton")
                        )
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            !_vm.isDisplayRawResults
              ? _c(
                  "b-button",
                  {
                    staticClass: "mr-2",
                    attrs: { variant: "light" },
                    on: {
                      click: function ($event) {
                        return _vm.clickOnRaw()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "d-inline-block d-sm-none" }, [
                      _c("i", { staticClass: "fas fa-poll-h" }),
                    ]),
                    _c("span", { staticClass: "d-none d-sm-inline-block" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("datafileResultGeneral.buttonRow.rawButton")
                        )
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.isDisplayCleanedResults
              ? _c(
                  "b-button",
                  {
                    attrs: { variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.clickOnCleaned()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "d-inline-block d-sm-none" }, [
                      _c("i", { staticClass: "far fa-question-circle" }),
                    ]),
                    _c("span", { staticClass: "d-none d-sm-inline-block" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "datafileResultGeneral.buttonRow.cleanedButton"
                          )
                        )
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            !_vm.isDisplayCleanedResults
              ? _c(
                  "b-button",
                  {
                    attrs: { variant: "light" },
                    on: {
                      click: function ($event) {
                        return _vm.clickOnCleaned()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "d-inline-block d-sm-none" }, [
                      _c("i", { staticClass: "far fa-question-circle" }),
                    ]),
                    _c("span", { staticClass: "d-none d-sm-inline-block" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "datafileResultGeneral.buttonRow.cleanedButton"
                          )
                        )
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4 text-center" },
          [
            _c(
              "b-alert",
              {
                staticClass: "col-md-12",
                attrs: {
                  show: _vm.showSecretKeyCopySuccessAlert,
                  variant: "success",
                },
              },
              [_vm._v(_vm._s(_vm.$t("datafileResultGeneral.buttonRow.copied")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-5 text-right" },
          [
            this.isLiveCleaning
              ? _c(
                  "span",
                  {
                    staticClass: "text-primary mr-3 font-weight-medium",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.clickChangeDatafileStatus()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "bx bx-repost" }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultGeneral.buttonRow.changeDatafileStatus"
                          )
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            this.secretKeyCopySuccess
              ? _c(
                  "span",
                  [
                    _c(
                      "b-badge",
                      {
                        staticClass: "mr-2",
                        staticStyle: { "font-size": "12px" },
                        attrs: { variant: "success" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("datafileResultGeneral.buttonRow.copied")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            this.isLiveCleaning
              ? _c(
                  "span",
                  {
                    staticClass: "text-primary mr-3 font-weight-medium",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.copySecretKey()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "bx bx-copy" }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultGeneral.buttonRow.copySecretKey"
                          )
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "span",
              {
                staticClass: "text-primary mr-3 font-weight-medium",
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.clickExportWorksheet()
                  },
                },
              },
              [
                _c("i", { staticClass: "bx bx-export" }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("datafileResultGeneral.buttonRow.exportButton")
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "span",
              {
                staticClass: "text-primary mr-3 font-weight-medium",
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.clickCleanData()
                  },
                },
              },
              [
                _c("i", { staticClass: "bx bx-brush" }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "datafileResultGeneral.buttonRow.cleaningAndMarkButton"
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "b-dropdown",
              {
                attrs: {
                  "toggle-class": "nav-btn",
                  right: "",
                  variant: "light",
                },
                scopedSlots: _vm._u([
                  {
                    key: "button-content",
                    fn: function () {
                      return [
                        _c("i", {
                          staticClass:
                            "bx bx-dots-horizontal-rounded font-size-20",
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                !this.isLiveCleaning &&
                _vm.loggedInUser.company.contractStatus === "RUNNING"
                  ? _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.clickUpdateDatafile()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bx bx-reset mr-2" }),
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "datafileResultGeneral.buttonRow.updateDatafile"
                            )
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
                !this.isLiveCleaning
                  ? _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.clickQuotaSettings()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bx bxs-pie-chart-alt-2 mr-2" }),
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "datafileResultGeneral.buttonRow.quotaSettings"
                            )
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
                !this.isLiveCleaning || this.isLiveCleaning === "STOPPED"
                  ? _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.clickWeightingData()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bx bx-slider mr-2" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafileResultGeneral.buttonRow.weightingButton"
                              )
                            )
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.clickManageReport()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "bx bxs-cog mr-2" }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("datafileResultGeneral.buttonRow.manageReport")
                        )
                    ),
                  ]
                ),
                this.isLiveCleaning && this.integration
                  ? _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.clickViewInstructions()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bx bx-show mr-2" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafileResultGeneral.buttonRow.showIntegrationInstructions"
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.clickManageCollaborators()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "bx bxs-group mr-2" }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultGeneral.buttonRow.manageContributors"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("b-dropdown-divider"),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.clickWorkSheet()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "bx bx-spreadsheet mr-2" }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultGeneral.buttonRow.worksheetButton"
                          )
                        )
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 mt-4" },
          [
            _vm.isDisplayRawResults ? _c("RawResults") : _vm._e(),
            _vm.isDisplayCleanedResults ? _c("CleanedResults") : _vm._e(),
          ],
          1
        ),
      ]),
      this.isDisplayCleanDataModal
        ? _c("CleanDataModal", {
            attrs: {
              visible:
                (!this.isLiveCleaning || this.isLiveCleaning === "STOPPED") &&
                this.isDisplayCleanDataModal,
              datafileId: this.datafileId,
            },
            on: {
              close: _vm.closeCleanDataModal,
              reload: function ($event) {
                return _vm.reloadPage()
              },
              commitCleanData: _vm.commitCleanData,
            },
          })
        : _vm._e(),
      this.isDisplayCleanDataModal
        ? _c("EditCleaningSettingsModal", {
            attrs: {
              visible:
                this.isLiveCleaning === "RUNNING" &&
                this.isDisplayCleanDataModal,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayCleanDataModal = false
              },
            },
          })
        : _vm._e(),
      this.mountModals
        ? _c("WeightDataModal", {
            attrs: {
              visible: this.isDisplayWeightDataModal,
              datafileId: this.datafileId,
              weightingSettings: this.weightingSettings,
            },
            on: {
              reload: function ($event) {
                return _vm.reloadPage()
              },
              close: _vm.closeWeightDataModal,
            },
          })
        : _vm._e(),
      _c("ExportWorksheetModal", {
        attrs: {
          visible: this.isDisplayExportWorksheetModal,
          datafileId: this.datafileId,
          isLiveCleaning: this.isLiveCleaning,
        },
        on: {
          close: _vm.closeExportWorksheetModal,
          commitExportWorksheet: _vm.commitExportWorksheet,
        },
      }),
      _c("UpdateDatafileModal", {
        attrs: {
          visible: this.isDisplayUpdateDatafileModal,
          datafileId: this.datafileId,
          metadata: this.metadata,
          oldDatafileKey: this.datafileKey,
        },
        on: {
          reload: function ($event) {
            return _vm.reloadPage()
          },
          close: function ($event) {
            _vm.isDisplayUpdateDatafileModal = false
          },
        },
      }),
      this.datafileId
        ? _c("QuotaSettingsModal", {
            attrs: {
              visible: this.isDisplayQuotaSettingsModal,
              datafileId: this.datafileId,
              datafileTitle: this.datafileTitle,
              metadata: this.metadata,
            },
            on: {
              reload: function ($event) {
                return _vm.reloadPage()
              },
              close: function ($event) {
                _vm.isDisplayQuotaSettingsModal = false
              },
            },
          })
        : _vm._e(),
      _c("ManageReportModal", {
        attrs: {
          visible: this.isDisplayManageReportModal,
          datafileId: this.datafileId,
          datafileTitle: this.datafileTitle,
        },
        on: {
          close: function ($event) {
            _vm.isDisplayManageReportModal = false
          },
          reload: function ($event) {
            return _vm.reloadPage()
          },
        },
      }),
      _vm.isDisplayChangeDatafileStatusModal === true
        ? _c("ChangeDatafileStatusModal", {
            attrs: {
              visible: _vm.isDisplayChangeDatafileStatusModal,
              datafileObject: this.dbDatafile,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayChangeDatafileStatusModal = false
              },
              reload: function ($event) {
                return _vm.reloadPage()
              },
            },
          })
        : _vm._e(),
      _vm.isDisplayManageCollaboratorModal === true
        ? _c("ManageDatafileCollaboratorsModal", {
            attrs: {
              visible: _vm.isDisplayManageCollaboratorModal,
              datafileId: this.datafileId,
              datafileOwner: this.dbDatafile.owner,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayManageCollaboratorModal = false
              },
              reload: function ($event) {
                return _vm.reloadPage()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }