<script>
import DatafileResultService from "@/api/services/datafileResult.service";
import {
  DistributionChart,
  MeanChart,
  TimeScorePerformanceChart,
  TrafficLightChart
} from "@/redem/datafiles/result-charts";
import { ALGORITHM_TYPES } from "@/shared/util/const";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";
import { EventBus } from "@/shared/util/event-bus.js";

/*****
 * Prediction Quality Widget
 */
export default {
  props: {
    datafileId: String,
    datapointIdentifier: String,
    cleaned: Boolean
  },
  components: {
    DataFetchingErrorWidget
  },
  data() {
    return {
      /******* Data Variables *******/
      chartDataForMeanChart: 0,
      meanChartOptions: null,
      chartDataForTrafficLightChart: [],
      trafficLightChartOptions: null,
      chartDataForDistributionChart: null,
      distributionChartOptions: null,
      predictionScorePerformanceData: {},
      chartDataForTimeScorePerformanceChart: null,
      PredictionScorePerformanceChartOptions: null,
      /******* Operational Variables *******/
      isDisplayInfo: false,
      isDisplayData: false,
      isDisplayTrafficLightError: false,
      isDisplayDistributionError: false,
      isDisplayDataPointError: false
    };
  },
  async created() {
    this.initializeEventBus();
    // set mean chart, charts options
    this.meanChartOptions = new MeanChart();
    this.trafficLightChartOptions = new TrafficLightChart();
    this.distributionChartOptions = new DistributionChart();
    this.PredictionScorePerformanceChartOptions = new TimeScorePerformanceChart();
    await this.fetchAllData(true);
    this.isDisplayData = true;
  },
  methods: {
    async fetchAllData(initial = false) {
      const trafficLight = this.fetchTrafficLightData();
      const distribution = this.fetchDistributionData();
      const performance = this.fetchPerformanceData(initial);

      try {
        await trafficLight;
      } catch (e) {
        this.isDisplayTrafficLightError = true;
        console.log("Error fetching PS traffic light data");
      }
      try {
        await distribution;
      } catch (e) {
        this.isDisplayDistributionError = true;
        console.log("Error fetching PS distribution data");
      }
      try {
        await performance;
      } catch (e) {
        this.isDisplayDataPointError = true;
        console.log("Error fetching PS Performance data");
      }

      this.$emit("fetchingFinished", ALGORITHM_TYPES.PS);
    },
    initializeEventBus() {
      // EventBus
      EventBus.$on("updateQualityWidgets", async () => {
        await this.fetchAllData();
      });
    },
    async fetchPerformanceData(initial = false) {
      const tempData = await DatafileResultService.getQualityScorePerformanceData(
        this.datafileId,
        null,
        this.cleaned,
        ALGORITHM_TYPES.PS
      );

      this.predictionScorePerformanceData = {
        name: "Average Prediction Score",
        data: tempData
      };
      if (initial) {
        for (
          let i = 0;
          i < this.predictionScorePerformanceData.data.length;
          i++
        ) {
          this.PredictionScorePerformanceChartOptions.chartOptions.xaxis.categories.push(
            "Data Point " + (i + 1)
          );
        }
      }
    },

    async fetchTrafficLightData() {
      let res = await DatafileResultService.getTrafficLightData(
        this.datafileId,
        ALGORITHM_TYPES.PS,
        this.datapointIdentifier,
        this.cleaned
      );
      res = res.trafficLightValues;
      const trafficLightData = [
        res.Red,
        res.Yellow,
        res.LightGreen,
        res.Green,
        res.Grey
      ];
      this.chartDataForTrafficLightChart = trafficLightData;
      this.chartDataForMeanChart = Math.round(res.Average) || 0;
    },

    async fetchDistributionData() {
      const tempDistributionData = await DatafileResultService.getDistributionData(
        this.datafileId,
        ALGORITHM_TYPES.PS,
        this.datapointIdentifier,
        this.cleaned
      );
      this.chartDataForDistributionChart = [
        {
          name: "Data-Records",
          data: tempDistributionData.scoreDistribution
        }
      ];
    },

    changeToShowInfo() {
      this.isDisplayInfo = true;
    },
    changeToShowData() {
      this.isDisplayInfo = false;
    },
    rerenderTLChart() {
      console.log("Re render the traffic light chart.");
    },
    clickViewMoreButton() {
      this.$emit("viewMore", ALGORITHM_TYPES.PS);
    }
  }
};
</script>
<template>
  <div
    class="card animate__animated animate__fadeInUp animate__slow"
    style="height: 850px"
    :style="[
      this.cleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
    ]"
  >
    <div class="" v-if="isDisplayData && !isError">
      <!-- Results -->
      <div class="card-body" v-if="!isDisplayInfo">
        <b-card-title class="pb-2">
          <div class="float-right" v-if="this.cleaned">
            <b-button
              class="btn-sm"
              variant="light"
              @click="clickViewMoreButton()"
              >{{
                $t("datafileResultGeneral.qualityScoreWidget.showMoreButton")
              }}
              <i class="bx bx-right-arrow-alt ml-1"></i
            ></b-button>
          </div>
          <h4 class="card-title mb-2">
            {{ $t("qualityScoreWidgets.predictionScoreWidget.title") }}
            <i
              class="bx bxs-info-circle font-size-20 text-info bx-tada ml-1"
              style="cursor: pointer"
              @click="changeToShowInfo()"
            ></i>
          </h4>
        </b-card-title>
        <div class="row mb-2">
          <div class="col-md-12 text-center">
            <!-- Mean Chart -->
            <div v-if="!isDisplayTrafficLightError">
              <apexchart
                ref="PSMeanChart"
                class="apex-charts apexChartData"
                type="radialBar"
                height="250"
                dir="ltr"
                :series="[chartDataForMeanChart]"
                :options="meanChartOptions.chartOptions"
              ></apexchart>
            </div>
            <div v-else class="text-center">
              <DataFetchingErrorWidget />
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-1"></div>
          <div class="col-md-10 text-center">
            {{ $t("qualityScoreWidgets.predictionScoreWidget.description") }}
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row pt-4">
          <div class="col-md-12">
            <b-tabs justified content-class="p-3 text-muted">
              <!-- Distribution Chart -->
              <b-tab active lazy>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="bx bx-line-chart"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{
                    $t(
                      "qualityScoreWidgets.predictionScoreWidget.tabs.distribution"
                    )
                  }}</span>
                </template>
                <div class="mt-3">
                  <div v-if="!isDisplayDistributionError">
                    <apexchart
                      ref="PSDistributionChart"
                      class="apex-charts mt-5"
                      dir="ltr"
                      type="area"
                      height="300"
                      :series="chartDataForDistributionChart"
                      :options="distributionChartOptions.chartOptions"
                    ></apexchart>
                  </div>
                  <div v-else class="text-center">
                    <DataFetchingErrorWidget />
                  </div>
                </div>
              </b-tab>
              <!-- Traffic Light Chart -->
              <b-tab lazy>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="bx bxs-traffic"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{
                    $t(
                      "qualityScoreWidgets.predictionScoreWidget.tabs.trafficLight"
                    )
                  }}</span>
                </template>
                <div class="mt-3">
                  <div v-if="!isDisplayTrafficLightError">
                    <apexchart
                      ref="PStrafficLightChart"
                      class="apex-charts mt-5 mb-4"
                      height="300"
                      type="pie"
                      dir="ltr"
                      :series="chartDataForTrafficLightChart"
                      :options="trafficLightChartOptions.chartOptions"
                    ></apexchart>
                  </div>
                  <div v-else class="text-center">
                    <DataFetchingErrorWidget />
                  </div>
                </div>
              </b-tab>
              <!-- Performance Chart -->
              <b-tab
                lazy
                v-if="
                  this.predictionScorePerformanceData.data &&
                    this.predictionScorePerformanceData.data.length > 1
                "
              >
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="bx bxs-traffic"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{
                    $t(
                      "qualityScoreWidgets.predictionScoreWidget.tabs.datapoints"
                    )
                  }}</span>
                </template>
                <div class="mt-3">
                  <div v-if="!isDisplayDataPointError">
                    <apexchart
                      ref="performanceChart"
                      class="apex-charts mt-5"
                      dir="ltr"
                      type="bar"
                      height="300"
                      :series="[predictionScorePerformanceData]"
                      :options="
                        PredictionScorePerformanceChartOptions.chartOptions
                      "
                    ></apexchart>
                  </div>
                  <div v-else class="text-center">
                    <DataFetchingErrorWidget />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>

      <!-- Description -->
      <div class="card-body" v-else>
        <div class="row mb-3">
          <div class="col-md-10">
            <h4 class="card-title mb-3">
              {{ $t("qualityScoreWidgets.predictionScoreWidget.title") }}
            </h4>
          </div>
          <div class="col-md-2 text-right">
            <i
              class="bx bxs-x-circle font-size-20 text-info bx-tada"
              style="cursor: pointer"
              @click="changeToShowData()"
            ></i>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.predictionScoreWidget.detailedInfo.text1")
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.predictionScoreWidget.detailedInfo.text2")
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.predictionScoreWidget.detailedInfo.text3")
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.predictionScoreWidget.detailedInfo.text4")
            }}
            <u>
              {{
                $t(
                  "qualityScoreWidgets.predictionScoreWidget.detailedInfo.text5"
                )
              }} </u
            >.
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t(
                "qualityScoreWidgets.predictionScoreWidget.detailedInfo.text61"
              )
            }}
            <u>{{
              $t(
                "qualityScoreWidgets.predictionScoreWidget.detailedInfo.text62"
              )
            }}</u>
            {{
              $t(
                "qualityScoreWidgets.predictionScoreWidget.detailedInfo.text63"
              )
            }}
          </div>
          <div class="col-md-12">
            {{
              $t(
                "qualityScoreWidgets.predictionScoreWidget.detailedInfo.useCase.title"
              )
            }}
            <ul>
              <li>
                {{
                  $t(
                    "qualityScoreWidgets.predictionScoreWidget.detailedInfo.useCase.text1"
                  )
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div class="row justify-content-center" v-else>
      <div class="col-6 text-center pt-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>
