<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import DatafileResultService from "@/api/services/datafileResult.service.js";
import { MeanChart } from "@/redem/datafiles/result-charts.js";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";
import NoValidScoresAvailableWidget from "@/shared/components/widgets/no-valid-scores-available.vue";
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    datafileId: String,
    qualityScore: String,
    dataPointIdentifer: String,
    isCleaned: Boolean,
    groupSelector: String
  },
  components: {
    DataFetchingErrorWidget,
    NoValidScoresAvailableWidget
  },
  data() {
    return {
      /***** Data varaiables *****/
      scoreChartData: null,
      scoreChartOptions: null,
      /***** Operational varaiables *****/
      isDisplayData: false,
      isFetchingDataError: false
    };
  },
  watch: {
    groupSelector: function (newValue, oldValue) {
      this.fetchChartData();
    }
  },
  async created() {
    await this.fetchChartData();
    this.scoreChartOptions = new MeanChart();
    this.$emit("dataFetchingDone");
    this.initializeEventBus();
  },
  methods: {
    async fetchChartData(reload = true) {
      // enable Loading
      if (reload) this.isDisplayData = false;

      try {
        let response = await DatafileResultService.getAverageQualityScoreData(
          this.datafileId,
          String(this.qualityScore),
          this.dataPointIdentifer,
          this.isCleaned,
          this.groupSelector
        )

        if(response && response.average) this.scoreChartData = response.average

        this.isDisplayData = true;

      } catch (error) {
        this.isFetchingDataError = true;
        this.isDisplayData = true;
      }
    },
    initializeEventBus() {
      // EventBus
      EventBus.$on("updateQualityWidgets", async () => {
        await this.fetchChartData(false);
      });
    }
  }
};
</script>

<template>
  <div
    class="card"
    style="height: 480px"
    :style="[
      this.isCleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
    ]"
  >
    <div class="card-body" v-if="isDisplayData">
      <div class="card-title">
        <span v-if="qualityScore === 'OES'">
          {{ $t("datafileResultOESTab.averageScoreWidget.title") }}</span
        >
        <span v-if="qualityScore === 'TS'">
          {{ $t("datafileResultTSTab.timeScoreWidget.title") }}</span
        >
        <span v-if="qualityScore === 'IBS'">
          {{ $t("datafileResultIBSTab.itemBatteryScoreWidget.title") }}</span
        >
        <span v-if="qualityScore === 'PS'">
          {{ $t("datafileResultPSTab.averageScoreWidget.title") }}</span
        >
        <span v-if="qualityScore === 'CS'">
          {{ $t("datafileResultCSTab.averageScoreWidget.title") }}</span
        >
        <span v-if="qualityScore === 'SDS'">
          {{ $t("datafileResultSDSTab.averageScoreWidget.title") }}</span
        >
      </div>
      <div class="card-text">
        {{ $t("datafileResultGeneral.generalWidgets.scoreWidget.description") }}
      </div>
      <div
        class="card-text mt-3"
        v-if="!this.isFetchingDataError && this.scoreChartData !== -999"
      >
        <apexchart
          ref="meanChart"
          class="apex-charts apexChartData"
          type="radialBar"
          height="300"
          dir="ltr"
          :series="[Math.round(this.scoreChartData)]"
          :options="scoreChartOptions.chartOptions"
        ></apexchart>
        <h6 class="font-weight-bold text-center">
          {{ $t("datafileResultGeneral.rScoreWidget.tab.meanLabel") }}
        </h6>
      </div>
      <div class="card-text mt-3" v-if="this.isFetchingDataError">
        <DataFetchingErrorWidget />
      </div>
      <div class="card-text mt-3" v-if="this.scoreChartData === -999">
        <NoValidScoresAvailableWidget />
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div class="card-body" v-else>
      <div class="row justify-content-center">
        <div class="col-6 text-center pt-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>
