<script>
import DatafileResultService from "@/api/services/datafile.service.js";
import AccessLevels from "@/shared/enums/aceessLevels";
import Multiselect from "vue-multiselect";

export default {
  props: {
    visible: Boolean,
    datafileId: String,
    datafileOwner: Object
  },
  components: {
    Multiselect
  },
  data() {
    return {
      /******** Data Variables ******/
      datafileContributors: null,
      allUsersOfCompany: null,
      availableUsersForContributors: null,
      accessLevels: AccessLevels,
      selectedContributors: null,
      addingNewContributorSuccessCountdown: 0,
      removeContributorSuccessCountdown: 0,
      dismissSecs: 3,
      /******** Operational Variables *******/
      isLoading: false,
      isFetchingAvailableContributors: false,
      isDataFetchingError: false,
      addContributorEnable: false,
      isDataFetchingErrorOfAvailableContributors: false,
      displayNoAvaialeUsersAsContributors: false,
      isAddingNewContributorFaild: false,
      isRemovingContributorFaild: false
    };
  },
  async created() {
    this.isLoading = true
    await this.getDatafileContributors()
    this.isLoading = false
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    clickCancel() {
      this.$emit("close");
    },
    async getDatafileContributors() {
      try {
        this.datafileContributors = await DatafileResultService.getDatafileContributors(this.datafileId)
      } catch (error) {
        this.isDataFetchingError = true 
      }
    },
    async clickAddNewContributor() {
      this.addContributorEnable = true
      this.isFetchingAvailableContributors = true

      try {
        this.availableUsersForContributors = await DatafileResultService.getAvailableUsersAsContributorsForDataFile(this.datafileId)
        if(this.availableUsersForContributors.length === 0) this.displayNoAvaialeUsersAsContributors = true
        this.isFetchingAvailableContributors = false
      } catch (error) {
        this.isFetchingAvailableContributors = false
        this.isDataFetchingErrorOfAvailableContributors = true 
      }
    },
    clickDismissNoUsersAvaialbeForContributors() {
      this.displayNoAvaialeUsersAsContributors = false
      this.addContributorEnable = false
      this.isFetchingAvailableContributors = false
    },
    async clickInviteNewContributors() {
      this.isLoading = true

      let selectedUserIdsAsNewContributors = []
      this.selectedContributors.forEach(contributor => {
        selectedUserIdsAsNewContributors.push(contributor.id)
      });

      try {
        const response = await DatafileResultService.addContributorToDatafile(this.datafileId, selectedUserIdsAsNewContributors)
        if (response === "SUCCESS") {
          this.addingNewContributorSuccessCountdown = this.dismissSecs;
          this.addContributorEnable = false
          this.selectedContributors = null
          await this.getDatafileContributors()
          this.isLoading = false
        }
      }catch(error) {
        this.isAddingNewContributorFaild = true
        this.isLoading = false
      }
    },
    async clickRemoveContributor(contributorIndex) {
      this.isLoading = true
      console.log(this.datafileContributors[contributorIndex])

      try {
        const response = await DatafileResultService.removeContributorFromDatafile(this.datafileId, this.datafileContributors[contributorIndex].id)
        if (response === "SUCCESS") {
          this.removeContributorSuccessCountdown = this.dismissSecs;
          await this.getDatafileContributors()
          this.isLoading = false
        }
      }catch(error) {
        this.isRemovingContributorFaild = true
        this.isLoading = false
      }
    },
    nameWithEmail({ name, email }) {
      return `${name.first} ${name.last} - ${email}`;
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
  >
    <div class="p-2">
      <!-- Header and input for selecting export options -->
      <div class="row mb-4 text-left">
        <div class="col-12">
          <h6>
            <i class="bx bxs-group mr-2"></i>
            {{$t("datafileResultGeneral.manageContributorsModal.title")}}
          </h6>
        </div>
      </div>

      <!-- Alerts -->
      <div class="row">
        <div class="col-md-12">
          <b-alert
            :show="addingNewContributorSuccessCountdown"
            dismissible
            @dismissed="addingNewContributorSuccessCountdown = 0"
            variant="success"
            >
              {{$t("datafileResultGeneral.manageContributorsModal.alert.successfullyInviteContributor")}}
            </b-alert
          >

          <b-alert
            :show="removeContributorSuccessCountdown"
            dismissible
            @dismissed="removeContributorSuccessCountdown = 0"
            variant="success"
            >
              {{$t("datafileResultGeneral.manageContributorsModal.alert.successfullyRemoveContributor")}}
            </b-alert
          >

          <b-alert
            :show="isAddingNewContributorFaild"
            dismissible
            variant="danger"
            >
              {{$t("datafileResultGeneral.manageContributorsModal.alert.errorRemoveContributor")}}
            </b-alert
          >

          <b-alert
            :show="isRemovingContributorFaild"
            dismissible
            variant="danger"
            >
            {{$t("datafileResultGeneral.manageContributorsModal.alert.errorInvitingContributor")}}
            </b-alert
          >
        </div>
      </div>

      <!-- Datafile Contributors -->
      <div class="row" v-if="!isLoading">
        <div class="col-12">
          <div
            class="row align-items-center mb-3"
            v-for="(contributor, contributorIndex) in this.datafileContributors"
            :key="contributorIndex"
          >
            <div class="col-md-2">
              <img
                src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/default-avatar.png"
                class="rounded-circle profile-user-avatar"
              />
            </div>
            <div class="col-md-8">
              <h6>
                {{ contributor.email }} 
              </h6>
              <div>
                {{contributor.name.first}} {{contributor.name.last}}
              </div>
            </div>
            <div class="col-md-2 text-right">
              <span v-if="datafileOwner.id === contributor.id" class="ml-2">
                <b-badge variant="info">
                  OWNER
                </b-badge>
              </span>
              <b-button
                variant="danger"
                @click="clickRemoveContributor(contributorIndex)"
                size="sm"
                v-if="contributor.accessLevel === accessLevels.EMPLOYEE && datafileOwner.id !== contributor.id"
              >
                <i class="bx bx-x"></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Add Contibutor Button -->
      <div class="row mt-4" v-if="!addContributorEnable && !isLoading">
        <div class="col-12 text-center p-3" style="background:#f5f5f5; cursor:pointer" @click="clickAddNewContributor()">
          <i class='bx bx-plus mr-2' ></i> {{$t("datafileResultGeneral.manageContributorsModal.button.addContributors")}}
        </div>
      </div>

      <!-- Divider -->
      <div class="row mt-4" v-if="addContributorEnable && !isLoading">
        <div class="col-12">
          <hr/>
        </div>
      </div>

      <!-- Add Contibutor Form -->
      <div class="row mt-4" v-if="addContributorEnable && !isLoading && !displayNoAvaialeUsersAsContributors">  
        <div class="col-12 mb-3">
          <div class="row">
            <div class="col-10">
              <h6> {{$t("datafileResultGeneral.manageContributorsModal.selectContributor.label")}}</h6>
            </div>
            <div class="col-2 text-right">
              <i class='bx bx-x' style="font-size: 20px; cursor:pointer" @click="addContributorEnable=false"></i>
            </div>
          </div>
          
        </div>
        <div class="col-12 text-center" v-if="isFetchingAvailableContributors">
          <b-spinner
            style="width: 3rem; height: 3rem"
            class=""
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
        <div class="col-12" v-if="!isFetchingAvailableContributors">
          <multiselect
            v-model="selectedContributors"
            :options="availableUsersForContributors"
            :searchable="true"
            track-by="id"
            :custom-label="nameWithEmail"
            :multiple="true"
            :placeholder="$t('datafileResultGeneral.manageContributorsModal.selectContributor.placeholder')"
          >
            <template slot="selection" slot-scope="{ values, isOpen }">
              <span class="multiselect__single" v-if="values.length" v-show="!isOpen">
                <span v-for="(value, valueIndex) in values" :key="valueIndex"> 
                  <b-badge class="font-size-13 mr-2 p-1" variant="primary">
                    {{ value.name.first }} {{ value.name.last }}
                    <span v-if="value.name.first || value.name.last"> - </span>
                    {{ value.email }}
                  </b-badge>
                </span>
              </span>
            </template>
            <template slot="option" slot-scope="{ option }">
              {{ option.name.first }} {{ option.name.last }}
              <span v-if="option.name.first || option.name.last"> - </span>
              {{ option.email }}
            </template>
          </multiselect>
        </div>
        <div class="col-12 mt-3" v-if="selectedContributors && selectedContributors.length > 0">
          <b-button variant="success" @click="clickInviteNewContributors">
            {{$t("datafileResultGeneral.manageContributorsModal.button.inviteContributors")}}
          </b-button>
        </div>
      </div>

      <!-- No Users to add as a Contributor -->
      <div class="row mt-4" v-if="addContributorEnable && !isLoading && displayNoAvaialeUsersAsContributors">  
        <div class="col-12">
          <b-alert
            v-model="displayNoAvaialeUsersAsContributors"
            class="mb-2"
            variant="danger"
            dismissible
            @dismissed="clickDismissNoUsersAvaialbeForContributors()"
            >
              {{$t("datafileResultGeneral.manageContributorsModal.alert.noMoreUsersToAddAsContributor")}}
            </b-alert
          >
        </div>
      </div>

      <!-- Loading Animation -->
      <div class="row mt-4" v-if="isLoading">
        <div class="col-md-12 text-center">
          <b-spinner
            style="width: 4rem; height: 4rem"
            class=""
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>


  </b-modal>
</template>
