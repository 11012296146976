var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.dbDatafile.numberOfRecords > 0 && this.isDisplayMainLoader
        ? _c("div", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 text-center p-5" },
                [
                  _c("b-spinner", {
                    staticClass: "m-2",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { variant: "mid", role: "status" },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.dbDatafile.numberOfRecords > 0
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !this.isDisplayMainLoader,
                  expression: "!this.isDisplayMainLoader",
                },
              ],
              staticClass: "row",
            },
            [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("MetaDataWidget", {
                        staticClass:
                          "animate__animated animate__fadeInUp animate__slow",
                        attrs: {
                          datafileId: this.datafileId,
                          onlyOneQulityScore:
                            _vm.numberOfQualityScores === 1 ? true : false,
                          cleaned: false,
                        },
                        on: {
                          dataFetchingDone: _vm.countTheComponents,
                          toggleBenchMark: _vm.updateBenchmarkAvailablity,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.numberOfQualityScores > 1 || _vm.enableBenchmarks,
                        expression:
                          "numberOfQualityScores > 1 || enableBenchmarks",
                      },
                    ],
                    staticClass: "row",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-lg-4" },
                      [
                        _c("RScoreWidget", {
                          staticClass:
                            "animate__animated animate__fadeInUp animate__slow",
                          attrs: {
                            datafileId: this.datafileId,
                            cleaned: false,
                            toggleBenchmark: this.enableBenchmarks,
                          },
                          on: {
                            showQualityScores: this.showQualityScoresModel,
                            showRScoreInfo: function ($event) {
                              return _vm.showRScoreInfo(true)
                            },
                            closeRScoreInfo: function ($event) {
                              return _vm.showRScoreInfo(false)
                            },
                            dataFetchingDone: _vm.countTheComponents,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-lg-4" },
                      [
                        _c("RScoreDistributionWidget", {
                          staticClass:
                            "animate__animated animate__fadeInUp animate__slow",
                          attrs: {
                            datafileId: this.datafileId,
                            cleaned: false,
                            toggleBenchmark: this.enableBenchmarks,
                          },
                          on: { dataFetchingDone: _vm.countTheComponents },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-lg-4" },
                      [
                        _c("RScoreTrafficLightWidget", {
                          ref: "rScoreTrafficLight",
                          staticClass:
                            "animate__animated animate__fadeInUp animate__slow",
                          attrs: {
                            datafileId: this.datafileId,
                            cleaned: false,
                            toggleBenchmark: this.enableBenchmarks,
                          },
                          on: { dataFetchingDone: _vm.countTheComponents },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.numberOfQualityScores > 1 &&
                          (_vm.algorithms.IS || _vm.algorithms.SDS),
                        expression:
                          "\n          numberOfQualityScores > 1 && (algorithms.IS || algorithms.SDS)\n        ",
                      },
                    ],
                    staticClass: "row",
                  },
                  [
                    _vm._m(0),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.algorithms.IS,
                            expression: "algorithms.IS",
                          },
                        ],
                        staticClass: "col-12",
                      },
                      [
                        _c("ISQualityScoreWidget", {
                          attrs: {
                            datafileId: this.datafileId,
                            cleaned: false,
                          },
                          on: { dataFetchingDone: _vm.countTheComponents },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.algorithms.SDS,
                            expression: "algorithms.SDS",
                          },
                        ],
                        staticClass: "col-12",
                      },
                      [
                        _c("SDSQualityScoreWidget", {
                          attrs: {
                            datafileId: this.datafileId,
                            cleaned: false,
                          },
                          on: { dataFetchingDone: _vm.countTheComponents },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.dbDatafile.numberOfRecords > 0 &&
      _vm.availableQualityScore &&
      _vm.numberOfQualityScores === 1 &&
      !_vm.algorithms.IS &&
      !_vm.enableBenchmarks
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-lg-4" },
              [
                _c("ScoreWidget", {
                  staticClass:
                    "animate__animated animate__fadeInUp animate__slow",
                  attrs: {
                    datafileId: this.datafileId,
                    qualityScore: this.availableQualityScore,
                    dataPointIdentife: null,
                    isCleaned: false,
                    groupSelector:
                      this.selectedGroupSelector === "All"
                        ? null
                        : this.selectedGroupSelector,
                  },
                  on: { dataFetchingDone: _vm.countTheComponents },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-lg-4" },
              [
                _c("DistributionWidget", {
                  staticClass:
                    "animate__animated animate__fadeInUp animate__slow",
                  attrs: {
                    datafileId: this.datafileId,
                    qualityScore: this.availableQualityScore,
                    dataPointIdentife: null,
                    isCleaned: false,
                    groupSelector:
                      this.selectedGroupSelector === "All"
                        ? null
                        : this.selectedGroupSelector,
                  },
                  on: { dataFetchingDone: _vm.countTheComponents },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-lg-4" },
              [
                _c("TrafficLightWidget", {
                  staticClass:
                    "animate__animated animate__fadeInUp animate__slow",
                  attrs: {
                    datafileId: this.datafileId,
                    qualityScore: this.availableQualityScore,
                    dataPointIdentife: null,
                    isCleaned: false,
                    groupSelector:
                      this.selectedGroupSelector === "All"
                        ? null
                        : this.selectedGroupSelector,
                  },
                  on: { dataFetchingDone: _vm.countTheComponents },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.dbDatafile.numberOfRecords > 0 &&
      !this.isDisplayMainLoading &&
      this.availableQualityScore === "SDS"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("SDSRateWidget", {
                  staticClass:
                    "animate__animated animate__fadeInUp animate__slow",
                  attrs: {
                    datafileId: this.datafileId,
                    dataPointIdentifier: null,
                    isCleaned: false,
                  },
                  on: { dataFetchingDone: _vm.countTheComponents },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.dbDatafile.numberOfRecords > 0 && this.availableQualityScore === "IS"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("PositiveNegativeWidget", {
                  staticClass:
                    "animate__animated animate__fadeInUp animate__slow",
                  attrs: {
                    datafileId: this.datafileId,
                    dataPointIdentifier: _vm.All,
                    isCleaned: false,
                  },
                  on: { dataFetchingDone: _vm.countTheComponents },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("ISDatapointWidget", {
                  staticClass:
                    "animate__animated animate__fadeInUp animate__slow",
                  attrs: { datafileId: this.datafileId, isCleaned: false },
                  on: { dataFetchingDone: _vm.countTheComponents },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.dbDatafile.numberOfRecords > 0
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.numberOfQualityScores === 1,
                  expression: "numberOfQualityScores === 1",
                },
              ],
              staticClass: "row",
            },
            [
              _c(
                "div",
                {
                  class:
                    this.availableQualityScore === "TS" ||
                    this.availableQualityScore === "OES"
                      ? "col-md-6"
                      : "col-md-12",
                },
                [
                  _c("PerformanceWidget", {
                    staticClass:
                      "animate__animated animate__fadeInUp animate__slow",
                    attrs: {
                      datafileId: this.datafileId,
                      qualityScore: this.availableQualityScore,
                      dataPointIdentife: null,
                      isCleaned: false,
                      groupSelector:
                        this.selectedGroupSelector === "All"
                          ? null
                          : this.selectedGroupSelector,
                    },
                    on: {
                      dataFetchingDone: _vm.countTheComponents,
                      performanceWidgetAvaiable:
                        _vm.performanceWidgetAvaiability,
                    },
                  }),
                ],
                1
              ),
              this.availableQualityScore === "TS"
                ? _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("TSSurveyDurationWidget", {
                        staticClass:
                          "animate__animated animate__fadeInUp animate__slow",
                        attrs: {
                          datafileId: this.datafileId,
                          qualityScore: this.availableQualityScore,
                          dataPointIdentife: null,
                          isCleaned: false,
                          groupSelector:
                            this.selectedGroupSelector === "All"
                              ? null
                              : this.selectedGroupSelector,
                        },
                        on: { dataFetchingDone: _vm.countTheComponents },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              this.availableQualityScore === "OES"
                ? _c(
                    "div",
                    {
                      class: !this.isPerformanceWidgetAvailable
                        ? "col-md-12"
                        : "col-md-6",
                    },
                    [
                      _c("OESCategoryWidget", {
                        staticClass:
                          "animate__animated animate__fadeInUp animate__slow",
                        attrs: {
                          datafileId: this.datafileId,
                          isCleaned: false,
                          dataPointIdentife: null,
                        },
                        on: { dataFetchingDone: _vm.countTheComponents },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.dbDatafile.numberOfRecords > 0 &&
      !this.isDisplayMainLoading &&
      this.availableQualityScore === "CS"
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.numberOfQualityScores === 1,
                  expression: "numberOfQualityScores === 1",
                },
              ],
              staticClass: "row",
            },
            [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("AveragePredictionWidget", {
                    attrs: { datafileId: this.datafileId, isCleaned: false },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.dbDatafile.numberOfRecords > 0 &&
      _vm.availableQualityScore &&
      _vm.numberOfQualityScores === 1 &&
      !_vm.algorithms.IS &&
      !_vm.algorithms.SDS
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("DataRecordTableWidget", {
                  staticClass:
                    "animate__animated animate__fadeInUp animate__slow",
                  attrs: {
                    datafileId: this.datafileId,
                    qualityScore: this.availableQualityScore,
                    dataPointIdentife: null,
                    isCleaned: false,
                    groupSelector:
                      this.selectedGroupSelector === "All"
                        ? null
                        : this.selectedGroupSelector,
                  },
                  on: { dataFetchingDone: _vm.countTheComponents },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.dbDatafile.numberOfRecords === 0
        ? _c(
            "div",
            {
              staticClass: "col-12 text-center mt-3",
              staticStyle: { "min-height": "200px" },
            },
            [
              _c("img", {
                staticClass: "img-fluid mb-4 mt-3",
                staticStyle: { width: "60px" },
                attrs: {
                  src: require("@/assets/images/information.png"),
                  alt: "",
                },
              }),
              _c("h5", [_vm._v(_vm._s(_vm.$t("general.noDatarecords")))]),
            ]
          )
        : _vm._e(),
      _vm.showRScoreInfoModel
        ? _c("RScoreWidgetInfo", {
            staticClass: "animate__animated animate__fadeInUp animate__slow",
            attrs: { visible: _vm.showRScoreInfoModel },
            on: {
              close: function ($event) {
                return _vm.showRScoreInfo(false)
              },
            },
          })
        : _vm._e(),
      _vm.isDisplayQualityScoreModal
        ? _c("QualityScoresModal", {
            attrs: {
              visible: this.isDisplayQualityScoreModal,
              datafileId: this.datafileId,
              isCleanedDatafile: false,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayQualityScoreModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12 mt-3 mb-4" }, [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }