var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isDisplayData && _vm.performanceChartData.data.length > 1
      ? _c(
          "div",
          {
            staticClass: "card",
            style: [
              this.isCleaned
                ? { background: "#FFFFFF" }
                : { background: "#FFF5F4" },
            ],
          },
          [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "card-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "datafileResultGeneral.generalWidgets.performanceWidget.title"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "card-text" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "datafileResultGeneral.generalWidgets.performanceWidget.description"
                      )
                    ) +
                    " "
                ),
              ]),
              !this.isFetchingDataError
                ? _c(
                    "div",
                    { staticClass: "card-text mt-3" },
                    [
                      _c("apexchart", {
                        ref: "performanceChart",
                        staticClass: "apex-charts mt-5",
                        attrs: {
                          dir: "ltr",
                          type: "bar",
                          height: "300",
                          series: [_vm.performanceChartData],
                          options: _vm.performanceChartOptions.chartOptions,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              this.isFetchingDataError
                ? _c(
                    "div",
                    { staticClass: "card-text mt-3" },
                    [_c("DataFetchingErrorWidget")],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        )
      : _vm._e(),
    !_vm.isDisplayData
      ? _c(
          "div",
          {
            staticClass: "card",
            style: [
              this.isCleaned
                ? { background: "#FFFFFF" }
                : { background: "#FFF5F4" },
            ],
          },
          [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row justify-content-center" }, [
                _c(
                  "div",
                  { staticClass: "col-6 text-center" },
                  [
                    _c("b-spinner", {
                      staticClass: "m-2",
                      staticStyle: { width: "5rem", height: "5rem" },
                      attrs: { variant: "mid", role: "status" },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }