<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import DatafileResultService from "@/api/services/datafileResult.service.js";
import SDSDataPointWidget from "@/redem/datafiles/components/widgets/SDS/SDS-dataPoint-widget.vue";
import MetadataWidget from "@/redem/datafiles/components/widgets/SDS/SDS-metadata-widget.vue";
import RateWidget from "@/redem/datafiles/components/widgets/SDS/SDS-rate-widget.vue";

import DistributionWidget from "@/redem/datafiles/components/widgets/distribution-widget.vue";
import PerformanceWidget from "@/redem/datafiles/components/widgets/performance-widget.vue";
import ScoreWidget from "@/redem/datafiles/components/widgets/score-widget.vue";
import TrafficLightWidget from "@/redem/datafiles/components/widgets/traffic-light-widget.vue";

/**
 * Cleaned Results
 */
export default {
  components: {
    MetadataWidget,
    RateWidget,
    SDSDataPointWidget,
    DistributionWidget,
    ScoreWidget,
    TrafficLightWidget,
    PerformanceWidget
  },
  data() {
    return {
      /*******  Data varibales ********/
      datafileId: null,
      datapointsData: [],
      /******** Operational Variables *********/
      isDisplayaDatapointData: false,
      errorDatapointData: false,
      isDisplayMainLoader: true,
      alreadyLoadedComponents: 0
    };
  },
  async created() {
    this.datafileId = this.$route.query.datafileId;
    this.fetchChartData();
  },
  methods: {
    fetchChartData() {
      DatafileResultService.getAllDataPoints(this.datafileId)
        .then(data => {
          this.datapointsData = data.filter(
            ({ algorithms }) => algorithms.SDS === true
          );
          this.isDisplayaDatapointData = true;
          this.$parent.$parent.$parent.isDisplaySocialDesirabilityScoreResults = true;
          this.countTheComponents();
        })
        .catch(e => {
          this.errorDatapointData = true;
          this.countTheComponents();
        });
    },
    countTheComponents() {
      this.alreadyLoadedComponents += 1;

      if (this.alreadyLoadedComponents === 4) this.isDisplayMainLoader = false;
    }
  }
};
</script>

<template>
  <div>
    <!-- Loading Component -->
    <div v-if="this.isDisplayMainLoader">
      <div class="row">
        <div class="col-12 text-center p-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>

    <div v-show="!this.isDisplayMainLoader">
      <!-- Metadata widget -->
      <MetadataWidget
        :datafileId="this.datafileId"
        class="animate__animated animate__fadeInUp animate__slow"
      />

      <!-- SDS Rate Chart -->
      <RateWidget
        class="animate__animated animate__fadeInUp animate__slow"
        :datafileId="this.datafileId"
        :dataPointIdentifier="null"
        :isCleaned="true"
        @dataFetchingDone="countTheComponents"
      />

      <!-- Quality Score Charts -->
      <div class="row">
        <div class="col-md-4">
          <ScoreWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'SDS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="null"
            @dataFetchingDone="countTheComponents"
          />
        </div>
        <div class="col-md-4">
          <DistributionWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'SDS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="null"
            @dataFetchingDone="countTheComponents"
          />
        </div>
        <div class="col-md-4">
          <TrafficLightWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'SDS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="null"
            @dataFetchingDone="countTheComponents"
          />
        </div>
      </div>

      <!-- Performance Widget -->
      <div class="row">
        <div class="col-md-12">
          <PerformanceWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'SDS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="null"
            @dataFetchingDone="countTheComponents"
          />
        </div>
      </div>

      <!-- Data Points -->
      <div class="row">
        <SDSDataPointWidget
          class="animate__animated animate__fadeInUp animate__slow"
          v-for="(datapointData, i) in this.datapointsData"
          :key="i"
          :datapointData="datapointData"
        />
      </div>
    </div>
  </div>
</template>
