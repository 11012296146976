<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import DatafileResultService from "@/api/services/datafileResult.service.js";
import { ISAnswerOptionChart } from "@/redem/datafiles/result-charts";

export default {
  props: {
    datafileId: String,
    isCleaned: Boolean
  },
  components: {},
  data() {
    return {
      dataPointResults: null,
      /******* Operational Variables *******/
      isDisplayData: false,
      isFetchingDataError: false
    };
  },
  async created() {
    await this.fetchCahrtData();
    this.isDisplayData = true;
    this.$emit("dataFetchingDone");
  },
  methods: {
    async fetchCahrtData() {
      await DatafileResultService.getValuesForISDataPoints(
        this.datafileId,
        this.isCleaned
      )
        .then(data => {
          this.dataPointsResults = data.map(
            ({ dataPointIdentifier, scores, chartData }) => {
              const answerOptions = [];
              for (let i = 0; i < chartData[0].data.length; i++)
                answerOptions.push(`Answer ${i + 1}`);
              const answerOptionsChartOptions = new ISAnswerOptionChart();
              answerOptionsChartOptions.chartOptions.xaxis.categories = [
                ...answerOptions
              ];

              return {
                dataPointIdentifier,
                scores: {
                  positive: scores.Positive,
                  negative: scores.Negative
                },
                chartData: [...chartData],
                chartOptions: answerOptionsChartOptions
              };
            }
          );
          this.isDisplayData = true;
        })
        .catch(e => {
          this.isFetchingDataError = true;
          this.isDisplayData = true;
        });
    }
  }
};
</script>

<template>
  <div>
    <!-- Result -->
    <div v-if="this.isDisplayData">
      <div
        v-for="(dataPoint, dataPointIndex) in dataPointsResults"
        :key="dataPointIndex"
      >
        <div
          class="card"
          :style="[
            isCleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
          ]"
        >
          <div class="card-body">
            <div class="card-title">
              {{ dataPoint.dataPointIdentifier }}
            </div>
            <div class="card-text mt-3">
              <b-tabs>
                <!-- Answer Options Widget -->
                <b-tab lazy active>
                  <template v-slot:title>
                    {{
                      $t(
                        "datafileResultISTab.dataPointWidget.answerOptionsTab.title"
                      )
                    }}
                  </template>
                  <div class="row p-3">
                    <div class="col-md-12">
                      <div class="card-title mb-1">
                        {{
                          $t(
                            "datafileResultISTab.dataPointWidget.answerOptionsTab.subTitle"
                          )
                        }}
                      </div>
                      <div class="card-text mb-2">
                        {{
                          $t(
                            "datafileResultISTab.dataPointWidget.answerOptionsTab.description"
                          )
                        }}
                      </div>
                      <div class="card-text">
                        <apexchart
                          class="apex-charts"
                          dir="ltr"
                          type="bar"
                          height="440"
                          :series="dataPoint.chartData"
                          :options="dataPoint.chartOptions.chartOptions"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <!-- Positive Negative Widget -->
                <b-tab lazy>
                  <template v-slot:title>
                    {{
                      $t(
                        "datafileResultISTab.dataPointWidget.positiveNegativeTab.title"
                      )
                    }}
                  </template>
                  <div class="row p-3">
                    <div class="col-md-12">
                      <div class="card-title mb-1">
                        {{
                          $t("datafileResultISTab.positiveNegativeWidget.title")
                        }}
                      </div>
                      <div class="card-text mb-2">
                        {{
                          $t(
                            "datafileResultISTab.positiveNegativeWidget.description"
                          )
                        }}
                      </div>
                      <div class="card-text mb-2">
                        <span>
                          <i class="bx bxs-circle text-mid"> </i>
                          {{
                            $t(
                              "datafileResultISTab.positiveNegativeWidget.negativeLabel"
                            )
                          }}
                          %
                        </span>
                        <span class="ml-3">
                          <i class="bx bxs-circle text-success"> </i>
                          {{
                            $t(
                              "datafileResultISTab.positiveNegativeWidget.positiveLabel"
                            )
                          }}
                          %
                        </span>
                      </div>
                      <b-progress
                        class="mt-4"
                        :max="100"
                        height="30px"
                        style="font-size: 15px"
                      >
                        <b-progress-bar
                          :value="dataPoint.scores.negative"
                          variant="mid"
                          show-value
                        ></b-progress-bar>
                        <b-progress-bar
                          :value="dataPoint.scores.positive"
                          variant="success"
                          show-value
                        ></b-progress-bar>
                      </b-progress>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div class="card" v-else>
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-6 text-center ">
            <b-spinner
              style="width: 5rem; height: 5rem"
              class="m-2"
              variant="mid"
              role="status"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
