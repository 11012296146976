var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card",
      style: [
        this.isCleaned ? { background: "#FFFFFF" } : { background: "#FFF5F4" },
      ],
    },
    [
      _vm.showCategoryChart
        ? _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "card-title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("datafileResultOESTab.categoryWidget.title")) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "card-text" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("datafileResultOESTab.categoryWidget.description")
                  ) +
                  " "
              ),
            ]),
            !this.isCategoriesAreNotAvailable
              ? _c(
                  "div",
                  { staticClass: "card-text mt-5" },
                  [
                    _c("apexchart", {
                      ref: "OESCategoryChart",
                      staticClass: "apex-charts mt-2",
                      attrs: {
                        height: "300",
                        type: "pie",
                        dir: "ltr",
                        series: _vm.oesCategoryChartData,
                        options: _vm.oesCategoriesChartOptions.chartOptions,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            this.isCategoriesAreNotAvailable
              ? _c(
                  "div",
                  {
                    staticClass: "card-text mt-5",
                    staticStyle: { height: "300px" },
                  },
                  [_c("VersionCompatibleError")],
                  1
                )
              : _vm._e(),
          ])
        : _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-6 text-center pt-5" },
                [
                  _c("b-spinner", {
                    staticClass: "m-2",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { variant: "mid", role: "status" },
                  }),
                ],
                1
              ),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }