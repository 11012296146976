<script>
import DatafileResultService from "@/api/services/datafileResult.service";
import { ALGORITHM_TYPES } from "@/shared/util/const";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";
/*****
 * Information Quality Widget
 */
export default {
  props: {
    datafileId: String,
    datapointIdentifier: String,
    cleaned: Boolean,
    cardBackgroundColor: String
  },
  components: {
    DataFetchingErrorWidget
  },
  data() {
    return {
      /******* Data Variables *******/
      chartDataForPositiveNegative: {
        positive: 0,
        negative: 0
      },
      /******* Operational Variables *******/
      isDisplayInfo: false,
      isDisplayData: false,
      isDisplayPosNegativeError: false
    };
  },
  async created() {
    const posNegative = this.fetchPositiveNegativeData();
    try {
      await posNegative;
    } catch (e) {
      this.isDisplayPosNegativeError = true;
    }

    this.isDisplayData = true;
    this.$emit("dataFetchingDone");
  },
  methods: {
    async fetchPositiveNegativeData() {
      const tempPositiveNegativeData = await DatafileResultService.getISPositiveNegative(
        this.datafileId,
        this.datapointIdentifier
      );

      this.chartDataForPositiveNegative = {
        positive: tempPositiveNegativeData.Positive,
        negative: tempPositiveNegativeData.Negative
      };
    },

    changeToShowInfo() {
      this.isDisplayInfo = true;
    },

    changeToShowData() {
      this.isDisplayInfo = false;
    },

    clickViewMoreButton() {
      this.$emit("viewMore", ALGORITHM_TYPES.IS);
    }
  }
};
</script>
<template>
  <div
    class="card animate__animated animate__fadeInUp animate__slow"
    :style="[
      this.cleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
    ]"
  >
    <div class="" v-if="isDisplayData">
      <!-- Results -->
      <div class="card-body" v-if="!isDisplayInfo">
        <b-card-title class="pb-1">
          <div class="float-right" v-if="this.cleaned">
            <b-button
              class="btn-sm"
              variant="light"
              @click="clickViewMoreButton()"
              >{{
                $t("datafileResultGeneral.qualityScoreWidget.showMoreButton")
              }}
              <i class="bx bx-right-arrow-alt ml-1"></i
            ></b-button>
          </div>
          <h4 class="card-title mb-1">
            {{ $t("qualityScoreWidgets.infomationScoreWidget.title") }}
            <i
              class="bx bxs-info-circle font-size-20 text-info bx-tada ml-2"
              style="cursor: pointer"
              @click="changeToShowInfo()"
            ></i>
          </h4>
        </b-card-title>
        <div class="row mb-3">
          <div class="col-md-12">
            {{ $t("qualityScoreWidgets.infomationScoreWidget.description") }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span>
              <i class="bx bxs-circle text-mid"> </i>
              {{
                $t(
                  "qualityScoreWidgets.infomationScoreWidget.positiveNegativeTab.negative"
                )
              }}
              %
            </span>
            <span class="ml-3">
              <i class="bx bxs-circle text-success"> </i>
              {{
                $t(
                  "qualityScoreWidgets.infomationScoreWidget.positiveNegativeTab.positive"
                )
              }}
              %
            </span>
          </div>
        </div>
        <b-progress
          v-if="!isDisplayPosNegativeError"
          class="mt-4"
          :max="100"
          height="30px"
          style="font-size: 15px"
        >
          <b-progress-bar
            :value="chartDataForPositiveNegative.negative"
            variant="mid"
            show-value
          ></b-progress-bar>
          <b-progress-bar
            :value="chartDataForPositiveNegative.positive"
            variant="success"
            show-value
          ></b-progress-bar>
        </b-progress>
        <div class="mt-3 text-center" v-else>
          <DataFetchingErrorWidget />
        </div>
      </div>

      <!-- Description -->
      <div class="card-body" v-else>
        <div class="row mb-3">
          <div class="col-md-10">
            <h4 class="card-title mb-3">
              {{ $t("qualityScoreWidgets.infomationScoreWidget.title") }}
            </h4>
          </div>
          <div class="col-md-2 text-right">
            <i
              class="bx bxs-x-circle font-size-20 text-info bx-tada"
              style="cursor: pointer"
              @click="changeToShowData()"
            ></i>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.infomationScoreWidget.detailedInfo.text1")
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.infomationScoreWidget.detailedInfo.text2")
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.infomationScoreWidget.detailedInfo.text3")
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.infomationScoreWidget.detailedInfo.text4")
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t("qualityScoreWidgets.infomationScoreWidget.detailedInfo.text5")
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t(
                "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text61"
              )
            }}
            <u>
              {{
                $t(
                  "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text62"
                )
              }}
            </u>
            {{
              $t(
                "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text63"
              )
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t(
                "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text71"
              )
            }}
            <u>{{
              $t(
                "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text72"
              )
            }}</u>
            {{
              $t(
                "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text73"
              )
            }}
            {{
              $t(
                "qualityScoreWidgets.infomationScoreWidget.detailedInfo.text74"
              )
            }}
          </div>
          <div class="col-md-12">
            {{
              $t(
                "qualityScoreWidgets.infomationScoreWidget.detailedInfo.useCase.title"
              )
            }}
            <ul>
              <li>
                {{
                  $t(
                    "qualityScoreWidgets.infomationScoreWidget.detailedInfo.useCase.text1"
                  )
                }}
              </li>
              <li>
                {{
                  $t(
                    "qualityScoreWidgets.infomationScoreWidget.detailedInfo.useCase.text2"
                  )
                }}
              </li>
              <li>
                {{
                  $t(
                    "qualityScoreWidgets.infomationScoreWidget.detailedInfo.useCase.text3"
                  )
                }}
              </li>
              <li>
                {{
                  $t(
                    "qualityScoreWidgets.infomationScoreWidget.detailedInfo.useCase.text4"
                  )
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div class="row justify-content-center" v-else>
      <div class="col-6 text-center pt-5 pb-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>
