var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card", style: { height: "300px" } }, [
    _c("div", { staticClass: "card-body" }, [
      _c(
        "h5",
        { staticClass: "card-title mb-3" },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("datafileResultCleanedTab.reportWidget.title")) +
              " "
          ),
          _c(
            "b-dropdown",
            {
              staticClass: "float-right",
              attrs: { size: "sm", variant: "light", dropleft: "" },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function () {
                    return [
                      _c("i", { staticClass: "bx bx-dots-vertical-rounded" }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.datafile.certificateLevel
                ? _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.clickViewDatafileReport()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "bx bxs-show mr-2" }),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "datafileResultCleanedTab.reportWidget.viewReportbutton"
                          )
                        )
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.datafile.certificateLevel
                ? _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        href: _vm.reportMetaData.certificateImageURL,
                        download: "",
                      },
                    },
                    [
                      _c("i", { staticClass: "bx bxs-download mr-2" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultCleanedTab.reportWidget.downloadCertificate"
                            )
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.clickReportSettings()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "bx bxs-cog mr-2" }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "datafileResultCleanedTab.reportWidget.manageReport"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", [
        _c("span", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("datafileResultCleanedTab.reportWidget.certificateLevel")
              ) +
              ": "
          ),
        ]),
        _c(
          "span",
          [
            _vm.datafile.certificateLevel
              ? _c(
                  "b-badge",
                  {
                    staticStyle: { "font-size": "12px" },
                    attrs: { variant: "gold" },
                  },
                  [_vm._v(" " + _vm._s(_vm.datafile.certificateLevel) + " ")]
                )
              : _vm._e(),
            !_vm.datafile.certificateLevel
              ? _c("strong", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "datafileResultCleanedTab.reportWidget.notEligibleForCertificate"
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      !_vm.datafile.certificateLevel
        ? _c("div", { staticClass: "mt-5 text-center" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "datafileResultCleanedTab.reportWidget.noCertificateAvailable"
                  )
                ) +
                " "
            ),
          ])
        : _vm._e(),
      _vm.datafile.certificateLevel
        ? _c("div", { staticClass: "mt-1 text-center" }, [
            _c("br"),
            _c("img", {
              staticClass: "img-fluid",
              staticStyle: { width: "150px" },
              attrs: {
                src: require("@/assets/images/redem-certification.png"),
                alt: "",
              },
            }),
            _c("h5", { staticClass: "mt-2" }, [
              _vm._v(_vm._s(_vm.reportMetaData.certificateCode)),
            ]),
          ])
        : _vm._e(),
      !_vm.datafile.certificateLevel
        ? _c("div", { staticClass: "row mt-3" }, [
            _c(
              "div",
              { staticClass: "col-md-12 text-center" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { size: "sm", variant: "light" },
                    on: {
                      click: function ($event) {
                        return _vm.clickViewDatafileReport()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "bx bxs-show mr-2" }),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileResultCleanedTab.reportWidget.viewReportbutton"
                        )
                      ) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }