var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card",
      style: [
        this.isCleaned ? { background: "#FFFFFF" } : { background: "#FFF5F4" },
      ],
    },
    [
      _vm.isDisplayData
        ? _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("h4", { staticClass: "card-title mb-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("datafileResultCleanedTab.dataRecordTable.title")
                      ) +
                      " "
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "datafileResultCleanedTab.dataRecordTable.description"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
            this.qualityScore !== "OES"
              ? _c("div", { staticClass: "card-text mt-3" }, [
                  _c("div", { staticClass: "row mt-4 mb-4" }, [
                    _c("div", { staticClass: "col-md-8" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultCleanedTab.dataRecordTable.show"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-2" },
                          [
                            _c("b-form-select", {
                              attrs: { size: "sm", options: _vm.pageOptions },
                              model: {
                                value: _vm.perPage,
                                callback: function ($$v) {
                                  _vm.perPage = $$v
                                },
                                expression: "perPage",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-md-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultCleanedTab.dataRecordTable.dataRecords"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        {
                          staticClass: "dataTables_filter text-md-right",
                          attrs: { id: "tickets-table_filter" },
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "d-inline-flex align-items-center" },
                            [
                              _c("b-form-input", {
                                staticClass:
                                  "form-control form-control-sm ml-2",
                                attrs: {
                                  type: "search",
                                  placeholder: this.$t(
                                    "datafileResultCleanedTab.dataRecordTable.searchPlaceholder"
                                  ),
                                },
                                model: {
                                  value: _vm.filter,
                                  callback: function ($$v) {
                                    _vm.filter = $$v
                                  },
                                  expression: "filter",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "table-responsive mb-0" },
                    [
                      _c("b-table", {
                        attrs: {
                          items: _vm.allDatarecords,
                          fields: _vm.fieldsForTable,
                          filter: _vm.filter,
                          responsive: "sm",
                          "per-page": _vm.perPage,
                          "current-page": _vm.currentPage,
                          "sort-by": _vm.sortBy,
                          "sort-desc": _vm.sortDesc,
                        },
                        on: {
                          "update:sortBy": function ($event) {
                            _vm.sortBy = $event
                          },
                          "update:sort-by": function ($event) {
                            _vm.sortBy = $event
                          },
                          "update:sortDesc": function ($event) {
                            _vm.sortDesc = $event
                          },
                          "update:sort-desc": function ($event) {
                            _vm.sortDesc = $event
                          },
                          "row-clicked": function ($event) {
                            return _vm.openRespondent($event)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(rank)",
                              fn: function (data) {
                                return [
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(" " + _vm._s(data.value) + " "),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(recordId)",
                              fn: function (data) {
                                return [
                                  _c("v-gravatar", {
                                    staticClass: "rounded-circle",
                                    attrs: {
                                      email: data.value,
                                      size: _vm.vGravatarSize,
                                    },
                                  }),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(" " + _vm._s(data.value) + " "),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(qualityScore)",
                              fn: function (data) {
                                return [
                                  data.value === -999
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "datafileResultGeneral.dataRecordTable.notAvailable"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              data.value.toLocaleString(
                                                "de-DE",
                                                { maximumFractionDigits: 2 }
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                ]
                              },
                            },
                            {
                              key: "cell(group)",
                              fn: function (data) {
                                return [
                                  data.value === -999
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "datafileResultGeneral.dataRecordTable.notAvailable"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              data.value.toLocaleString(
                                                "de-DE",
                                                { maximumFractionDigits: 2 }
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                ]
                              },
                            },
                            {
                              key: "cell(validDatapoints)",
                              fn: function (data) {
                                return [
                                  data.value === -999
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "datafileResultGeneral.dataRecordTable.notAvailable"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              data.value.toLocaleString(
                                                "de-DE",
                                                { maximumFractionDigits: 2 }
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                ]
                              },
                            },
                            {
                              key: "cell(trafficLight)",
                              fn: function (data) {
                                return [
                                  _c("i", {
                                    staticClass: "bx bxs-circle text-primary",
                                    class: {
                                      "text-success":
                                        `${data.value}` === "Green",
                                      "text-average":
                                        `${data.value}` === "LightGreen",
                                      "text-danger": `${data.value}` === "Red",
                                      "text-warning":
                                        `${data.value}` === "Yellow",
                                      "text-mid": `${data.value}` === "Grey",
                                    },
                                    staticStyle: { "font-size": "20px" },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "cell(action)",
                              fn: function (data) {
                                return [
                                  data.item.qualityScore !== -999
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "warning",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickSuggestScore(
                                                data.item.recordId,
                                                data.item.oesAnswer,
                                                data.item.oesFeedback,
                                                data.item.oesComment,
                                                data.item.qualityScore
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "bx bxs-pencil",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "cell(oesSwearword)",
                              fn: function (data) {
                                return [
                                  data.value == "true"
                                    ? _c("i", {
                                        class: {
                                          "bx bxs-flag text-danger":
                                            `${data.value}` == "true",
                                        },
                                        staticStyle: { "font-size": "20px" },
                                      })
                                    : _c("i", {
                                        staticClass: "bx bx-minus",
                                        staticStyle: { "font-size": "12px" },
                                      }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4164001144
                        ),
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dataTables_paginate paging_simple_numbers float-right",
                        },
                        [
                          _c(
                            "ul",
                            {
                              staticClass: "pagination pagination-rounded mb-0",
                            },
                            [
                              _c("b-pagination", {
                                attrs: {
                                  "total-rows": _vm.rows,
                                  "per-page": _vm.perPage,
                                },
                                model: {
                                  value: _vm.currentPage,
                                  callback: function ($$v) {
                                    _vm.currentPage = $$v
                                  },
                                  expression: "currentPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            this.qualityScore === "OES"
              ? _c(
                  "div",
                  { staticClass: "card-text mt-3" },
                  [
                    _c(
                      "b-tabs",
                      {
                        model: {
                          value: _vm.activatedTabForOES,
                          callback: function ($$v) {
                            _vm.activatedTabForOES = $$v
                          },
                          expression: "activatedTabForOES",
                        },
                      },
                      [
                        this.OESDataPointIdentifiers.length > 1
                          ? _c(
                              "b-tab",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeToOESOverviewTab()
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-none d-sm-inline-block",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "datafileResultCleanedTab.tabs.overview"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  32504195
                                ),
                              },
                              [
                                _c("div", { staticClass: "p-3" }, [
                                  _c("div", { staticClass: "row mt-4 mb-4" }, [
                                    _c("div", { staticClass: "col-md-8" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col-md-1" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "datafileResultCleanedTab.dataRecordTable.show"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-2" },
                                          [
                                            _c("b-form-select", {
                                              attrs: {
                                                size: "sm",
                                                options: _vm.pageOptions,
                                              },
                                              model: {
                                                value: _vm.perPage,
                                                callback: function ($$v) {
                                                  _vm.perPage = $$v
                                                },
                                                expression: "perPage",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "col-md-2" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "datafileResultCleanedTab.dataRecordTable.dataRecords"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dataTables_filter text-md-right",
                                          attrs: { id: "tickets-table_filter" },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "d-inline-flex align-items-center",
                                            },
                                            [
                                              _c("b-form-input", {
                                                staticClass:
                                                  "form-control form-control-sm ml-2",
                                                attrs: {
                                                  type: "search",
                                                  placeholder: this.$t(
                                                    "datafileResultCleanedTab.dataRecordTable.searchPlaceholder"
                                                  ),
                                                },
                                                model: {
                                                  value: _vm.filter,
                                                  callback: function ($$v) {
                                                    _vm.filter = $$v
                                                  },
                                                  expression: "filter",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm.isCleaned
                                    ? _c("div", { staticClass: "row mb-4" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-12" },
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  name: "check-button",
                                                  switch: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.isShowExcludedDataRecordsAsWell,
                                                  callback: function ($$v) {
                                                    _vm.isShowExcludedDataRecordsAsWell =
                                                      $$v
                                                  },
                                                  expression:
                                                    "isShowExcludedDataRecordsAsWell",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "datafileResultOESTab.dataRecordTable.showAllRecords"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "table-responsive mb-0" },
                                    [
                                      _c("b-table", {
                                        attrs: {
                                          items: _vm.allDatarecords,
                                          fields: _vm.fieldsForTable,
                                          filter: _vm.filter,
                                          responsive: "sm",
                                          "per-page": _vm.perPage,
                                          "current-page": _vm.currentPage,
                                          "sort-by": _vm.sortBy,
                                          "sort-desc": _vm.sortDesc,
                                        },
                                        on: {
                                          "update:sortBy": function ($event) {
                                            _vm.sortBy = $event
                                          },
                                          "update:sort-by": function ($event) {
                                            _vm.sortBy = $event
                                          },
                                          "update:sortDesc": function ($event) {
                                            _vm.sortDesc = $event
                                          },
                                          "update:sort-desc": function (
                                            $event
                                          ) {
                                            _vm.sortDesc = $event
                                          },
                                          "row-clicked": function ($event) {
                                            return _vm.openRespondent($event)
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "cell(rank)",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    "span",
                                                    { staticClass: "ml-2" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(data.value) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(recordId)",
                                              fn: function (data) {
                                                return [
                                                  _c("v-gravatar", {
                                                    staticClass:
                                                      "rounded-circle",
                                                    attrs: {
                                                      email: data.value,
                                                      size: _vm.vGravatarSize,
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    { staticClass: "ml-2" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(data.value) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(qualityScore)",
                                              fn: function (data) {
                                                return [
                                                  data.value === -999
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "datafileResultGeneral.dataRecordTable.notAvailable"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.value.toLocaleString(
                                                                "de-DE",
                                                                {
                                                                  maximumFractionDigits: 2,
                                                                }
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(group)",
                                              fn: function (data) {
                                                return [
                                                  data.value === -999
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "datafileResultGeneral.dataRecordTable.notAvailable"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.value.toLocaleString(
                                                                "de-DE",
                                                                {
                                                                  maximumFractionDigits: 2,
                                                                }
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(validDatapoints)",
                                              fn: function (data) {
                                                return [
                                                  data.value === -999
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "datafileResultGeneral.dataRecordTable.notAvailable"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.value.toLocaleString(
                                                                "de-DE",
                                                                {
                                                                  maximumFractionDigits: 2,
                                                                }
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(trafficLight)",
                                              fn: function (data) {
                                                return [
                                                  _c("i", {
                                                    staticClass:
                                                      "bx bxs-circle text-primary",
                                                    class: {
                                                      "text-success":
                                                        `${data.value}` ===
                                                        "Green",
                                                      "text-average":
                                                        `${data.value}` ===
                                                        "LightGreen",
                                                      "text-danger":
                                                        `${data.value}` ===
                                                        "Red",
                                                      "text-warning":
                                                        `${data.value}` ===
                                                        "Yellow",
                                                      "text-mid":
                                                        `${data.value}` ===
                                                        "Grey",
                                                    },
                                                    staticStyle: {
                                                      "font-size": "20px",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(action)",
                                              fn: function (data) {
                                                return [
                                                  data.item.qualityScore !==
                                                  -999
                                                    ? _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant: "warning",
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.clickSuggestScore(
                                                                data.item
                                                                  .recordId,
                                                                data.item
                                                                  .oesAnswer,
                                                                data.item
                                                                  .oesFeedback,
                                                                data.item
                                                                  .oesComment,
                                                                data.item
                                                                  .qualityScore
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "bx bxs-pencil",
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(oesSwearword)",
                                              fn: function (data) {
                                                return [
                                                  data.value == "true"
                                                    ? _c("i", {
                                                        class: {
                                                          "bx bxs-flag text-danger":
                                                            `${data.value}` ==
                                                            "true",
                                                        },
                                                        staticStyle: {
                                                          "font-size": "20px",
                                                        },
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "bx bx-minus",
                                                        staticStyle: {
                                                          "font-size": "12px",
                                                        },
                                                      }),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(oesAnswerCategory)",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge badge-silver font-size-12",
                                                      class: {
                                                        "oes-answer-cat-va":
                                                          `${data.value}` == 1,
                                                        "oes-answer-cat-ga":
                                                          `${data.value}` == 4,
                                                        "oes-answer-cat-pr":
                                                          `${data.value}` == 6,
                                                        "oes-answer-cat-no":
                                                          `${data.value}` == 7,
                                                        "oes-answer-cat-dr":
                                                          `${data.value}` == 8,
                                                        "oes-answer-cat-da":
                                                          `${data.value}` == 9,
                                                        "oes-answer-cat-wt":
                                                          `${data.value}` == 10,
                                                        "oes-answer-cat-wl":
                                                          `${data.value}` == 11,
                                                        "oes-answer-cat-cp":
                                                          `${data.value}` == 12,
                                                        "oes-answer-cat-ai-error":
                                                          `${data.value}` == -2,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.findOESCategory(
                                                              data.value
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2447699705
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dataTables_paginate paging_simple_numbers float-right",
                                        },
                                        [
                                          _c(
                                            "ul",
                                            {
                                              staticClass:
                                                "pagination pagination-rounded mb-0",
                                            },
                                            [
                                              _c("b-pagination", {
                                                attrs: {
                                                  "total-rows": _vm.rows,
                                                  "per-page": _vm.perPage,
                                                },
                                                model: {
                                                  value: _vm.currentPage,
                                                  callback: function ($$v) {
                                                    _vm.currentPage = $$v
                                                  },
                                                  expression: "currentPage",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._l(
                          this.OESDataPointIdentifiers,
                          function (dataPoint, dataPointIndex) {
                            return _c(
                              "b-tab",
                              {
                                key: dataPointIndex,
                                on: {
                                  click: function ($event) {
                                    return _vm.changeOESDataPointTab(
                                      dataPointIndex
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-none d-sm-inline-block text-limt",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.OESDataPointIdentifiers[
                                                    dataPointIndex
                                                  ]
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("div", { staticClass: "p-3" }, [
                                  _vm.isFetchingOESData
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row justify-content-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-6 text-center",
                                            },
                                            [
                                              _c("b-spinner", {
                                                staticClass: "m-2",
                                                staticStyle: {
                                                  width: "5rem",
                                                  height: "5rem",
                                                },
                                                attrs: {
                                                  variant: "mid",
                                                  role: "status",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.isFetchingOESData
                                    ? _c("div", [
                                        _c("div", { staticClass: "row mb-3" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-12" },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "datafileResultOESTab.dataRecordTable.oesQuestionTitle"
                                                    )
                                                  ) + " "
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.OESQuestionTitles[
                                                      dataPointIndex
                                                    ]
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm.OESDataPoints[dataPointIndex]
                                          .keywords &&
                                        _vm.OESDataPoints[dataPointIndex]
                                          .keywords.length > 0
                                          ? _c(
                                              "div",
                                              { staticClass: "row mb-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-12" },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "datafileResultOESTab.dataRecordTable.oesQuestionKeywords"
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]),
                                                    _vm._l(
                                                      _vm.OESDataPoints[
                                                        dataPointIndex
                                                      ].keywords,
                                                      function (
                                                        keyword,
                                                        keywordIndex
                                                      ) {
                                                        return _c(
                                                          "span",
                                                          { key: keywordIndex },
                                                          [
                                                            _c(
                                                              "b-badge",
                                                              {
                                                                staticClass:
                                                                  "mr-2 mb-1",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "12px",
                                                                },
                                                                attrs: {
                                                                  variant:
                                                                    "success",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      keyword
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.OESDataPoints[dataPointIndex]
                                          .languagesToCheck &&
                                        _vm.OESDataPoints[dataPointIndex]
                                          .languagesToCheck.length > 0
                                          ? _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-12" },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "datafileResultOESTab.dataRecordTable.oesQuestionLanguagesToCheck"
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]),
                                                    _vm._l(
                                                      _vm.OESDataPoints[
                                                        dataPointIndex
                                                      ].languagesToCheck,
                                                      function (
                                                        lan,
                                                        langIndex
                                                      ) {
                                                        return _c(
                                                          "span",
                                                          { key: langIndex },
                                                          [
                                                            _c(
                                                              "b-badge",
                                                              {
                                                                staticClass:
                                                                  "mr-2 mb-1",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "12px",
                                                                },
                                                                attrs: {
                                                                  variant:
                                                                    "primary",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      lan
                                                                    ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      _vm.findLanguageWithCode(
                                                                        lan
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    _vm.OESDataPoints[
                                                      dataPointIndex
                                                    ].languagesToCheck
                                                      .length === 0
                                                      ? _c("span", [
                                                          _vm._v(" - "),
                                                        ])
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "row mt-4 mb-4" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-8" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-md-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "datafileResultCleanedTab.dataRecordTable.show"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-md-2",
                                                      },
                                                      [
                                                        _c("b-form-select", {
                                                          attrs: {
                                                            size: "sm",
                                                            options:
                                                              _vm.pageOptions,
                                                          },
                                                          model: {
                                                            value: _vm.perPage,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.perPage = $$v
                                                            },
                                                            expression:
                                                              "perPage",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-md-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "datafileResultCleanedTab.dataRecordTable.dataRecords"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-4" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dataTables_filter text-md-right",
                                                    attrs: {
                                                      id: "tickets-table_filter",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "d-inline-flex align-items-center",
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          staticClass:
                                                            "form-control form-control-sm ml-2",
                                                          attrs: {
                                                            type: "search",
                                                            placeholder: _vm.$t(
                                                              "datafileResultCleanedTab.dataRecordTable.searchPlaceholder"
                                                            ),
                                                          },
                                                          model: {
                                                            value: _vm.filter,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.filter = $$v
                                                            },
                                                            expression:
                                                              "filter",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm.isCleaned
                                          ? _c(
                                              "div",
                                              { staticClass: "row mb-4" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-12" },
                                                  [
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        staticClass: "mt-2",
                                                        attrs: {
                                                          name: "check-button",
                                                          switch: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.isShowExcludedDataRecordsAsWell,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.isShowExcludedDataRecordsAsWell =
                                                              $$v
                                                          },
                                                          expression:
                                                            "isShowExcludedDataRecordsAsWell",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "datafileResultOESTab.dataRecordTable.showAllRecords"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "table-responsive mb-0",
                                          },
                                          [
                                            _c("b-table", {
                                              attrs: {
                                                items: _vm.allDatarecords,
                                                fields: _vm.fieldsForTable,
                                                filter: _vm.filter,
                                                responsive: "sm",
                                                "per-page": _vm.perPage,
                                                "current-page": _vm.currentPage,
                                                "sort-by": _vm.sortBy,
                                                "sort-desc": _vm.sortDesc,
                                              },
                                              on: {
                                                "update:sortBy": function (
                                                  $event
                                                ) {
                                                  _vm.sortBy = $event
                                                },
                                                "update:sort-by": function (
                                                  $event
                                                ) {
                                                  _vm.sortBy = $event
                                                },
                                                "update:sortDesc": function (
                                                  $event
                                                ) {
                                                  _vm.sortDesc = $event
                                                },
                                                "update:sort-desc": function (
                                                  $event
                                                ) {
                                                  _vm.sortDesc = $event
                                                },
                                                "row-clicked": function (
                                                  $event
                                                ) {
                                                  return _vm.openRespondent(
                                                    $event
                                                  )
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "cell(rank)",
                                                    fn: function (data) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "ml-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.value
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(recordId)",
                                                    fn: function (data) {
                                                      return [
                                                        _c("v-gravatar", {
                                                          staticClass:
                                                            "rounded-circle",
                                                          attrs: {
                                                            email: data.value,
                                                            size: _vm.vGravatarSize,
                                                          },
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "ml-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.value
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(qualityScore)",
                                                    fn: function (data) {
                                                      return [
                                                        data.value === -999
                                                          ? _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "datafileResultGeneral.dataRecordTable.notAvailable"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.value.toLocaleString(
                                                                      "de-DE",
                                                                      {
                                                                        maximumFractionDigits: 2,
                                                                      }
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(group)",
                                                    fn: function (data) {
                                                      return [
                                                        data.value === -999
                                                          ? _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "datafileResultGeneral.dataRecordTable.notAvailable"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.value.toLocaleString(
                                                                      "de-DE",
                                                                      {
                                                                        maximumFractionDigits: 2,
                                                                      }
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(validDatapoints)",
                                                    fn: function (data) {
                                                      return [
                                                        data.value === -999
                                                          ? _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "datafileResultGeneral.dataRecordTable.notAvailable"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.value.toLocaleString(
                                                                      "de-DE",
                                                                      {
                                                                        maximumFractionDigits: 2,
                                                                      }
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(trafficLight)",
                                                    fn: function (data) {
                                                      return [
                                                        _c("i", {
                                                          staticClass:
                                                            "bx bxs-circle text-primary",
                                                          class: {
                                                            "text-success":
                                                              `${data.value}` ===
                                                              "Green",
                                                            "text-average":
                                                              `${data.value}` ===
                                                              "LightGreen",
                                                            "text-danger":
                                                              `${data.value}` ===
                                                              "Red",
                                                            "text-warning":
                                                              `${data.value}` ===
                                                              "Yellow",
                                                            "text-mid":
                                                              `${data.value}` ===
                                                              "Grey",
                                                          },
                                                          staticStyle: {
                                                            "font-size": "20px",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(action)",
                                                    fn: function (data) {
                                                      return [
                                                        data.item
                                                          .qualityScore !== -999
                                                          ? _c(
                                                              "b-button",
                                                              {
                                                                attrs: {
                                                                  variant:
                                                                    "warning",
                                                                  size: "sm",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.clickOESFeedbackScore(
                                                                        data,
                                                                        dataPointIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "bx bxs-pencil",
                                                                }),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(oesSwearword)",
                                                    fn: function (data) {
                                                      return [
                                                        data.value == "true"
                                                          ? _c("i", {
                                                              class: {
                                                                "bx bxs-flag text-danger":
                                                                  `${data.value}` ==
                                                                  "true",
                                                              },
                                                              staticStyle: {
                                                                "font-size":
                                                                  "20px",
                                                              },
                                                            })
                                                          : _c("i", {
                                                              staticClass:
                                                                "bx bx-minus",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                            }),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(oesAnswerCategory)",
                                                    fn: function (data) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "badge badge-silver font-size-12",
                                                            class: {
                                                              "oes-answer-cat-va":
                                                                `${data.value}` ==
                                                                1,
                                                              "oes-answer-cat-ga":
                                                                `${data.value}` ==
                                                                4,
                                                              "oes-answer-cat-ni":
                                                                `${data.value}` ==
                                                                5,
                                                              "oes-answer-cat-pr":
                                                                `${data.value}` ==
                                                                6,
                                                              "oes-answer-cat-no":
                                                                `${data.value}` ==
                                                                7,
                                                              "oes-answer-cat-dr":
                                                                `${data.value}` ==
                                                                8,
                                                              "oes-answer-cat-da":
                                                                `${data.value}` ==
                                                                9,
                                                              "oes-answer-cat-wt":
                                                                `${data.value}` ==
                                                                10,
                                                              "oes-answer-cat-wl":
                                                                `${data.value}` ==
                                                                11,
                                                              "oes-answer-cat-cp":
                                                                `${data.value}` ==
                                                                12,
                                                              "oes-answer-cat-ar":
                                                                `${data.value}` ==
                                                                13,
                                                              "oes-answer-cat-ai-error":
                                                                `${data.value}` ==
                                                                -2,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.findOESCategory(
                                                                    data.value
                                                                  )
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "row" }, [
                                          _c("div", { staticClass: "col" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dataTables_paginate paging_simple_numbers float-right",
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "pagination pagination-rounded mb-0",
                                                  },
                                                  [
                                                    _c("b-pagination", {
                                                      attrs: {
                                                        "total-rows": _vm.rows,
                                                        "per-page": _vm.perPage,
                                                      },
                                                      model: {
                                                        value: _vm.currentPage,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.currentPage = $$v
                                                        },
                                                        expression:
                                                          "currentPage",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _c(
            "div",
            {
              staticClass: "card-body",
              staticStyle: { "min-height": "1000px", "padding-top": "100px" },
            },
            [
              _c("div", { staticClass: "row justify-content-center" }, [
                _c(
                  "div",
                  { staticClass: "col-6 text-center" },
                  [
                    _c("b-spinner", {
                      staticClass: "m-2",
                      staticStyle: { width: "5rem", height: "5rem" },
                      attrs: { variant: "mid", role: "status" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
      _vm.isDisplayRecordModal
        ? _c("DatarecordModal", {
            attrs: {
              dataRecord: _vm.respondentForRespondentModal,
              datafile: _vm.datafileId,
              activatedTab: _vm.activatedTabForRespondentModal,
              visible: _vm.isDisplayRecordModal,
              includeTimestamp: _vm.includeTS,
              includeIBS: _vm.includeIBS,
              includeCS: _vm.includeCS,
              includePS: _vm.includePS,
              includeOES: _vm.includeOES,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayRecordModal = false
              },
            },
          })
        : _vm._e(),
      _vm.isDisplayOESFeedbackModal
        ? _c("OESFeedbackModal", {
            attrs: {
              visible: _vm.isDisplayOESFeedbackModal,
              datafileId: _vm.datafileId,
              dataPointIndentifier:
                _vm.dataForOESFeedbackModal.dataPointIndentifier,
              questionTitle: _vm.dataForOESFeedbackModal.questionTitle,
              selectedDataRecord: _vm.dataForOESFeedbackModal.dataRecord,
              openFromRespondentModal:
                _vm.isOpenOESFeedbackModalFromRespondentModal,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayOESFeedbackModal = false
              },
              suggestAndClose: _vm.finalizeOESSuggestScore,
              suggestScoreFromRespondent: _vm.suggestScoreFromRespondentModal,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }