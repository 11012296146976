<script>
// Services
import QuotaService from "@/api/services/quota.service.js";
import { ERROR_CODES } from "@/shared/util/errorCodes.js";
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    datafileId: String
  },
  components: {},
  data() {
    return {
      targetQuotaSettingsPercentage: [],
      /********** Operational Variables ********/
      quotaSettingsNotSaved: false,
      isLoading: true
    };
  },
  created() {
    this.getTragetQuotaSettingPercentage(this.datafileId);
    EventBus.$on("updateQuotaWidget", async () => {
      await this.getTragetQuotaSettingPercentage(this.datafileId);
    });
  },
  methods: {
    async getTragetQuotaSettingPercentage(datafielID) {
      this.isLoading = true;
      const response = await QuotaService.getTargetQuotaPercentage(datafielID);
      this.$emit("dataFetchingDone");
      if (response === ERROR_CODES.QUOTA_SETTINGS_NOT_SAVED) {
        this.isLoading = false;
        this.quotaSettingsNotSaved = true;
        this.$emit("quotaSettingStatus")
      } else {
        this.isLoading = false;
        this.quotaSettingsNotSaved = false;
        this.targetQuotaSettingsPercentage = response;
      }
    },
    clickInfQuotaWidget() {
      this.$emit("showQuotaInfo");
    },
    clickQuotaSettingsModal() {
      EventBus.$emit("showQuotaSettingsModal");
    }
  }
};
</script>
<template>
  <div class="card" :style="{ height: '300px' }">
    <div class="card-body">
      <!-- Header -->
      <h5 class="card-title">
        {{ $t("datafileResultCleanedTab.quotaSettingsWidget.title") }}
        <i
          class="bx bxs-info-circle font-size-16 text-info bx-tada ml-2"
          style="cursor: pointer"
          @click="clickInfQuotaWidget()"
        ></i>
        <div
          class="float-right"
          v-if="!this.quotaSettingsNotSaved && !this.isLoading"
        >
          <b-button
            size="sm"
            variant="light"
            @click="clickQuotaSettingsModal()"
          >
            <i class="bx bxs-pie-chart-alt-2 mr-2"></i>
            {{
              $t("datafileResultCleanedTab.quotaSettingsWidget.button")
            }}</b-button
          >
        </div>
      </h5>

      <!-- Loading untile data get set --->
      <div class="row justify-content-center" v-if="this.isLoading">
        <div class="col-6 text-center pt-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>

      <!-- Display Datat -->
      <div v-if="!this.isLoading">
        <!-- No Quotat Settings -->
        <div
          class="card-text text-center mt-5"
          v-if="this.quotaSettingsNotSaved"
        >
          {{
            $t("datafileResultCleanedTab.quotaSettingsWidget.noSettings.text")
          }}
          <div class="mt-3">
            <b-button
              size="sm"
              variant="primary"
              @click="clickQuotaSettingsModal()"
            >
              <i class="bx bxs-pie-chart-alt-2 mr-2"></i>
              {{
                $t(
                  "datafileResultCleanedTab.quotaSettingsWidget.noSettings.button"
                )
              }}</b-button
            >
          </div>
        </div>

        <!-- Settings Available -->
        <div
          class="card-text mt-5"
          v-if="
            !this.quotaSettingsNotSaved && this.targetQuotaSettingsPercentage
          "
        >
          {{ $t("datafileResultCleanedTab.quotaSettingsWidget.description") }}
          <div class="mt-3">
            <b-progress
              class="mt-4"
              :max="100"
              height="30px"
              style="font-size: 15px"
            >
              <b-progress-bar
                :value="
                  (this.targetQuotaSettingsPercentage[0] /
                    this.targetQuotaSettingsPercentage[1]) *
                    100
                "
                :label="
                  `${this.targetQuotaSettingsPercentage[0]} / ${this.targetQuotaSettingsPercentage[1]}`
                "
                variant="success"
                show-value
              ></b-progress-bar>
            </b-progress>
            <i class="mt-3" v-if="this.targetQuotaSettingsPercentage === 0">
              {{
                $t("datafileResultCleanedTab.quotaSettingsWidget.tragetNotSet")
              }}</i
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
