var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { centered: "", "hide-footer": "", "hide-header": "" },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "p-2" }, [
        _c("div", { staticClass: "row mb-4 text-left" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h6", [
              _c("i", { staticClass: "bx bxs-group mr-2" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "datafileResultGeneral.manageContributorsModal.title"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c(
                "b-alert",
                {
                  attrs: {
                    show: _vm.addingNewContributorSuccessCountdown,
                    dismissible: "",
                    variant: "success",
                  },
                  on: {
                    dismissed: function ($event) {
                      _vm.addingNewContributorSuccessCountdown = 0
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "datafileResultGeneral.manageContributorsModal.alert.successfullyInviteContributor"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "b-alert",
                {
                  attrs: {
                    show: _vm.removeContributorSuccessCountdown,
                    dismissible: "",
                    variant: "success",
                  },
                  on: {
                    dismissed: function ($event) {
                      _vm.removeContributorSuccessCountdown = 0
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "datafileResultGeneral.manageContributorsModal.alert.successfullyRemoveContributor"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "b-alert",
                {
                  attrs: {
                    show: _vm.isAddingNewContributorFaild,
                    dismissible: "",
                    variant: "danger",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "datafileResultGeneral.manageContributorsModal.alert.errorRemoveContributor"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "b-alert",
                {
                  attrs: {
                    show: _vm.isRemovingContributorFaild,
                    dismissible: "",
                    variant: "danger",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "datafileResultGeneral.manageContributorsModal.alert.errorInvitingContributor"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        !_vm.isLoading
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                _vm._l(
                  this.datafileContributors,
                  function (contributor, contributorIndex) {
                    return _c(
                      "div",
                      {
                        key: contributorIndex,
                        staticClass: "row align-items-center mb-3",
                      },
                      [
                        _c("div", { staticClass: "col-md-2" }, [
                          _c("img", {
                            staticClass: "rounded-circle profile-user-avatar",
                            attrs: {
                              src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/default-avatar.png",
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "col-md-8" }, [
                          _c("h6", [
                            _vm._v(" " + _vm._s(contributor.email) + " "),
                          ]),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(contributor.name.first) +
                                " " +
                                _vm._s(contributor.name.last) +
                                " "
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-2 text-right" },
                          [
                            _vm.datafileOwner.id === contributor.id
                              ? _c(
                                  "span",
                                  { staticClass: "ml-2" },
                                  [
                                    _c(
                                      "b-badge",
                                      { attrs: { variant: "info" } },
                                      [_vm._v(" OWNER ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            contributor.accessLevel ===
                              _vm.accessLevels.EMPLOYEE &&
                            _vm.datafileOwner.id !== contributor.id
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "danger", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickRemoveContributor(
                                          contributorIndex
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "bx bx-x" })]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }
                ),
                0
              ),
            ])
          : _vm._e(),
        !_vm.addContributorEnable && !_vm.isLoading
          ? _c("div", { staticClass: "row mt-4" }, [
              _c(
                "div",
                {
                  staticClass: "col-12 text-center p-3",
                  staticStyle: { background: "#f5f5f5", cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.clickAddNewContributor()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "bx bx-plus mr-2" }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "datafileResultGeneral.manageContributorsModal.button.addContributors"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm.addContributorEnable && !_vm.isLoading
          ? _c("div", { staticClass: "row mt-4" }, [
              _c("div", { staticClass: "col-12" }, [_c("hr")]),
            ])
          : _vm._e(),
        _vm.addContributorEnable &&
        !_vm.isLoading &&
        !_vm.displayNoAvaialeUsersAsContributors
          ? _c("div", { staticClass: "row mt-4" }, [
              _c("div", { staticClass: "col-12 mb-3" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-10" }, [
                    _c("h6", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultGeneral.manageContributorsModal.selectContributor.label"
                            )
                          )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-2 text-right" }, [
                    _c("i", {
                      staticClass: "bx bx-x",
                      staticStyle: { "font-size": "20px", cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          _vm.addContributorEnable = false
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _vm.isFetchingAvailableContributors
                ? _c(
                    "div",
                    { staticClass: "col-12 text-center" },
                    [
                      _c("b-spinner", {
                        staticStyle: { width: "3rem", height: "3rem" },
                        attrs: { variant: "mid", role: "status" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isFetchingAvailableContributors
                ? _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("multiselect", {
                        attrs: {
                          options: _vm.availableUsersForContributors,
                          searchable: true,
                          "track-by": "id",
                          "custom-label": _vm.nameWithEmail,
                          multiple: true,
                          placeholder: _vm.$t(
                            "datafileResultGeneral.manageContributorsModal.selectContributor.placeholder"
                          ),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function ({ values, isOpen }) {
                                return [
                                  values.length
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !isOpen,
                                              expression: "!isOpen",
                                            },
                                          ],
                                          staticClass: "multiselect__single",
                                        },
                                        _vm._l(
                                          values,
                                          function (value, valueIndex) {
                                            return _c(
                                              "span",
                                              { key: valueIndex },
                                              [
                                                _c(
                                                  "b-badge",
                                                  {
                                                    staticClass:
                                                      "font-size-13 mr-2 p-1",
                                                    attrs: {
                                                      variant: "primary",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          value.name.first
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          value.name.last
                                                        ) +
                                                        " "
                                                    ),
                                                    value.name.first ||
                                                    value.name.last
                                                      ? _c("span", [
                                                          _vm._v(" - "),
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(value.email) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "option",
                              fn: function ({ option }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(option.name.first) +
                                      " " +
                                      _vm._s(option.name.last) +
                                      " "
                                  ),
                                  option.name.first || option.name.last
                                    ? _c("span", [_vm._v(" - ")])
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(option.email) + " "),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3832086135
                        ),
                        model: {
                          value: _vm.selectedContributors,
                          callback: function ($$v) {
                            _vm.selectedContributors = $$v
                          },
                          expression: "selectedContributors",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedContributors && _vm.selectedContributors.length > 0
                ? _c(
                    "div",
                    { staticClass: "col-12 mt-3" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "success" },
                          on: { click: _vm.clickInviteNewContributors },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultGeneral.manageContributorsModal.button.inviteContributors"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.addContributorEnable &&
        !_vm.isLoading &&
        _vm.displayNoAvaialeUsersAsContributors
          ? _c("div", { staticClass: "row mt-4" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "b-alert",
                    {
                      staticClass: "mb-2",
                      attrs: { variant: "danger", dismissible: "" },
                      on: {
                        dismissed: function ($event) {
                          return _vm.clickDismissNoUsersAvaialbeForContributors()
                        },
                      },
                      model: {
                        value: _vm.displayNoAvaialeUsersAsContributors,
                        callback: function ($$v) {
                          _vm.displayNoAvaialeUsersAsContributors = $$v
                        },
                        expression: "displayNoAvaialeUsersAsContributors",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultGeneral.manageContributorsModal.alert.noMoreUsersToAddAsContributor"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.isLoading
          ? _c("div", { staticClass: "row mt-4" }, [
              _c(
                "div",
                { staticClass: "col-md-12 text-center" },
                [
                  _c("b-spinner", {
                    staticStyle: { width: "4rem", height: "4rem" },
                    attrs: { variant: "mid", role: "status" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }