var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card animate__animated animate__fadeInUp animate__slow",
      style: [
        this.cleaned ? { background: "#FFFFFF" } : { background: "#FFF5F4" },
      ],
    },
    [
      _vm.isDisplayData
        ? _c("div", {}, [
            !_vm.isDisplayInfo
              ? _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("b-card-title", { staticClass: "pb-1" }, [
                      this.cleaned
                        ? _c(
                            "div",
                            { staticClass: "float-right" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn-sm",
                                  attrs: { variant: "light" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickViewMoreButton()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.qualityScoreWidget.showMoreButton"
                                      )
                                    ) + " "
                                  ),
                                  _c("i", {
                                    staticClass: "bx bx-right-arrow-alt ml-1",
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("h4", { staticClass: "card-title mb-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "qualityScoreWidgets.socialDesirabilityScoreWidget.title"
                              )
                            ) +
                            " "
                        ),
                        _c("i", {
                          staticClass:
                            "bx bxs-info-circle font-size-18 text-info bx-tada ml-2",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.changeToShowInfo()
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "row mb-3 mt-3" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "qualityScoreWidgets.socialDesirabilityScoreWidget.description"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row mt-5" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "b-tabs",
                            [
                              _c(
                                "b-tab",
                                {
                                  attrs: { active: "", lazy: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-none d-sm-inline-block",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "qualityScoreWidgets.socialDesirabilityScoreWidget.tabs.rate.title"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    210006656
                                  ),
                                },
                                [
                                  _c("div", { staticClass: "row mt-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-12" },
                                      [
                                        _c("div", { staticClass: "mb-3" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "qualityScoreWidgets.socialDesirabilityScoreWidget.tabs.rate.description"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-12" },
                                            [
                                              _c("span", [
                                                _c("i", {
                                                  staticClass:
                                                    "bx bxs-circle text-danger",
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "qualityScoreWidgets.socialDesirabilityScoreWidget.tabs.rate.socialDesirableLabel"
                                                      )
                                                    ) +
                                                    "% "
                                                ),
                                              ]),
                                              _c(
                                                "span",
                                                { staticClass: "ml-3" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bx bxs-circle text-success",
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "qualityScoreWidgets.socialDesirabilityScoreWidget.tabs.rate.notSocialDesirableLabel"
                                                        )
                                                      ) +
                                                      "% "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        !_vm.isDisplayRateError
                                          ? _c(
                                              "b-progress",
                                              {
                                                staticClass: "mt-4",
                                                staticStyle: {
                                                  "font-size": "15px",
                                                },
                                                attrs: {
                                                  max: 100,
                                                  height: "30px",
                                                },
                                              },
                                              [
                                                _c("b-progress-bar", {
                                                  attrs: {
                                                    value:
                                                      _vm.rateChartData
                                                        .negative,
                                                    variant: "danger",
                                                    "show-value": "",
                                                  },
                                                }),
                                                _c("b-progress-bar", {
                                                  attrs: {
                                                    value:
                                                      _vm.rateChartData
                                                        .positive,
                                                    variant: "success",
                                                    "show-value": "",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "mt-3 text-center",
                                              },
                                              [_c("DataFetchingErrorWidget")],
                                              1
                                            ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "b-tab",
                                {
                                  attrs: { lazy: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-none d-sm-inline-block",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "qualityScoreWidgets.socialDesirabilityScoreWidget.tabs.genral"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2383333503
                                  ),
                                },
                                [
                                  _c("div", { staticClass: "row mt-3" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      !_vm.isDisplayTrafficLightError
                                        ? _c(
                                            "div",
                                            [
                                              _c("apexchart", {
                                                ref: "PSMeanChart",
                                                staticClass:
                                                  "apex-charts apexChartData mt-5",
                                                attrs: {
                                                  type: "radialBar",
                                                  height: "300",
                                                  dir: "ltr",
                                                  series: [
                                                    _vm.chartDataForMeanChart,
                                                  ],
                                                  options:
                                                    _vm.meanChartOptions
                                                      .chartOptions,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "text-center" },
                                            [_c("DataFetchingErrorWidget")],
                                            1
                                          ),
                                    ]),
                                    _c("div", { staticClass: "col-md-4" }, [
                                      !_vm.isDisplayDistributionError
                                        ? _c(
                                            "div",
                                            [
                                              _c("apexchart", {
                                                ref: "PSDistributionChart",
                                                staticClass: "apex-charts mt-5",
                                                attrs: {
                                                  dir: "ltr",
                                                  type: "area",
                                                  height: "300",
                                                  series:
                                                    _vm.chartDataForDistributionChart,
                                                  options:
                                                    _vm.distributionChartOptions
                                                      .chartOptions,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "text-center" },
                                            [_c("DataFetchingErrorWidget")],
                                            1
                                          ),
                                    ]),
                                    _c("div", { staticClass: "col-md-4" }, [
                                      !_vm.isDisplayTrafficLightError
                                        ? _c(
                                            "div",
                                            [
                                              _c("apexchart", {
                                                ref: "PStrafficLightChart",
                                                staticClass:
                                                  "apex-charts mt-5 mb-4",
                                                attrs: {
                                                  height: "300",
                                                  type: "pie",
                                                  dir: "ltr",
                                                  series:
                                                    _vm.chartDataForTrafficLightChart,
                                                  options:
                                                    _vm.trafficLightChartOptions
                                                      .chartOptions,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "text-center" },
                                            [_c("DataFetchingErrorWidget")],
                                            1
                                          ),
                                    ]),
                                  ]),
                                ]
                              ),
                              this.chartDataForPerformanceChart.data.length > 1
                                ? _c(
                                    "b-tab",
                                    {
                                      attrs: { lazy: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "title",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-none d-sm-inline-block",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "qualityScoreWidgets.socialDesirabilityScoreWidget.tabs.performance"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2627805012
                                      ),
                                    },
                                    [
                                      _c("div", { staticClass: "row mt-3" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-12" },
                                          [
                                            !_vm.isDisplayPerformanceDataError
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("apexchart", {
                                                      ref: "performanceChart",
                                                      staticClass:
                                                        "apex-charts",
                                                      attrs: {
                                                        dir: "ltr",
                                                        type: "bar",
                                                        height: "300",
                                                        series: [
                                                          _vm.chartDataForPerformanceChart,
                                                        ],
                                                        options:
                                                          _vm
                                                            .performanceChartOptions
                                                            .chartOptions,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "DataFetchingErrorWidget"
                                                    ),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              : _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-md-10" }, [
                      _c("h4", { staticClass: "card-title mb-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "qualityScoreWidgets.socialDesirabilityScoreWidget.title"
                              )
                            ) +
                            " "
                        ),
                        _c("i", {
                          staticClass:
                            "bx bxs-x-circle font-size-18 text-info bx-tada ml-2",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.changeToShowData()
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.socialDesirabilityScoreWidget.detailedInfo.text1"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.socialDesirabilityScoreWidget.detailedInfo.text2"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.socialDesirabilityScoreWidget.detailedInfo.text3"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.socialDesirabilityScoreWidget.detailedInfo.text4"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.socialDesirabilityScoreWidget.detailedInfo.text5"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-12" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "qualityScoreWidgets.socialDesirabilityScoreWidget.detailedInfo.useCase.title"
                            )
                          ) +
                          " "
                      ),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "qualityScoreWidgets.socialDesirabilityScoreWidget.detailedInfo.useCase.text1"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
          ])
        : _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center pt-5 pb-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }