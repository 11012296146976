<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import DatafileResultService from "@/api/services/datafileResult.service.js";
import { MedianChart } from "@/redem/datafiles/result-charts.js";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    datafileId: String,
    qualityScore: String,
    isCleaned: Boolean,
    groupSelector: String
  },
  components: {
    DataFetchingErrorWidget
  },
  data() {
    return {
      /***** Data varaiables *****/
      surveyDurationChartData: null,
      surveyDurationChartOptions: null,
      /***** Operational varaiables *****/
      isDisplayData: false,
      isFetchingDataError: false
    };
  },
  watch: {
    groupSelector: function(newValue, oldValue) {
      this.fetchChartData();
    }
  },
  async created() {
    await this.fetchChartData();
    this.surveyDurationChartOptions = new MedianChart();

    for (let i = 0; i < this.surveyDurationChartData.data.length; i++) {
      this.surveyDurationChartOptions.chartOptions.xaxis.categories.push(
        this.qualityScore + " " + (i + 1)
      );
    }
    this.$emit("dataFetchingDone");
    this.initializeEventBus();
  },
  methods: {
    async fetchChartData(reload = true) {
      // enable Loading
      if (reload) this.isDisplayData = false;

      await DatafileResultService.getTimePerData(
        this.datafileId,
        this.groupSelector
      )
        .then(data => {
          this.surveyDurationChartData = {
            name: "Median Time " + this.qualityScore,
            data
          };
          this.isDisplayData = true;
        })
        .catch(e => {
          this.isFetchingDataError = true;
          this.isDisplayData = true;
        });
    },
    initializeEventBus() {
      EventBus.$on("updateQualityWidgets", async () => {
        await this.fetchChartData(false);
      });
    },
  }
};
</script>

<template>
  <div>
    <!-- Results -->
    <div
      class="card"
      v-if="isDisplayData && surveyDurationChartData.data.length > 1"
      :style="[
        this.isCleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
      ]"
    >
      <div class="card-body">
        <div class="card-title">
          {{ $t("datafileResultTSTab.datapointsMedianWidget.title") }}
        </div>
        <div class="card-text">
          {{ $t("datafileResultTSTab.datapointsMedianWidget.description") }}
        </div>

        <div class="card-text mt-3" v-if="!this.isFetchingDataError">
          <apexchart
            ref="performanceChart"
            class="apex-charts mt-5"
            dir="ltr"
            type="bar"
            height="300"
            :series="[surveyDurationChartData]"
            :options="surveyDurationChartOptions.chartOptions"
          ></apexchart>
        </div>
        <div class="card-text mt-3" v-if="this.isFetchingDataError">
          <DataFetchingErrorWidget />
        </div>
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div
      class="card"
      v-if="!isDisplayData"
      :style="[
        this.isCleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
      ]"
    >
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-6 text-center">
            <b-spinner
              style="width: 5rem; height: 5rem"
              class="m-2"
              variant="mid"
              role="status"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
