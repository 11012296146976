var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card", staticStyle: { height: "300px" } }, [
    _vm.isDisplayData
      ? _c("div", { staticClass: "card-body" }, [
          _c(
            "h5",
            { staticClass: "card-title mb-3" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "datafileResultCleanedTab.cleanignSummaryWidget.title"
                    )
                  ) +
                  " "
              ),
              _c(
                "b-button",
                {
                  staticClass: "float-right",
                  attrs: { size: "sm", variant: "light" },
                  on: {
                    click: function ($event) {
                      return _vm.clickCleaningSettings()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "bx bx-brush mr-2" }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "datafileResultCleanedTab.cleanignSummaryWidget.cleanignSettingsButton"
                        )
                      )
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "card-text mt-4" },
            [
              _c(
                "b-row",
                [
                  _c("b-col", { staticClass: "mb-2", attrs: { sm: "4" } }, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultCleanedTab.cleanignSummaryWidget.totalRecords"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.data.numberOfIncludedDataRecords)),
                      ]),
                    ]),
                  ]),
                  _c("b-col", { staticClass: "mb-2", attrs: { sm: "4" } }, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultCleanedTab.cleanignSummaryWidget.excludedRecords"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.data.numberOfExcludedDataRecords)),
                      ]),
                    ]),
                  ]),
                  _vm.onlyOneQulityScore
                    ? _c("b-col", { staticClass: "mb-2", attrs: { sm: "4" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultCleanedTab.cleanignSummaryWidget.recordsWithOutScores"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.data.numberOfDataRecordsWithInvalidScore
                              )
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isCleaningApply
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c("apexchart", {
                      attrs: {
                        type: "donut",
                        options: _vm.dataRecordSummaryChartOptions.chartOptions,
                        series: _vm.dataRecordSummaryChartData,
                        height: "290",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          !_vm.isCleaningApply
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    !_vm.isCleaningApply
                      ? _c(
                          "b-alert",
                          {
                            staticClass: "mt-2 text-center",
                            attrs: { show: "", variant: "info" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafileResultCleanedTab.cleanignSummaryWidget.cleaningNotAppliedYet"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ])
      : _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center pt-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }