var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card" },
    [
      _vm.isDisplayData
        ? _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("div", { staticClass: "card-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("qualityScoreWidgets.openEndedScoreWidget.title")
                    ) +
                    " "
                ),
                _c("i", {
                  staticClass:
                    "bx bxs-info-circle font-size-18 text-info bx-tada ml-2",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.clickQualityScoreInfoModal()
                    },
                  },
                }),
              ]),
              !this.isFetchingDataError
                ? _c(
                    "b-row",
                    [
                      _c("b-col", { staticClass: "mb-3", attrs: { sm: "4" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultOESTab.metadataWidget.numberOfDataPoints"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.metadata.numberOfDatapoints)),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { staticClass: "mb-3", attrs: { sm: "4" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultOESTab.metadataWidget.numberOfDataRecords"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.metadata.numberOfRecords)),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { staticClass: "mb-3", attrs: { sm: "4" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultOESTab.metadataWidget.numberOfInvalidDataRecords"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.metadata.numberOfInvalidRecords)),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { staticClass: "mb-3", attrs: { sm: "4" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultOESTab.metadataWidget.contextCheck"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _vm.metadata.isEnableWrongTopicCheck
                          ? _c(
                              "span",
                              [
                                _c(
                                  "b-badge",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "info" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultOESTab.metadataWidget.yes"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.metadata.isEnableWrongTopicCheck
                          ? _c(
                              "span",
                              [
                                _c(
                                  "b-badge",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "info" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultOESTab.metadataWidget.no"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("b-col", { staticClass: "mb-3", attrs: { sm: "4" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultOESTab.metadataWidget.duplicateCheck"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _vm.metadata.enableDuplicateChecks
                          ? _c(
                              "span",
                              [
                                _c(
                                  "b-badge",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "info" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultOESTab.metadataWidget.yes"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.metadata.enableDuplicateChecks
                          ? _c(
                              "span",
                              [
                                _c(
                                  "b-badge",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "info" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultOESTab.metadataWidget.no"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              this.isFetchingDataError
                ? _c(
                    "div",
                    { staticClass: "card-text mt-3" },
                    [_c("DataFetchingErrorWidget")],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-6 text-center" },
                [
                  _c("b-spinner", {
                    staticClass: "m-2",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { variant: "mid", role: "status" },
                  }),
                ],
                1
              ),
            ]),
          ]),
      _vm.isDisplayInfoModal
        ? _c("OESInfoModal", {
            attrs: { visible: _vm.isDisplayInfoModal },
            on: {
              close: function ($event) {
                _vm.isDisplayInfoModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }