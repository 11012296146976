var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      this.isDisplayMainLoading
        ? _c("div", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 text-center p-5" },
                [
                  _c("b-spinner", {
                    staticClass: "m-2",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { variant: "mid", role: "status" },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.availableQualityScore
        ? _c("div", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !this.isDisplayMainLoading,
                    expression: "!this.isDisplayMainLoading",
                  },
                ],
              },
              [
                this.groupSelectors && this.groupSelectors.length > 1
                  ? _c("div", { staticClass: "row mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafileResultTSTab.groupSelectorDropdown.label"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("Multiselect", {
                            attrs: {
                              options: _vm.groupSelectors,
                              placeholder: _vm.$t(
                                "uploadProcessStep2.form.general.selectColumn.placeholder"
                              ),
                            },
                            model: {
                              value: _vm.selectedGroupSelector,
                              callback: function ($$v) {
                                _vm.selectedGroupSelector = $$v
                              },
                              expression: "selectedGroupSelector",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("MetaDataWidget", {
                        staticClass:
                          "animate__animated animate__fadeInUp animate__slow",
                        attrs: {
                          datafileId: this.datafileId,
                          cleaned: true,
                          onlyOneQulityScore: true,
                          availableQualityScore: _vm.availableQualityScore,
                        },
                        on: { dataFetchingDone: _vm.countTheComponents },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { class: _vm.customWidgetWidth },
                    [
                      _c("DataRecordsSummaryWidget", {
                        staticClass:
                          "animate__animated animate__fadeInUp animate__slow",
                        attrs: {
                          datafileId: this.datafileId,
                          cleaned: true,
                          isCleaningApply: _vm.dbDatafile.isCleaned,
                          onlyOneQulityScore: true,
                        },
                        on: { dataFetchingDone: _vm.countTheComponents },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { class: _vm.customWidgetWidth },
                    [
                      _c("ReportWidget", {
                        staticClass:
                          "animate__animated animate__fadeInUp animate__slow",
                        attrs: {
                          datafileId: this.datafileId,
                          datafileTitle: this.dbDatafile.title,
                          datafile: this.dbDatafile,
                        },
                        on: {
                          displayManageReport: function ($event) {
                            return _vm.showManageReprot(true)
                          },
                          dataFetchingDone: _vm.countTheComponents,
                        },
                      }),
                    ],
                    1
                  ),
                  this.dbDatafile.importType === "XLXS" &&
                  this.isDisplayQuotaSettingsWidget
                    ? _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c("QuotaSettingsWidget", {
                            staticClass:
                              "animate__animated animate__fadeInUp animate__slow",
                            attrs: { datafileId: this.datafileId },
                            on: {
                              showQuotaInfo: function ($event) {
                                return _vm.showQuotaSettingsInfo(true)
                              },
                              dataFetchingDone: _vm.countTheComponents,
                              quotaSettingStatus:
                                _vm.quotaSettingWidgetAvaiability,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                this.availableQualityScore !== "IS"
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-lg-4" },
                        [
                          _c("ScoreWidget", {
                            staticClass:
                              "animate__animated animate__fadeInUp animate__slow",
                            attrs: {
                              datafileId: this.datafileId,
                              qualityScore: this.availableQualityScore,
                              dataPointIdentife: null,
                              isCleaned: true,
                              groupSelector:
                                this.selectedGroupSelector === "All"
                                  ? null
                                  : this.selectedGroupSelector,
                            },
                            on: { dataFetchingDone: _vm.countTheComponents },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-lg-4" },
                        [
                          _c("DistributionWidget", {
                            staticClass:
                              "animate__animated animate__fadeInUp animate__slow",
                            attrs: {
                              datafileId: this.datafileId,
                              qualityScore: this.availableQualityScore,
                              dataPointIdentife: null,
                              isCleaned: true,
                              groupSelector:
                                this.selectedGroupSelector === "All"
                                  ? null
                                  : this.selectedGroupSelector,
                            },
                            on: { dataFetchingDone: _vm.countTheComponents },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-lg-4" },
                        [
                          _c("TrafficLightWidget", {
                            staticClass:
                              "animate__animated animate__fadeInUp animate__slow",
                            attrs: {
                              datafileId: this.datafileId,
                              qualityScore: this.availableQualityScore,
                              dataPointIdentife: null,
                              isCleaned: true,
                              groupSelector:
                                this.selectedGroupSelector === "All"
                                  ? null
                                  : this.selectedGroupSelector,
                            },
                            on: { dataFetchingDone: _vm.countTheComponents },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                this.availableQualityScore === "SDS"
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c("SDSRateWidget", {
                            staticClass:
                              "animate__animated animate__fadeInUp animate__slow",
                            attrs: {
                              datafileId: this.datafileId,
                              dataPointIdentifier: null,
                              isCleaned: true,
                            },
                            on: { dataFetchingDone: _vm.countTheComponents },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                this.availableQualityScore === "IS"
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c("PositiveNegativeWidget", {
                            staticClass:
                              "animate__animated animate__fadeInUp animate__slow",
                            attrs: {
                              datafileId: this.datafileId,
                              dataPointIdentifier: _vm.All,
                              isCleaned: true,
                            },
                            on: { dataFetchingDone: _vm.countTheComponents },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c("ISDatapointWidget", {
                            staticClass:
                              "animate__animated animate__fadeInUp animate__slow",
                            attrs: {
                              datafileId: this.datafileId,
                              isCleaned: true,
                            },
                            on: { dataFetchingDone: _vm.countTheComponents },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      class:
                        this.availableQualityScore === "TS" ||
                        this.availableQualityScore === "OES"
                          ? "col-md-6"
                          : "col-md-12",
                    },
                    [
                      _c("PerformanceWidget", {
                        staticClass:
                          "animate__animated animate__fadeInUp animate__slow",
                        attrs: {
                          datafileId: this.datafileId,
                          qualityScore: this.availableQualityScore,
                          dataPointIdentife: null,
                          isCleaned: true,
                          groupSelector:
                            this.selectedGroupSelector === "All"
                              ? null
                              : this.selectedGroupSelector,
                        },
                        on: {
                          dataFetchingDone: _vm.countTheComponents,
                          performanceWidgetAvaiable:
                            _vm.performanceWidgetAvaiability,
                        },
                      }),
                    ],
                    1
                  ),
                  this.availableQualityScore === "TS"
                    ? _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("TSSurveyDurationWidget", {
                            staticClass:
                              "animate__animated animate__fadeInUp animate__slow",
                            attrs: {
                              datafileId: this.datafileId,
                              qualityScore: this.availableQualityScore,
                              dataPointIdentife: null,
                              isCleaned: true,
                              groupSelector:
                                this.selectedGroupSelector === "All"
                                  ? null
                                  : this.selectedGroupSelector,
                            },
                            on: { dataFetchingDone: _vm.countTheComponents },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  this.availableQualityScore === "OES"
                    ? _c(
                        "div",
                        {
                          class: !this.isPerformanceWidgetAvailable
                            ? "col-md-12"
                            : "col-md-6",
                        },
                        [
                          _c("OESCategoryWidget", {
                            staticClass:
                              "animate__animated animate__fadeInUp animate__slow",
                            attrs: {
                              datafileId: this.datafileId,
                              isCleaned: true,
                              dataPointIdentife: null,
                            },
                            on: { dataFetchingDone: _vm.countTheComponents },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                !this.isDisplayMainLoading &&
                this.availableQualityScore === "CS"
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c("AveragePredictionWidget", {
                            attrs: {
                              datafileId: this.datafileId,
                              isCleaned: true,
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                this.availableQualityScore !== "IS" &&
                this.availableQualityScore !== "SDS"
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c("DataRecordTableWidget", {
                            staticClass:
                              "animate__animated animate__fadeInUp animate__slow",
                            attrs: {
                              datafileId: this.datafileId,
                              qualityScore: this.availableQualityScore,
                              dataPointIdentife: null,
                              isCleaned: true,
                              groupSelector:
                                this.selectedGroupSelector === "All"
                                  ? null
                                  : this.selectedGroupSelector,
                            },
                            on: { dataFetchingDone: _vm.countTheComponents },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ])
        : _vm._e(),
      _vm.showQuotaSettingsInfoModel
        ? _c("QuotaSettingsInfoModal", {
            attrs: { visible: _vm.showQuotaSettingsInfoModel },
            on: {
              close: function ($event) {
                return _vm.showQuotaSettingsInfo(false)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }