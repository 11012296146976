<script>
export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    GQSDataPoints: Object
  },
  data() {
    return {
      /******** Data Variables ******/
      /******** Operational Variables *******/
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    truncatedText(TextToTruncate) {
      return TextToTruncate.length > 15 ? TextToTruncate.substring(0, 15) + '...' : TextToTruncate;
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    size="lg"
  >
    <div class="p-2">
      <!-- Header -->
      <div class="row">
        <div class="col-12">
          <h5>{{$t("datafileResultIBSTab.dataPointInfoModal.title")}}</h5>
        </div>
      </div>

      <!-- Data Points -->
      <div class="row mt-4">
        <div class="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th> {{$t("datafileResultIBSTab.dataPointInfoModal.table.dataPoint")}} </th>
                <th> {{$t("datafileResultIBSTab.dataPointInfoModal.table.columns")}} </th>
                <th> {{$t("datafileResultIBSTab.dataPointInfoModal.table.patternCheck.label")}} </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dataPoint, dataPointIndex) in GQSDataPoints" :key="dataPointIndex">
                <td> {{ $t("dataRecordModal.itemBatteryScoreTab.items.text") }} {{dataPointIndex + 1}} </td>
                <td> 
                  {{ truncatedText(dataPoint.startColumn) }} 
                  <i class="bx bx-arrow-back bx-flip-horizontal ml-2 mr-2" ></i> 
                  {{ truncatedText(dataPoint.endColumn) }} 
                  <span class="ml-2">({{ dataPoint.numberOfItems }})</span>
                </td>
                <td>
                  <span class="ml-2" v-if="dataPoint.enablePatternCheck">
                    <b-badge style="font-size: 12px" variant="info">
                      {{ $t( "datafileResultIBSTab.dataPointInfoModal.table.patternCheck.yes")}}
                    </b-badge>
                  </span>
                  <span class="ml-2" v-else>
                    <b-badge style="font-size: 12px" variant="light">
                      {{ $t( "datafileResultIBSTab.dataPointInfoModal.table.patternCheck.no" ) }}
                    </b-badge>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-modal>
</template>
