<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import DatafileService from "@/api/services/datafile.service.js";
import DatafileResultService from "@/api/services/datafileResult.service.js";
import MetadataWidget from "@/redem/datafiles/components/widgets/TS/TS-metadata-widget.vue";
import LoadingCircle from "@/shared/components/model/loading-circle.vue";
import APIError from "@/shared/components/widgets/API-error.vue";
import {
checkDatafileSize,
initializeSocketBufferTimer
} from "@/shared/util/helpers.js";

import DataRecordTableWidget from "@/redem/datafiles/components/widgets/data-record-table-widget.vue";
import DistributionWidget from "@/redem/datafiles/components/widgets/distribution-widget.vue";
import PerformanceWidget from "@/redem/datafiles/components/widgets/performance-widget.vue";
import ScoreWidget from "@/redem/datafiles/components/widgets/score-widget.vue";
import TrafficLightWidget from "@/redem/datafiles/components/widgets/traffic-light-widget.vue";
import TSSurveyDurationWidget from "@/redem/datafiles/components/widgets/TS/TS-survey-duration-widget.vue";
import { EventBus } from "@/shared/util/event-bus.js";

// Const
import {
MAX_REQUEST_TIME,
WEBSOCKET_UPDATE_INTERVAL
} from "@/shared/util/const.js";
import cookie from "vue-cookies";
import Multiselect from "vue-multiselect";

export default {
  components: {
    MetadataWidget,
    LoadingCircle,
    APIError,
    ScoreWidget,
    TrafficLightWidget,
    DistributionWidget,
    PerformanceWidget,
    DataRecordTableWidget,
    TSSurveyDurationWidget,
    Multiselect
  },
  data() {
    return {
      /*******  Data varibales ********/
      datafileId: null,
      selectedGroupSelector: "All",
      dbDatafile: {},
      groupSelectors: null,
      /******** Operational Variables *********/
      errorFetchingGroupSelectors: false,
      socket: null,
      websocketBuffer: 0,
      websocketUpdateInterval: WEBSOCKET_UPDATE_INTERVAL,
      maxRequestTime: MAX_REQUEST_TIME,
      isDisplayMainLoader: true,
      alreadyLoadedComponents: 0,
      numberOfComponentToBeLoaded: 6
    };
  },
  async created() {
    this.datafileId = this.$route.query.datafileId;
    // If it's a live survey, subscribe to database updates here and trigger a re-render whenever a respondent gets added
    this.dbDatafile = await DatafileService.getDatafile(this.datafileId);
    if (this.dbDatafile.isLiveCleaning === "RUNNING") {
      this.initializeWebSocketSubscription();
      initializeSocketBufferTimer(this);
    }

    this.groupSelectors = await DatafileResultService.getGroupSelectors(
      this.datafileId
    );
    this.groupSelectors.splice(0, 0, "All");
  },
  beforeDestroy() {
    if (this.socket)
      this.socket.close(1000, String(this.datafileId) + ";CLEANED");
    this.socket = null;
  },
  methods: {
    initializeWebSocketSubscription() {
      const cleanedValues = true;
      const socket = new WebSocket(process.env.VUE_APP_WEB_SOCKET_SERVER);
      this.socket = socket;
      const token = cookie.get("accessToken");
      socket.onopen = async e => {
        await checkDatafileSize(this, cleanedValues);
        socket.send(this.datafileId + ";CLEANED;" + token);
      };
      socket.onmessage = event => {
        // Check if records are there if they are 0
        if (this.dbDatafile.numberOfCleanedRecords === 0) {
          DatafileService.getDatafile(this.datafileId).then(datafile => {
            this.dbDatafile = datafile;
          });
        }
        if (this.websocketBuffer < 1) {
          this.websocketBuffer++;
          EventBus.$emit("updateQualityWidgets");
        }
      };
    },
    countTheComponents() {
      this.alreadyLoadedComponents += 1;

      if (this.alreadyLoadedComponents === this.numberOfComponentToBeLoaded)
        this.isDisplayMainLoader = false;
    }
  }
};
</script>

<template>
  <div>
    <!-- Results -->
    <div v-show="!this.isDisplayMainLoader">
      <!-- Group Selector -->
      <div class="row mb-3" v-if="this.groupSelectors.length > 1">
        <div class="col-md-4">
          <label>
            {{ $t("datafileResultTSTab.groupSelectorDropdown.label") }}
          </label>
          <Multiselect
            v-model="selectedGroupSelector"
            :options="groupSelectors"
            :placeholder="
              $t('uploadProcessStep2.form.general.selectColumn.placeholder')
            "
          ></Multiselect>
        </div>
      </div>

      <!-- Metadata -->
      <div class="row">
        <div class="col-md-12">
          <MetadataWidget
            :datafileId="this.datafileId"
            :groupSelector="
              this.selectedGroupSelector === 'All'
                ? null
                : this.selectedGroupSelector
            "
            class="animate__animated animate__fadeInUp animate__slow"
          />
        </div>
      </div>

      <!-- R-Score Widgets -->
      <div class="row">
        <div class="col-md-4">
          <ScoreWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'TS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="
              this.selectedGroupSelector === 'All'
                ? null
                : this.selectedGroupSelector
            "
            @dataFetchingDone="countTheComponents"
          />
        </div>
        <div class="col-md-4">
          <DistributionWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'TS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="
              this.selectedGroupSelector === 'All'
                ? null
                : this.selectedGroupSelector
            "
            @dataFetchingDone="countTheComponents"
          />
        </div>
        <div class="col-md-4">
          <TrafficLightWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'TS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="
              this.selectedGroupSelector === 'All'
                ? null
                : this.selectedGroupSelector
            "
            @dataFetchingDone="countTheComponents"
          />
        </div>
      </div>

      <!-- Performance Widget & Survey Duration Widget -->
      <div class="row">
        <div class="col-md-6">
          <PerformanceWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'TS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="
              this.selectedGroupSelector === 'All'
                ? null
                : this.selectedGroupSelector
            "
            @dataFetchingDone="countTheComponents"
          />
        </div>
        <div class="col-md-6">
          <TSSurveyDurationWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'TS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="
              this.selectedGroupSelector === 'All'
                ? null
                : this.selectedGroupSelector
            "
            @dataFetchingDone="countTheComponents"
          />
        </div>
      </div>

      <!-- Data Record Table Widget -->
      <div class="row">
        <div class="col-md-12">
          <DataRecordTableWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :qualityScore="'TS'"
            :dataPointIdentife="null"
            :isCleaned="true"
            :groupSelector="
              this.selectedGroupSelector === 'All'
                ? null
                : this.selectedGroupSelector
            "
            @dataFetchingDone="countTheComponents"
          />
        </div>
      </div>
    </div>

    <!-- Loading Component -->
    <div v-if="this.isDisplayMainLoader">
      <div class="row">
        <div class="col-12 text-center p-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>
